import React from 'react'
import { AiOutlineFilePdf } from 'react-icons/ai'
import ShowMoreText from 'react-show-more-text'
import { Link, useLocation } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function BlogArticlesCard({
  coverImage,
  title,
  description,
  createdTime,
  memberName,
  memberLastName,
  memberImage,
  id,
  pdf,
}) {
  const location = useLocation()
  const locationParse = location.pathname.split('/')

  function formatDate() {
    const dateObj = new Date(createdTime)
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const day = dateObj.getDate().toString().padStart(2, '0')
    const year = dateObj.getFullYear().toString()
    return `${month}.${day}.${year}`
  }

  return (
    <div className="flex flex-col mb-[50px] mr-[30px] hover:scale-110 ease-in duration-300">
      <Link
        to={`/${locationParse[1]}/${id}`}
        className="cursor-pointer"
      >
        <div>
          
          <LazyLoadImage
            effect={!coverImage ? "blur" : ""}
            src={`${process.env.REACT_APP_DEV_API_URL}storage/${coverImage}`}
            className="w-[100%] h-[263px] object-cover"
          />
        </div>
        <div>
          <span className="text-neutral-800 text-[12px] font-normal">
            {formatDate()}
          </span>
        </div>
        <div className="mt-[5px] text-neutral-800 text-[16px] font-bold">
          <ShowMoreText
            /* Default options */
            lines={2}
            more={false}
            less={false}
            className="w-[100%]"
            expanded={false}
            truncatedEndingComponent={'... '}
          >
            {title}
          </ShowMoreText>
        </div>

        <div className="flex items-center mt-[15px]">
          <div className="h-[32px] w-[32px] border-full rounded-full bg-[#202021] mr-[15px]">
            <img
              className="object-cover w-[100%] h-[100%] rounded-full"
              src={`${process.env.REACT_APP_DEV_API_URL}storage/${memberImage}`}
              alt=""
            />
          </div>
          <div>
            <h1 className="text-neutral-800 text-[12px] font-normal mr-[15px]">
              {memberName} {memberLastName}
            </h1>
          </div>
        </div>
      </Link>
      {location.pathname === '/articles' && (
        <a
          href={
            'https://api.namyouth.org/storage/article/pdf/2023-07-21/gcSTnc5OqcgFzpXJknlofVSk1sxKK7Nz.pdf'
          }
          download="Example-PDF-document"
          target="_blank"
          rel="noreferrer"
          className="text-center py-[7px] bg-zinc-100 text-neutral-400 text-[15px] flex items-center ease-in duration-200 justify-center mt-[25px] hover:bg-[#2A538A] hover:text-white"
        >
          <AiOutlineFilePdf className="text-[30px] mr-[5px]" />
          Get PDF
        </a>
      )}
    </div>
  )
}

import React from 'react';
import { Outlet,Navigate } from 'react-router'
import Register from './components/layout/register/Register';

const LoginRouteCheck = () => {
   let auth = {'token':localStorage.getItem('token')}

    return (
        !auth.token ? <Register/> : <Navigate to ="/myCabinet/view"/>
    );
}

export default LoginRouteCheck;

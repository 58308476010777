import React, { useMemo } from "react";
import BecomeButton from "../../components/shared/button/BecomeButton";
import TextInput from "../../components/shared/input/TextInput";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// Date picker
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "../../components/layout/register/datePicker.css"
import { getCountries } from "../../components/slices/auth";
import { getChapters } from "../../components/slices/auth";
import { getMemberProfile } from "../../components/slices/auth";
import axios from "axios";
import { APIRoutes } from "../../core/api/routes";
import { selectImage } from "../../components/slices/addImg";
import { selectImageEdit } from "../../components/slices/addImgEdit";
import { setMessages,clearMessages } from "../../components/slices/message"
import Swal from 'sweetalert2'



const MyCabinetEdit = () => {
  const image = useSelector(selectImageEdit);
  const memberData = useSelector(state => state.auth.member.member.data.member)
  // const { member: currentMember } = useSelector((state) => state.auth)
  const [oldAvatar, setOldAvatar] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [namYouthEmail, setNamYouthEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState();
  const [chaptersId, setChaptersId] = useState("");

  // Address
  const [country, setCountry] = useState([]);
  const [inputCountry, setInputCountry] = useState('');

  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");

  // National Chapter
  const [chaptersMembers, setChaptersMembers] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  // const [oneChap, setOneChap] = useState('');

  // Set Password
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Social Media
  const [facebookLink, setFacebookLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [countryName,setCountryName] = useState("")

  const endOfQ12022 = dayjs('2010-03-31T23:59:59.999');
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    message,
    messageEmail,
    messagePassword,
    messageStatus,
    messageAvatar,
    messageUsername,
    messageFirstName,
    messageLastName,
    messageNamYouthEmail,
    messageNationality,
    messagePhoneNumber,
    messageGenderID,
    messageCountry,
    messagePostalCode,
    messageCity,
    messageins,
    messagefb,
    messagetw,
    messageNationalChapter
  } = useSelector((state) => state.messages);


 

  
  const filtered = chaptersMembers?.find(obj => {
    return obj?.name === inputValue;
  });
  const chapterId = filtered ? filtered?.id : null

  const filteredCountry = country?.find(obj => {
    return obj?.name === inputCountry;
  });
  const chapterIdCountry = filteredCountry ? filteredCountry?.id : null



  const token = JSON.parse(localStorage.getItem('token'));
  const id = localStorage.getItem("id")
  useEffect(() => {
    dispatch(getMemberProfile({ id: id, token: token })).then(response => {
      setUserName(response?.payload?.member?.data?.member?.username)
      setOldAvatar(response?.payload?.member?.data?.member?.avatar)
      setEmail(response?.payload?.member?.data?.member?.email)
      setNamYouthEmail(response?.payload?.member?.data?.member?.nam_youth_email)
      setChaptersId(response?.payload?.member?.data?.member?.national_chapter)
      setFirstName(response?.payload?.member?.data?.member?.first_name)
      setLastName(response?.payload?.member?.data?.member?.last_name)
      setDateBirth(dayjs(response?.payload?.member?.data?.member?.date_of_birth) ? dayjs(response?.payload?.member?.data?.member?.date_of_birth) : "1999-10-10")
      setNationality(response?.payload?.member?.data?.member?.nationality)
      setPhone(response?.payload?.member?.data?.member?.phone_number)
      setGender(Number(response?.payload?.member?.data?.member?.gender_id))
      // setCountry(response)
      setPostalCode(response?.payload?.member?.data?.member?.postal_code)
      setCity(response?.payload?.member?.data?.member?.city)
      setFacebookLink(response?.payload?.member?.data?.member?.fb)
      setTwitterLink(response?.payload?.member?.data?.member?.tw)
      setLinkedinLink(response?.payload?.member?.data?.member?.linkedin)
      setInstagramLink(response?.payload?.member?.data?.member?.ins)
    })
  }, [dispatch]);
  const fileBlob = new Blob([oldAvatar], { type: 'text/plain' });
  const fileUrl = URL.createObjectURL(fileBlob);
  const file = new File([fileUrl], "myFile.txt", { type: 'text/plain' });

  const editData = async (e, thunkAPI) => {
    setLoadingSubmit(true)

    e.preventDefault();
    try {
    
      const headers = {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }

     
      const config = {
        headers: headers
      }
      // const append = image ? avatar : image : "";
      const appearImg = !image ? null : image
      const data = {
        first_name: firstName,
        avatar:image,
        appearImg,
        email:email,
        password: password,
        username: userName,
        last_name: lastName,
        date_of_birth: `${dateBirth.$y}-${dateBirth.$M + 1}-${dateBirth.$D}`,
        nationality: nationality,
        phone_number: phone,
        gender_id: gender,
        country: chapterIdCountry,
        postal_code: postalCode,
        city: city,
        national_chapter: chapterId,
        fb: facebookLink,
        linkedin: linkedinLink,
        tw: twitterLink,
        ins: instagramLink,
        old_password: passwordCurrent,
        password_confirmation: confirmPassword,
        _method:'PUT'
      }
      const response = await axios.post(APIRoutes.editMember + `/${id}`, data, config)
     
      if (response?.data?.message === "Update completed successfully.") {
        Swal.fire(
          'Success',
          'Your information has been updated',
          'success'
        )
      }
      
      dispatch(getMemberProfile({ id: id, token: token })).then(response => {
        console.log(response)
      })

      return response?.data
    }
    catch (error) {
      const messageNationalChapter = error.response?.data?.data?.national_chapter;
      const messageUsername = error.response?.data?.data?.username;
      const messageFirstName = error.response?.data?.data?.first_name;
      const messageLastName = error.response?.data?.data?.last_name;
      const messageCountry = error.response?.data?.data?.country;
      const messageCity = error.response?.data?.data?.city;
      const messageEmail = error.response?.data?.data?.email;
      const messagePassword = error.response?.data?.data?.password;
      const messageAvatar = error.response?.data?.data?.avatar;
      const messageNationality = error.response?.data?.data?.nationality;
      const messagePhoneNumber = error.response?.data?.data?.phone_number;
      const messageGenderID = error.response?.data?.data?.gender_id;
      const messagePostalCode = error.response?.data?.data?.postal_code;
      const messageStatus = error.response?.status;
      const messageins = error.response?.data?.data?.ins;
      const messagefb = error.response?.data?.data?.fb;
      const messagetw = error.response?.data?.data?.tw;
      const messages = {
        message, messageEmail, messagePassword, messageStatus, messageAvatar,
        messageFirstName, messageLastName, messageNationality, messagePhoneNumber,
        messageGenderID, messageCountry, messagePostalCode, messageCity, messageUsername, messageins, messagefb, messagetw, messageNationalChapter
      };
      dispatch(setMessages(messages));
     if(error.response.status === 422){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.error,
      })
       
      }
      // if(error.response.message === ""){
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: 'The information entered is incorrect.',
      //   })
      // }

    }
    finally{
      setLoadingSubmit(false)

    }


  }

  useEffect(() => {
    dispatch(getChapters(countryName)).then(res => {
      setChaptersMembers(res?.payload)
    })
  }, [dispatch]);


  const handleValueChange = (event) => {
    setGender(parseInt(event.target.value));
  };


  // Countries list
  useEffect(() => {
    dispatch(getCountries()).then(res => {
      setCountry(res?.payload?.countries?.data?.countries)
    })
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearMessages());
  }, [dispatch]);

  //  var t = chaptersMembers?.map(chap => chap?.id === chaptersId ? console.log(chap.name) : "")
  //  console.log(t)
 
  const findOneChap = useMemo(() => chaptersMembers?.find(chap => chap?.id === memberData?.national_chapter), [memberData, chaptersMembers]);
  
  const [value, setValue] = useState('');

  // Update the 'value' state whenever 'findOneChap' changes
  useEffect(() => {
    if (findOneChap) {
      setValue(findOneChap.name);
    } else {
      setValue(''); // Set to an empty string when findOneChap is undefined
    }
  }, [findOneChap]);

  const findOneCountry = useMemo(() => country?.find(country => country?.id == memberData.country), [memberData, country]);
  const [valueCountry, setValueCountry] = useState('');

  useEffect(() => {
    if (findOneCountry) {
      setValueCountry(findOneCountry?.name);
    } else {
      setValueCountry(''); // Set to an empty string when findOneChap is undefined
    }
  }, [findOneCountry]);

 
  return (
    <div className="px-[20px] pt-[50px] md:pt-[108px] lg:px-[50px] md:px-[30px] sm:px-[20px]">
      <div className="block md:flex ">
        <div className="w-[100%] md:w-[32%]">
          {/* <input
            style={{ display: "none" }}
            ref={inputRefEdit}
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleFileChangeEdit}
          /> */}
          <h1 className="font-bold text-[24px] pb-[20px]">Edit Profile</h1>
          <TextInput
            iplaceholder="Enter your username"
            ititle="Username"
            iname="username"
            handle={setUserName}
            value={userName ? userName : ""}
          />
          <span className="text-[red]">{messageUsername}</span>
          <TextInput
            iplaceholder="Enter your email"
            ititle="Email Address"
            iname="email"
            handle={setEmail}
            value={email ? email : ""}
          />
          <span className="text-[red]">{messageEmail}</span>
          <TextInput
            iplaceholder="Enter your email"
            ititle="NAM Youth Email Address"
            iname="namemail"
            handle={setNamYouthEmail}
            value={namYouthEmail ? namYouthEmail : ""}
          />
          <span className="text-[red]">{messageNamYouthEmail}</span>
          <TextInput
            iplaceholder="Enter your name"
            ititle="First Name"
            iname="firstname"
            handle={setFirstName}
            value={firstName ? firstName : ""}
          />
          <span className="text-[red]">{messageFirstName}</span>
          <TextInput
            iplaceholder="Enter your surname"
            ititle="Last Name"
            iname="lastname"
            handle={setLastName}
            value={lastName ? lastName : ""}
          />
          <span className="text-[red]">{messageLastName}</span>
          {/* <TextInput
            iplaceholder="Enter your date of birth"
            ititle="Date of Birth"
            iname="dbirth"
            handle={setDateBirth}
            value={dateBirth}
          /> */}
          <div className="w-[100%]">
            <h4 className="mb-[15px] font-bold text-[15px] mt-[17px]">Date</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  value={dayjs(dateBirth) ? dayjs(dateBirth) : ""}
                  onChange={(newValue) => setDateBirth(dayjs(newValue))}
                  // minDate={startOfQ12022}
                  maxDate={endOfQ12022}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <TextInput
            iplaceholder="Enter your nationality"
            ititle="Nationality"
            iname="nationality"
            handle={setNationality}
            value={nationality ? nationality : ""}
          />
          <span className="text-[red]">{messageNationality}</span>
          <TextInput
            iplaceholder="Enter your number"
            ititle="Phone Number"
            iname="pnumber"
            handle={setPhone}
            value={phone ? phone : ""}
          />
          <span className="text-[red]">{messagePhoneNumber}</span>
          <div>
            <h4 className="mb-[15px] font-bold text-[15px]">Gender</h4>
            <div className="flex gap-5 ">
              <input type="radio" name="numericRadioGroup"  className="cursor-pointer" value="1" checked={gender === 1} onChange={handleValueChange} />
              Male
              <input type="radio" name="numericRadioGroup"  className="cursor-pointer" value="2" checked={gender === 2} onChange={handleValueChange} />
              Female
              <input type="radio" value="3" name="gender"  className="cursor-pointer" onChange={(e) => setGender(e.target.value)} checked={gender === 3} /> 
              Other
            </div>
          </div>
          <span className="text-[red]">{messageGenderID}</span>
        </div>
        <div className="ml-0 md:ml-[80px] w-[100%] md:w-[32%]">
          <div>
            <h1 className="font-bold text-[24px] pb-[20px]">Address</h1>
            <Autocomplete
              id="controllable-states-demo"
              options={country.map((x)=>x.name)}
              inputValue={inputCountry}
              onInputChange={(event, newInputValue) => {
                setInputCountry(newInputValue);
              }}
              onChange={(event, newValue) => {
                setValueCountry(newValue);
              }}
              value={valueCountry}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
            />
            <span className="text-[red]">{messageCountry}</span>
            <TextInput
              iplaceholder="Enter your postal code"
              ititle="Postal code"
              iname="postalcode"
              handle={setPostalCode}
              value={postalCode ? postalCode : ""}
            />
            <span className="text-[red]">{messagePostalCode}</span>
            <TextInput
              iplaceholder="Enter your city"
              ititle="City"
              iname="city"
              handle={setCity}
              value={city ? city : ""}
            />
            <span className="text-[red]">{messageCity}</span>
          </div>
          <div className="pt-[30px]">
            <h1 className="font-bold text-[24px] pb-[20px]">
              National Chapter
            </h1>
            <div className="w-[100%]">
                   {/* burdaki deyeri inputValue qoymaq lazimdir */}
                  {/* {chaptersMembers.map(chap => chap?.id === chaptersId ?(chap.name) : "")} */}
              <Autocomplete
                id="controllable-states-demo"
                options={chaptersMembers?.map(chap => chap?.name)}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}

                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                value={value}

                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
              />
              <span className="text-[red]">{messageNationalChapter}</span>
            </div>
          </div>
          <div className="pt-[30px]">
            <h1 className="font-bold text-[24px] pb-[20px]">Set Password</h1>
            <TextInput
              iplaceholder="Enter Current Password"
              ititle="Current Password"
              iname="crrntpsw"
              handle={setPasswordCurrent}
              value={passwordCurrent}
            />
            <span className="text-[red]">{messagePassword}</span>
            <TextInput
              iplaceholder="Enter New Password"
              ititle="Password"
              iname="newpsw"
              handle={setPassword}
              value={password}
            />
            <span className="text-[red]">{messagePassword}</span>
            <TextInput
              iplaceholder="Enter Confirm Password"
              ititle="Confirm Password"
              iname="repsw"
              handle={setConfirmPassword}
              value={confirmPassword}
            />
            <span className="text-[red]">{messagePassword}</span>
          </div>
        </div>
      </div>
      <hr className="my-[150px]" />
      <div>
        <h1 className="font-bold text-[24px] pb-[30px]">
          Social Media Information
        </h1>
        <div className="block md:flex">
          <div className=" w-[100%] md:w-[30%]">
            <TextInput
              iplaceholder="Enter Facebook Link"
              ititle="Facebook Profile"
              iname="fblink"
              handle={setFacebookLink}
              value={facebookLink ? facebookLink : ""}
            />
            <span className="text-[red]">{messagefb}</span>
            <TextInput
              iplaceholder="Enter Twitter Link"
              ititle="Twitter Profile"
              iname="twitlink"
              handle={setTwitterLink}
              value={twitterLink ? twitterLink : ""}
            />
            <span className="text-[red]">{messagetw}</span>
          </div>
          <div className="w-[100%] md:w-[30%] ml-0 md:ml-[147px]">
            <TextInput
              iplaceholder="Enter LinkedIn Link"
              ititle="Linkedin Profile"
              iname="lelink"
              handle={setLinkedinLink}
              value={linkedinLink ? linkedinLink : ""}
            />
            <TextInput
              iplaceholder="Enter Instagram Link"
              ititle="Instagram Profile"
              iname="instalink"
              handle={setInstagramLink}
              value={instagramLink ? instagramLink : ""}
            />
            <span className="text-[red]">{messageins}</span>
          </div>
        </div>
      </div>
      <hr className="my-[150px]" />
      <div className="flex justify-center pb-[220px]">
        <BecomeButton func={editData} textColor="white" textSize="18px" text={loadingSubmit ? "Loading..." : "Update"} bgColor="#202021" paddingX="72px" paddingY="22px" />
      </div>
    </div>
  );
};

export default MyCabinetEdit;

import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { BsDot } from 'react-icons/bs'
import { useEffect } from 'react'
import { useState } from 'react'
import { getMemberProfile } from '../../slices/auth'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import 'react-loading-skeleton/dist/skeleton.css'
export default function ViewAnother() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const token = JSON.parse(localStorage.getItem('token'))

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [gender, setGender] = useState('')
  const [nationality, setNationality] = useState('')
  const [email, setEmail] = useState('')
  const [dateOfBirt, setDateOfBirt] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [roles, setRoles] = useState([])
  useEffect(() => {
    dispatch(getMemberProfile({ id: id, token: token })).then((response) => {
      setFirstName(response?.payload?.member?.data?.member?.first_name)
      setLastName(response?.payload?.member?.data?.member?.last_name)
      setGender(response?.payload?.member?.data?.member?.gender_id)
      setNationality(response?.payload?.member?.data?.member?.nationality)
      setEmail(response?.payload?.member?.data?.member?.email)
      setDateOfBirt(response?.payload?.member?.data?.member?.date_of_birth)
      setPhoneNumber(response?.payload?.member?.data?.member?.phone_number)
      setRoles(response?.payload?.member?.data?.member?.roles)
    })
  }, [dispatch])

  return (
    <>
      <div className="px-[25px] pt-[45px] md:px-[50px] pb-[265px] flex max-[768px]:flex-col">
      <div className='w-[100%] md:w-[40%]'>
          <h6 className="text-[#202021] text-[24px] font-bold border-b pb-[19px]">
            Personal Details
          </h6>
          <ul>
            <li className="flex gap-x-[104px] pb-[13px] pt-[41px] border-b">
              <span className="font-bold text-[15px] text-[#1C1C1C] w-[107px]">
                First Name
              </span>{' '}
              <span>{firstName || <Skeleton />}</span>
            </li>
            <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b">
              <span className="font-bold text-[15px] text-[#1C1C1C] w-[107px]">
                Last Name
              </span>{' '}
              <span>{lastName || <Skeleton />}</span>
            </li>
            <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b">
              <span className="font-bold text-[15px] text-[#1C1C1C] w-[107px]">
                Gender
              </span>{' '}
              <span>
                {gender === 2 ? "Female" : 1 ? "Male" : 3 ? "Other" : ""}
              </span>
            </li>
            <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b">
              <span className="font-bold text-[15px] text-[#1C1C1C] w-[107px]">
                Nationality
              </span>{' '}
              <span>{nationality || <Skeleton />}</span>
            </li>
            <li className="flex gap-x-[104px] pb-[13px] pt-[10px]">
              <span className="font-bold text-[15px] text-[#1C1C1C] w-[107px]">
                Email
              </span>{' '}
              <span>{email || <Skeleton />}</span>
            </li>
          </ul>
        </div>
        <div className='w-[100%]'>
          <h6 className="pl-0 md:pl-[45px] lg:pl-[333px] xl:pl-[420px] text-[#202021] text-[24px] font-bold border-b pb-[19px] mt-[50px] md:mt-[0px]">
            NAM Involvement
          </h6>
          <ul className="">
            <li className="pl-0 md:pl-[45px] lg:pl-[333px] xl:pl-[420px] gap-x-[104px] pb-[13px] pt-[41px] border-b">
              <span>NAM Youth Azerbaijan</span>
            </li>
            {roles.length != 0 ? (
              roles?.map((role) => (
                <li
                className="px-0 md:pl-[45px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex items-center"
                  key={role?.id}
                >
                  <BsDot />
                  <span>{role?.name || <Skeleton />}</span>
                </li>
              ))
            ) : (
              <>
                 {/* <li className="pl-[0] md:pl-[45px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span></span></li>  
        <li className="pl-[0] md:pl-[45px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span></span></li>  
        <li className="pl-[0] md:pl-[45px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span></span></li>   */}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

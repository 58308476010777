import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

const blogsSearch = async(title) => {
    try {
        if (title) {
            const response = await axios.get(`${APIRoutes.blogs}?title=${title}`)
            return response.data
        } else {
            const response = await axios.get(`${APIRoutes.blogs}`)
            return response.data
        }

    } catch (error) {
        console.log(error);
    }
}

export default blogsSearch
import TextInput from '../../components/shared/input/TextInput'
import Follower from '../../components/shared/card/UserCard'
import React, { useState, useEffect } from 'react'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import BecomeButton from '../../components/shared/button/BecomeButton'
import { member } from '../../components/slices/auth'
import { useDispatch, useSelector } from 'react-redux'
import paginate from '../../components/shared/pagination/Paginate'
// AutoComplete
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { getRoles, getChapters } from '../../components/slices/auth'
import { clearMessages } from '../../components/slices/message'
import { Oval } from 'react-loader-spinner'

const MyCabinetUserSearch = () => {
  const [page, setPage] = useState(0)
  const [allMembers, setAllMembers] = useState([])

  const [firstNameN, setFirstNameN] = useState('')
  const [lastNameN, setLastNameN] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [inputValueRole, setInputValueRole] = useState([])

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  // Chapters data
  const [chaptersMembers, setChaptersMembers] = useState([])
  const [roles, setRoles] = useState([])
  const [countryName, setCountryName] = useState('')
  const [test, setTest] = useState('')
  const dispatch = useDispatch()

  const { messageFirstName1, messageLastName1, messageStatus } = useSelector(
    (state) => state.messages,
  )

  useEffect(() => {
    if (firstNameN) {
      dispatch(clearMessages())
    }
  }, [firstNameN])

  useEffect(() => {
    if (lastNameN) {
      dispatch(clearMessages())
    }
  }, [lastNameN])

  useEffect(() => {
    if (loading) return
    setAllMembers(data[page])
  }, [loading, page])

  const handlePage = (index) => {
    setPage(index)
  }
  const nextPage = () => {
    if (page === data.length - 1) {
      setPage(0)
    } else {
      setPage(page + 1)
    }
  }
  const prevPage = () => {
    if (page === 0) {
      setPage(data.length - 1)
    } else {
      setPage(page - 1)
    }
  }
  useEffect(() => {
    dispatch(
      member({
        firstNameN,
        lastNameN,
        inputValue: chapterId,
        inputValueRole: roleId,
      }),
    ).then((res) => {
      setTest(res?.payload.member)
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(getChapters(countryName)).then((res) => {
      setChaptersMembers(res?.payload)
    })
    dispatch(getRoles()).then((res) => {
      setRoles(res?.payload?.data.roles)
    })
  }, [dispatch])

  const filtered = chaptersMembers.find((obj) => {
    return obj.name === inputValue
  })
  const chapterId = filtered ? filtered.id : null
  const filteredRole = roles.find((obj) => {
    return obj.name === inputValueRole
  })
  const roleId = filteredRole ? filteredRole.id : null

  useEffect(() => {
    dispatch(
      member({
        firstNameN,
        lastNameN,
        inputValue: chapterId,
        inputValueRole: roleId,
      }),
    ).then((response) => {
      if (response) {
        setData(paginate(response.payload.member))
        setLoading(false)
        setPage(0)
      } else {
        setPage(0)
      }
    })
  }, [])

  const handleSearch = async (event) => {
    event.preventDefault()
    setLoading(true)
    await dispatch(
      member({
        firstNameN,
        lastNameN,
        inputValue: chapterId,
        inputValueRole: roleId,
      }),
    )
      .unwrap()
      .then((response) => {
        if (response) {
          setData(paginate(response.member))
          setLoading(false)
          setPage(0)
        } else {
          setPage(0)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="px-[50px] w-[100%] md:w-[50%] xl:w-[30%] pt-[102px] max-[768px]:px-[25px]">
        <h1 className="font-bold text-[24px] pb-[20px]">User Search</h1>
        <TextInput
          iplaceholder="Enter your name"
          ititle="First Name"
          iname="firstNameN"
          value={firstNameN}
          handle={setFirstNameN}
        />
        <span className="text-[red]">{messageFirstName1}</span>
        <TextInput
          iplaceholder="Enter your surname"
          ititle="Last Name"
          iname="lastNameN"
          value={lastNameN}
          handle={setLastNameN}
        />
        <span className="text-[red]">{messageLastName1}</span>

        <span className="font-bold mb-[15px] font-bold text-[15px] mt-[17px] block">
          Roles
        </span>
        <Autocomplete
          id="controllable-states-demo1"
          options={roles.map((chap) => chap?.name)}
          inputValueRole={inputValueRole}
          onInputChange={(event, newInputValue) => {
            setInputValueRole(newInputValue)
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="-Any -" />}
          className="pb-[55px] pt-[10px]"
        />
        <span className="font-bold">NAM Youth Country</span>
        <Autocomplete
          id="controllable-states-demo"
          options={chaptersMembers.map((chap) => chap?.name)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="-Any -" />}
          className="pb-[35px] pt-[10px]"
        />
        <BecomeButton
          func={handleSearch}
          txtsize="13px"
          textColor="white"
          text="search"
          bgColor="#2A538A"
          paddingX="48px"
          paddingY="25px"
          marginB="15px"
        />
        {!allMembers && <span className="text-[20px] mt-[55px]">No data</span>}
      </div>

      <div className="section-title">
        <div className="underline"></div>
      </div>
      <section className="py-[15px]">
          {!loading ? 
            <div className="grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mx-[25px] md:px-[50px]">
            {allMembers?.length > 0 && 
              allMembers.map((follower) => {
                return <Follower key={follower.id} {...follower} />
              }) }
          </div> : <div className="flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>

          }
        


        {
          allMembers?.length > 0 && <div className="btn-container flex justify-center pt-[95px] pb-[254px]">
          {loading ? null : (
            <button
              className="btn next-btn bg-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]"
              onClick={() => prevPage()}
            >
              {!loading ? <MdKeyboardArrowLeft /> : null}
            </button>
          )}

          {loading
            ? null
            : data.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={`page-btn border-[1px] border-[#E9E9E9] py-[4px] px-[12px] ${
                      index === page
                        ? 'active-btn bg-[#2A538A] text-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]'
                        : null
                    }`}
                    onClick={() => {
                      handlePage(index)
                    }}
                  >
                    {index + 1}
                  </button>
                )
              })}
          {loading ? null : (
            <button
              className="btn next-btn bg-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]"
              onClick={() => nextPage()}
            >
              {!loading ? <MdKeyboardArrowRight /> : null}
            </button>
          )}
        </div>
        }
      </section>
    </>
  )
}

export default MyCabinetUserSearch

import React from 'react'
import ShowMoreText from 'react-show-more-text'

import { RiFacebookFill, RiInstagramFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import LazyImage from '../../lazyLoad/LazyImage'
export default function SecretariatBoardCard({
  image,
  fullName,
  duty,
  unique,
  facebook,
  instagram,
}) {
  const item = {
    hidden: {
      opacity: 0,
      translateY: 20,
    },
    visible: {
      opacity: 1,
      translateY: 0,
    },
  }
  return (
    <motion.div variants={item} className="flex flex-col mb-[2rem]">
      <Link to={`${unique}`} key={unique}>
        <div className="mb-[8px] overflow-hidden object-center">
          <LazyImage
            src={image}
            width={'100%'}
            smWidth={'100%'}
            smHeight={'300px'}
            height={'300px'}
            cover={'cover'}
            animation={true}
          />
          {/* <img
            src={image}
            alt=""
            className="hover:scale-125 transform transition object-cover object-top h-[296px] w-[100%]"
          /> */}
        </div>
        <div>
          <h1 className="text-[#202021] py-[14px] font-bold">{fullName}</h1>
          <ShowMoreText
            /* Default options */
            lines={2}
            more={false}
            less={false}
            className="w-[100%]"
            expanded={false}
            truncatedEndingComponent={'... '}
          >
            {duty}
          </ShowMoreText>
        </div>
      </Link>
      <div className="mt-[13px]">
        {facebook && (
          <Link target={'_blank'} to={`https://${facebook}`}>
            <RiFacebookFill className="text-[18px] text-[#353B48] hover:text-[black]" />
          </Link>
        )}
        {instagram && (
          <Link target={'_blank'} to={`https:/${instagram}`}>
            <RiInstagramFill className="text-[18px] ml-[15px] text-[#353B48] hover:text-black" />
          </Link>
        )}
      </div>
    </motion.div>
  )
}

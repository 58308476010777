import React, { useCallback } from 'react'
import { AiFillLike } from 'react-icons/ai'
import { AiFillDislike } from 'react-icons/ai'
import { BsEyeFill } from 'react-icons/bs'

import { getAllNews } from '../../components/slices/auth'

import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ShowMoreText from 'react-show-more-text'
import { useNavigate } from 'react-router-dom'

import LazyImage from '../../components/lazyLoad/LazyImage'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MainNews from '../../components/services/newsMain.service'
import { Navigation, Pagination, Autoplay } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'
import { motion } from 'framer-motion'

import 'swiper/css/pagination'
import 'swiper/css'
import 'swiper/css/navigation'
import './style.css'
import './date.css'
import { Like } from '../../components/services/likes.service'
import NewsCard from '../../components/shared/card/NewsCard'
export default function NewsMain() {
  const [allNews, setAllNews] = useState([])

  // const [dates, setDates] = useState([]);
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [title, setTitle] = useState('')
  const [mainNews, setMainNews] = useState([])
  const [message, setMessage] = useState('');
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [next, setNext] = useState(3)

  const handleChangeDay = (event) => {
    setDay(event.target.value)
  }
  const handleChangeMonth = (event) => {
    setMonth(event.target.value)
  }
  const handleChangeYear = (event) => {
    setYear(event.target.value)
  }

  let years = [2022, 2023, 2024]
  const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ]
  let monthOfDays = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
  ]  
  useEffect(() => {
    dispatch(
      getAllNews({ year: year, month: month, day: day, title: title }),
    ).then((res) => {
      setAllNews(res?.payload?.news?.data?.news)
      setMessage(res?.payload?.news?.message)
    })
  }, [dispatch, getAllNews, year, month, day, title, setAllNews])

  const handleMoreImage = () => {
    setNext(next + 3)
  }

  useEffect(() => {
    MainNews().then((res) => {
      setMainNews(res)
    })
  }, [])

  const container = {
    visible: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 1,
      },
    },
  }
  const items = {
    hidden: {
      opacity: 0,
      translateY: 60,
    },
    visible: {
      opacity: 1,
      translateY: 0,
    },
  }



  return (
    <>
      {
        // !allNews?.length === 0 || allNews===undefined ? <div className=''></div> : 
        <div className="w-[93%] mx-auto mt-[85px]">
          <div className="block md:flex items-center" id="topDates">
            <div className="relative w-[100%] xl:w-[30%]">
              <input
                type="text"
                className="block w-full px-3 py-4 bg-[#F0F0F0] border border-[#F0F0F0] text-sm shadow-sm placeholder-[black] focus:outline-none focus:border-[#F0F0F0] focus:ring-1 focus:ring-[#F0F0F0]"
                placeholder="Search by keyword"
                onChange={(e) => setTitle(e.target.value)}
              />
              <button
                type="submit"
                className="absolute top-0 right-0 px-2.5 py-4 text-sm font-medium text-black "
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
            <div className="flex border w-[100%]">
              <Box sx={{ minWidth: 120 }}>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    style={{ color: 'gray' }}
                  >
                    Day
                  </InputLabel>
                  <Select
                    disableUnderline
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={day}
                    onChange={handleChangeDay}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {monthOfDays.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    style={{ color: 'gray' }}
                  >
                    Month
                  </InputLabel>
                  <Select
                    disableUnderline
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={month}
                    onChange={handleChangeMonth}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {months.map((month) => (
                      <MenuItem key={month.id} value={month.id}>
                        {month.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 120 }}>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    style={{ color: 'gray' }}
                  >
                    Year
                  </InputLabel>
                  <Select
                    disableUnderline
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={year}
                    onChange={handleChangeYear}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>

          <div className="py-[50px] 2xl:py-[0] mt-[50px]">
            <h1 className="text-[#202021] text-[44px] font-bold leading-[53px]">
              News
            </h1>
          </div>
          {message !== "Successful" || allNews?.length === 0 ? <div className='mt-4'><h1 className='text-[22px] font-bold'>No data</h1></div> : ""}


          <div
            className={`mt-[50px] ${
              title || month || year || day ? 'hidden' : 'block'
            }`}
          >
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              slidesPerView={1}
              navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
              }}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4500,
                disableOnInteraction: false,
              }}
              // loop={true}
            >
              {mainNews &&
                mainNews.map((news) => (
                  <SwiperSlide key={news?.id}>
                    <div
                      className="block lg:flex items-center cursor-pointer"
                      key={news.id}
                      onClick={() => navigate(`detail/${news.id}`)}
                    >
                      <div className="lg:w-[50%] lg:ml-[5rem]">
                        <img
                          src={`${process.env.REACT_APP_DEV_API_URL}storage/${news?.cover_image}`}
                          alt=""
                        />
                      </div>
                      <div className="lg:w-[50%] lg:ml-[3rem] lg:mr-[5rem]">
                        <div>
                          <h1 className="text-neutral-800 text-[25px] lg:text-[36px] font-bold">
                            {news?.title}
                          </h1>
                        </div>
                        <div className="py-[25px] lg:py-[45px]">
                          <p>{news?.desc}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              <div className="swiper-button-prev hidden md:block"></div>
              <div className="swiper-button-next hidden md:block"></div>
            </Swiper>
          </div>

          <div className="mt-[74px]">
            {/* Items */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={container}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[40px] mt-[70px] md:mt-[117px] pb-[20px]"
            >
              {/* One item */}
              {allNews?.slice(0, next)?.map((item) => (
                
                <motion.div
                  variants={items}
                  key={item?.id}
                  className="w-[100%] cursor-pointer mr-0 mb-[23px] md:mb-[20px] lg:mr-[80px] xl:mr-[80px] 2xl:mr-[100px] lg:mx-0 md:mr-[10px]"
                >
                  <NewsCard item={item}/>
                </motion.div>
              ))}
            </motion.div>
            {next < allNews?.length && (
              <div className="flex justify-center pb-[137px]">
                <button
                  className="mt-4 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-300"
                  onClick={handleMoreImage}
                >
                  Load more
                </button>
              </div>
            )}
          </div>
        </div>
      }
        
     
    </>
  )
}

import { createBrowserRouter } from 'react-router-dom'
import React from 'react'
import App from '../App'
// import Home from "../pages/home/Home";
import About from '../../src/pages/about/About'
import MyCabinetEdit from '../pages/myCabinet/MyCabinetEdit'
import MyCabinetUserSearch from '../pages/myCabinet/MyCabinetUserSearch'
// import NationalChapters from "../pages/chapters/NationalChapters";
import Login from '../components/layout/login/Login'
import ForgotPassword from '../components/layout/forgotPassword/ForgotPassword'
import ResetPassword from '../components/layout/resetPassword/ResetPassword'
import Register from '../components/layout/register/Register'
// import MyCabinetLayout from "../pages/myCabinet/MyCabinetLayout";
import MyCabinetView from '../pages/myCabinet/MyCabinetView'
import MyCabinetRoleHistory from '../pages/myCabinet/MyCabinetRoleHistory'
import CountryNameMembers from '../pages/chapters/CountryNameMembers'
import CountryNameOverview from '../pages/chapters/CountryNameOverview'
import NationalChaptersLayout from '../pages/chapters/NationalChaptersLayout'
import EmailVerify from '../components/layout/Verify/Verify'
import NewsLayout from '../pages/news/NewsLayout'
import NewsMain from '../pages/news/NewsMain'
import NewsDetail from '../pages/news/NewsDetail'
import Contact from '../pages/contact/Contact'

import UserLayout from '../pages/user/UserLayout'
import ViewAnotherUser from '../components/shared/view/ViewAnother'
import AboutLayout from '../pages/about/AboutLayout'
import TimelineLayout from '../pages/timeline/TimelineLayout'
import Timeline from '../pages/timeline/Timeline'
import ConceptLayout from '../pages/concept/ConceptLayout'
import Concept from '../pages/concept/Concept'

import LoginRouteCheck from '../LoginRouteCheck'
import RegisterRouteCheck from '../RegisterRouteCheck'
import PrivateRoute from '../PrivateRoute'
import Fag from '../pages/fag/Fag'
import RoleHistoryAnother from '../components/shared/role-history/RoleHistoryAnother'

import Opportunities from '../pages/opportunites/Opportunities'
import Job from '../pages/opportunites/Job'
import Internship from '../pages/opportunites/Internship'
import Volunteering from '../pages/opportunites/Volunteering'
import ExchangePrograms from '../pages/opportunites/ExchangePrograms'
import Scholarships from '../pages/opportunites/Scholarships'
import JobDetail from '../pages/opportunites/JobDetail'
import InternShipDetail from '../pages/opportunites/InternShipDetail'
import VolunteeringDetail from '../pages/opportunites/VolunteeringDetail'
import ExchangeProgramsDetail from '../pages/opportunites/ExchangeProgramsDetail'
import ScholarShipsDetail from '../pages/opportunites/ScholarShipsDetail'
import { Oval } from 'react-loader-spinner'
import Secretariat from '../pages/secretariat/Secretariat'
import SecretariatDetail from '../pages/secretariat/SecretariatDetail'
import Board from '../pages/board/Board'
import BoardDetail from "../pages/board/BoardDetail"
import ProjectsDetail from '../pages/projects/ProjectsDetail'
import Calendar from "../pages/calendar/Calendar.jsx"
import Blog from '../pages/blog/Blog'
import Articles from '../pages/articles/Articles'
import BlogDetail from '../pages/blog/BlogDetail'
import ArticleDetail from '../pages/articles/ArticleDetail'
import MyCabinetArticles from '../pages/myCabinet/MyCabinetArticles'
import MyCabinetBlog from '../pages/myCabinet/MyCabinetBlog'
import MyBlog from "../pages/blog/MyBlog"
import Policies from '../pages/policies/Policies'
import MyArticle from '../pages/articles/MyArticle'

import Home from '../pages/home/Home.jsx'
import Projects from "../pages/projects/Projects"



import MyCabinetLayout from '../pages/myCabinet/MyCabinetLayout'



let routes = [
  {
    element: <App />,
    path: '/',
    children: [
      {
        element: (
          <React.Suspense
            fallback={
              <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
              <Oval
                height={80}
                width={80}
                color="#2A538A"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#2A538A"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
            }
          >
            <Home />{' '}
          </React.Suspense>
        ),
        path: '/',
      },
      {
        element: <NationalChaptersLayout />,
        path: 'chapters/',
        children: [
          {
            children: [
              {
                index: true,
                path: 'overview/:id',
                element: <CountryNameOverview />,
              },
            ],
          },
          {
            element: <PrivateRoute />,
            children: [
              {
                index: true,
                path: 'members/:id',
                element: <CountryNameMembers />,
              },
            ],
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: 'myCabinet',
            element: (
              <React.Suspense fallback={ <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
              <Oval
                height={80}
                width={80}
                color="#2A538A"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#2A538A"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>}>
                <MyCabinetLayout />
              </React.Suspense>
            ),
            children: [
              {
                index: true,
                path: 'view',
                element: <MyCabinetView />,
              },
              {
                index: true,
                path: 'userSearch',
                element: <MyCabinetUserSearch />,
              },
              {
                index: true,
                path: 'edit',
                element: <MyCabinetEdit />,
              },
              {
                index: true,
                path: 'roleHistory',
                element: <MyCabinetRoleHistory />,
              },
              {
                index: true,
                path: 'myCabinetArticles',
                element: <MyCabinetArticles />,
              },
              {
                index: true,
                path: 'myCabinetBlog',
                element: <MyCabinetBlog />,
              },
            ],
          },
        ],
      },
      {
        element: <Opportunities />,
        path: 'opportunities',
        children: [
          {
            element: <Job />,
            path: 'job',
          },
          {
            element: <JobDetail />,
            path: 'job/:id',
          },
          {
            element: <Internship />,
            path: 'internships',
          },
          {
            element: <InternShipDetail />,
            path: 'internships/:id',
          },
          {
            element: <Volunteering />,
            path: 'volunteering',
          },
          {
            element: <VolunteeringDetail />,
            path: 'volunteering/:id',
          },
          {
            element: <ExchangePrograms />,
            path: 'exchangeprograms',
          },
          {
            element: <ExchangeProgramsDetail />,
            path: 'exchangeprograms/:id',
          },
          {
            element: <Scholarships />,
            path: 'scholarships',
          },
          {
            element: <ScholarShipsDetail />,
            path: 'scholarships/:id',
          },
        ],
      },

      {
        element: <Contact />,
        path: 'contact',
      },
      {
        element: <Fag />,
        path: 'faq',
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: 'user/:id',
            element: <UserLayout />,
            children: [
              {
                index: true,
                path: 'userView/',
                element: <ViewAnotherUser />,
              },
              {
                path: 'userRolesHistory/',
                element: <RoleHistoryAnother />,
              },
            ],
          },
        ],
      },
      {
        path: 'news',
        element: <NewsLayout />,
        children: [
          {
            index: true,
            path: 'news',
            element: <NewsMain />,
          },
          {
            path: 'detail/:id',
            element: <NewsDetail />,
          },
        ],
      },
      {
        element:<Secretariat/>,
        path:'secretariat'
      },
      {
        element:<SecretariatDetail/>,
        path:'secretariat/:id'
      },
      {
        element:<Board/>,
        path:'board'
      },
      {
        element:<BoardDetail/>,
        path:'board/:id'
      },
      {
        // element:<Projects/>,
        element:(
          <React.Suspense fallback={<div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>}><Projects/></React.Suspense>
        ),
        path:'projects'
      },
      {
        element:<ProjectsDetail/>,
        path:'projects/:id'
      },
      {
        element:<Calendar/>,
        path:'calendar'
      },
      {
        element:<Blog/>,
        path:'blog'
      },
      {
        element:<BlogDetail/>,
        path:'blog/:id'
      },
      {
        element:<Articles/>,
        path:'articles'
      },
      {
        element:<ArticleDetail/>,
        path:'articles/:id'
      },
      {
        element: <LoginRouteCheck />,
        children: [
          {
            element: <Login />,
            path: 'login',
          },
        ],
      },
      {
        /* {
            
            element: < Login / > ,
            path: "login",
        }, */
      },
      {
        element: <AboutLayout />,
        path: 'about',
        children: [
          {
            index: true,
            path: 'about',
            element: <About />,
          },
        ],
      },
      {
        element: <TimelineLayout />,
        path: 'timeline',
        children: [
          {
            index: true,
            path: 'timeline',
            element: <Timeline />,
          },
        ],
      },
      {
        element: <ConceptLayout />,
        path: 'whoweare',
        children: [
          {
            index: true,
            path: '/whoweare',
            element: <Concept />,
          },
        ],
      },
      {
        element: <EmailVerify />,
        path: 'email/verify/:id',
      },
      {
        element: <RegisterRouteCheck />,
        children: [
          {
            element: <Register />,
            path: 'register',
          },
        ],
      },

      {
        element: <ForgotPassword />,
        path: 'forgotPassword',
      },
      {
        element: <ResetPassword />,
        path: 'resetPassword',
      },
      {
        element: <PrivateRoute />,
        children:[
          {
            index:true,
            path:'myBlog/:id',
            element:<MyBlog/>
          }
        ]
      },
      {
        element: <PrivateRoute />,
        children:[
          {
            index:true,
            path:'myArticle/:id',
            element:<MyArticle/>
          }
        ]
      },
      {
        element: <Policies />,
        path: '/policies',
      },
      
    ],
  },
]

export default routes
export const browserRouter = createBrowserRouter(routes)

import React from "react";
import { Outlet } from "react-router-dom";
import ChaptersLayout from "../../components/layout/public/ChaptersLayout";
import NationalChapters from "./NationalChapters";
import { useLocation } from "react-router-dom";

const NationalChaptersLayout = () => {
  const location = useLocation();
  return (
    <>
      <ChaptersLayout>
        <Outlet />
        {location.pathname === "/chapters" ? <NationalChapters /> : null}
      </ChaptersLayout>
    </>
  );
};

export default NationalChaptersLayout;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import OpportunitiesService from "../services/opportunities.service.js";

export const getOpportunities = createAsyncThunk(
    'data/opportunities',
    async({ category_id, country_id, title }) => {
        const res = await OpportunitiesService.opportunitiesItems(category_id, country_id, title)
        return res.data.opportunities
    }
)

const initialState = {
    opportunities: [],
    loading: false,
    error: null
}

const opportunitiesSlice = createSlice({
    name: 'opportunities',
    initialState,
    extraReducers: {
        [getOpportunities.fulfilled]: (state, action) => {
            state.loading = false;
            state.opportunities = action.payload;
        },
        [getOpportunities.rejected]: (state, action) => {
            state.loading = false;
            state.opportunities = action.error.message;
        },
    }
})

export default opportunitiesSlice.reducer
import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

export const Subscribe = (payload) => {
    try {
        const response = axios.post(`${APIRoutes.subscriber}`, payload)
        return response
    } catch (error) {
        console.log(error)
    }
}
import React from 'react'
import { useEffect, useState } from 'react'
import { getSettings } from '../../slices/auth'
import { useDispatch } from 'react-redux'


export default function LoginLeftSide() {
const [item, setItem] = useState([])
const dispatch = useDispatch()

useEffect(() => {
        dispatch(getSettings()).then((res) => {
          setItem(res?.payload?.settings?.data.settings)
    })
  }, [dispatch])

return (

        <div style={{ backgroundImage: `url(${require("../../../assets/images/Rectangle1539.png")})` }} className='hidden sm:block h-[100vh] w-[48%] bg-no-repeat bg-cover bg-center relative'>
            <div className='px-[25px] md:px-[50px] pt-[46px] h-[100%] bg-[rgba(11,36,71,.5)]'>
                <div>
                    <img src={require("../../../assets/images/Group1979.png")} alt="" className='w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%]' />
                </div>
                <div className='absolute top-[50%] w-[85%] md:w-[75%] xl:w-[75%] 2xl:w-[85%] translate-y-[-50%]'>
                    <p className='text-white text-[30px] md:text-[40px] leading-[40px] md:leading-[50px] xl:leading-[60px] '>
                    {item &&
                          item?.find(
                            (x) => x.key === 'site.login_page_title',
                          )?.value}
                    </p>
                </div>
            </div>
        </div>
    )
}

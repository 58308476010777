import React from 'react'
import Navbar from './navbar/Navbar'
import { useLocation } from 'react-router-dom'
import MyCabinetHeader from './MyCabinetHeader'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { FiMenu } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import Search from './Search'

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState(null)

  React.useEffect(() => {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }
    window.addEventListener('scroll', updateScrollDirection) // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection) // clean up
    }
  }, [scrollDirection])

  return scrollDirection
}

function Header() {
  const location = useLocation()
  const locationParse = location.pathname.split('/')
  const scrollDirection = useScrollDirection()

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset)
  const [headerColor, setHeaderColor] = useState('bg-[#E9EDF6]')
  const [openMenu, setOpenMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [searchActive, setSearchActive] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  const listenScrollEvent = () => {
    window.scrollY > 50
      ? setHeaderColor('bg-[#E9EDF6]')
      : setHeaderColor('bg-[#E9EDF6]')
  }
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  return (
    <div className="">
      <div
        className={` ${
          location.pathname === '/login' ||
          location.pathname === '/forgotPassword' ||
          location.pathname === '/resetPassword' ||
          locationParse[1] === 'email'
            ? 'hidden'
            : 'bg-[#E9EDF6] lg:bg-inherit'
        } fixed z-[999999] w-[100%] ${
          !openMenu
            ? scrollDirection === 'down'
              ? '-top-40'
              : 'top-0'
            : 'top-0'
        } h-[5.3rem]  transition-all duration-500`}
      >
        {location.pathname === '/login' ||
        location.pathname === '/forgotPassword' ||
        locationParse[1] === 'email' ||
        location.pathname === '/resetPassword' ? null : (
          <header
            className={`${headerColor} transition-all duration-500 block lg:flex items-center justify-between w-[100%] px-[25px] pt-[20px] pb-[10px] lg:px-[50px] absolute z-[10] ${
              location.pathname === '/'
                ? 'absolute'
                : locationParse[1] === 'myCabinet' ||
                  location.pathname === '/register' ||
                  locationParse[1] === 'chapters' ||
                  locationParse[1] === 'contact'
                ? 'absolute'
                : 'relative'
            }`}
          >
            <div className="flex justify-between items-center">
              {locationParse[1] === 'chapters' ||
              locationParse[1] === 'contact' ||
              locationParse[1] === 'faq' ? (
                screenWidth < 1024 ? (
                  <div className="">
                    <Link to={'/'} onClick={() => setOpenMenu(false)}>
                      <img
                        src={require('../../../../assets/images/newLogo.png')}
                        alt=""
                        className="w-[190px] lg:w-[302px]"
                      />
                    </Link>
                  </div>
                ) : (
                  <div className="">
                    <Link to={'/'}>
                      <img
                        src={require('../../../../assets/images/newLogo.png')}
                        alt=""
                      />
                    </Link>
                  </div>
                )
              ) : (
                <div className="">
                  <Link to={'/'} onClick={() => setOpenMenu(false)}>
                    <img
                      src={require('../../../../assets/images/newLogo.png')}
                      alt=""
                      className="w-[190px] lg:w-[302px]"
                    />
                  </Link>
                </div>
              )}
              {!searchActive ? <FiMenu
                className={`${
                  !openMenu ? 'block' : 'hidden'
                } lg:hidden text-[#2A538A] cursor-pointer text-[30px]`}
                onClick={() => setOpenMenu(!openMenu)}
              /> : ""}
              <MdClose
                className={`${
                  !openMenu ? 'hidden' : 'block'
                } text-[#2A538A] text-[30px] font-bold cursor-pointer`}
                onClick={() => setOpenMenu(!openMenu)}
              />
            </div>
            <Navbar
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              scrollDirection={scrollDirection}
              setSearchActive={setSearchActive}
              searchActive={searchActive}
            />
          </header>
        )}
      </div>
      {location.pathname === '/register' ? <MyCabinetHeader /> : null}
      {searchActive && (
        <Search setSearchActive={setSearchActive} searchActive={searchActive} />
      )}
    </div>
  )
}

// app component

export default Header

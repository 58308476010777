import React from 'react'
import MiniHeader from '../../components/layout/public/components/MiniHeader'
import Bio from '../../components/shared/bio/Bio'
import ProjectsCard from '../../components/shared/card/ProjectsCard'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'
import { getCountries } from '../../components/slices/auth'
import { useMemo } from 'react'
import { Oval } from 'react-loader-spinner'
import MainProjectsCard from '../../components/shared/card/MainProjectsCard'
import { getProjects } from '../../components/slices/projects'

export default function SecretariatDetail() {
  const [secretariatDetailPerson, setSecretariatDetailPerson] = useState({})
  const [countries, setCountries] = useState([])
  const [next, setNext] = useState(2)

  const { id } = useParams()
  const dispatch = useDispatch()
  const handleMoreImage = () => {
    setNext(next + 2)
  }
  useEffect(() => {
    dispatch(getProjects()).then((res) => {
      return res.payload
    })
  }, [dispatch])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DEV_API_URL}api/boardSecretariat/` + id)
      .then((res) => {
        setSecretariatDetailPerson(res.data.data.boardSecretariat)
      })
  }, [id])

  useEffect(() => {
    dispatch(getCountries()).then((res) => {
      setCountries(res?.payload?.countries?.data?.countries)
    })
  }, [dispatch])
  const projects = useSelector((state) => state.projects.projects)

  const oneCountry = useMemo(
    () =>
      countries.find(
        (country) => country.id === +secretariatDetailPerson.country_id,
      ),
    [countries, secretariatDetailPerson.country_id],
  )

  return (
    <div>
      {secretariatDetailPerson && oneCountry ? (
        <>
          <MiniHeader
            bgImage={require('../../assets/images/miniheader.png')}
            name={secretariatDetailPerson.title}
            duty={secretariatDetailPerson.duty}
            personImg={`${process.env.REACT_APP_DEV_API_URL}/storage/${secretariatDetailPerson.image}`}
            specialty={'Expertise Nuclear Weapons Policy'}
            country={oneCountry.name}
            facebook={secretariatDetailPerson.facebook}
            instagram={secretariatDetailPerson.instagram}
          />
          <Bio bioText={secretariatDetailPerson.description} />
          <div className="px-[20px] md:px-[50px] mt-[100px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pb-[100px]">
              {projects &&
                projects
                  ?.slice(0, next)
                  ?.map((project) => (
                    <MainProjectsCard
                      cardId={project.id}
                      image={`${process.env.REACT_APP_DEV_API_URL}storage/${project.cover}`}
                      text={project.title}
                    />
                  ))}
            </div>
            {next < projects?.length && (
              <div className="flex justify-center pb-[137px]">
                <button
                  className="mt-2 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-100"
                  onClick={handleMoreImage}
                >
                  Load more
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  )
}

import React from 'react'
import AboutItem from "../../components/shared/about-item/About-Item"
import {motion} from "framer-motion"


export default function About() {
    return (
        <motion.div 
        initial={{opacity:0}}
        animate={{opacity:1}}

        className='px-[56px] py-[108px] max-[768px]:px-[26px]'>
            <AboutItem className="justify-between"/>
        </motion.div>
    )
}

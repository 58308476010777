// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// export const uploadImage = createAsyncThunk(
//     'image/upload',
//     async(file) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         return new Promise((resolve) => {
//             reader.onloadend = () => {
//                 resolve(reader.result);
//             };
//         });
//     }
// );

// const imageSlice = createSlice({
//     name: 'image',
//     initialState: {
//         url: '',
//         status: 'idle',
//         error: null,
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(uploadImage.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(uploadImage.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.url = action.payload;
//             })
//             .addCase(uploadImage.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             });
//     },
// });

// export default imageSlice.reducer;





















import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    file: null
};

export const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setImage: (state, action) => {
            state.file = action.payload;
        }
    }
});

export const { setImage } = imageSlice.actions;

export const selectImage = state => state.image.file;

export default imageSlice.reducer;
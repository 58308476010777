import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Projects from "../services/projects.service";

export const getProjects = createAsyncThunk(
    'data/projects',
    async() => {
        const res = await Projects.projects()
        return res.data.projects


    }
)

const initialState = {
    projects: [],
    loading: false,
    error: null,
}

const projects = createSlice({
    name: 'projects',
    initialState,
    extraReducers: {
        [getProjects.fulfilled]: (state, action) => {
            state.loading = true;
            state.projects = action.payload;
        },
        [getProjects.rejected]: (state, action) => {
            state.loading = false;
            state.projects = action.error.message;
        },
    }
})

export default projects.reducer
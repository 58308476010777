import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import RegisterButton from '../../../register/register__button/RegisterButton'
import { useLocation } from 'react-router-dom'

import { logout } from '../../../../slices/auth'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { useState } from 'react'
import { APIRoutes } from '../../../../../core/api/routes'
import { IoIosArrowDown } from 'react-icons/io'
import './navbar.css'
import Search from '../Search'
import { MdClose } from 'react-icons/md'

export default function Navbar({
  openMenu,
  setOpenMenu,
  setSearchActive,
  searchActive,
}) {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const locationParse = location.pathname.split('/')
  const [isOpen, setIsOpen] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    })
  }

  const handleItemClick = () => {
    setIsOpen(false)
    setOpenMenu(false)
  }

  const actionDrop = () => {
    setOpen((open) => !open)
  }

  const actionAllDrop = () => {
    actionDrop()
    setOpenMenu(false)
  }
  const color =
    locationParse[1] === 'chapters' ||
    locationParse[1] === 'contact' ||
    locationParse[1] === 'faq'
      ? 'text-[black] lg:text-black'
      : 'text-neutral-700'

  const dispatch = useDispatch()
  const token = JSON.parse(localStorage.getItem('token'))
  const logOut = () => {
    axios
      .get(APIRoutes.logout, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err)
      })
    setOpenMenu(false)

    dispatch(logout())
  }
  const topNav = [
    { to: '/faq', name: 'FAQ', onClick: () => setOpenMenu(false) },
    { to: '/policies', name: 'Policies', onClick: () => setOpenMenu(false) },
    { to: '/contact', name: 'Contacts', onClick: () => setOpenMenu(false) },
    localStorage.getItem('token')
      ? { to: '/login', name: 'Log out', onClick: logOut }
      : { to: '/login', name: 'Log in', onClick: () => setOpenMenu(false) },
  ]
  useEffect(() => {
    openMenu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  }, [openMenu])

  const dropdownLocationColor =
    locationParse[1] === 'chapters' ? 'text-white' : 'text-white'

  return (
    <>
      <nav
        className={`bg-[#E9EDF6] lg:bg-transparent lg:flex justify-end items-center fixed top-[79px] lg:top-0 lg:right-0 h-screen lg:h-auto lg:relative w-[100%] px-[25px] lg:px-0 ${
          !openMenu ? 'right-[-100%] ' : 'right-0 '
        } transition-all duration-500`}
      >
        {searchActive === false ? (
          <>
            <div className="overflow-y-auto lg:overflow-y-visible xl:overflow-y-visible h-[540px] lg:h-auto xl:h-[auto]">
              <div className="block lg:flex justify-end relative">
                <ul className="pb-[13px]">
                  {topNav.map((link) => (
                    <Link
                      onClick={link.onClick}
                      to={link.to}
                      key={link.to}
                      className={`font-normal mt-2 lg:mt-0 text-[18px] lg:text-[11px] xl:text-[14px] block lg:inline ml-0 lg:ml-[30px] hover:opacity-[1] ${color}`}
                    >
                      {link.name}
                    </Link>
                  ))}
                </ul>
                <div
                  className={`h-[1px] w-[70%] ${
                    locationParse[1] === 'chapters' ||
                    locationParse[1] === 'contact' ||
                    locationParse[1] === 'faq'
                      ? 'bg-black'
                      : 'bg-[#727071]'
                  }  opacity-[0.25] absolute lg:right-0 bottom-0`}
                ></div>
              </div>

              <div>
                <div className="block lg:flex justify-end ">
                  <ul className="py-[14.5px] block lg:flex whitespace-nowrap">
                    <div
                      onMouseEnter={() => setIsOpen(true)}
                      onMouseLeave={() => setIsOpen(false)}
                      className={`${
                        isOpen ? 'dropdown' : ''
                      } block lg:flex xl:block uppercase mt-4 lg:mt-0 text-[20px] lg:text-[13px] xl:text-[16px] ml-0 lg:ml-[10px] xl:ml-[22px] ${color}`}
                    >
                      <span
                        className="cursor-default uppercase flex items-center"
                        to={''}
                      >
                        About <IoIosArrowDown className={`ml-[5px]`} />
                      </span>
                      {isOpen && (
                        <ul
                          className={`dropdown-content top-[25px] ease-in duration-700 bg-[#2A538A]`}
                          aria-label="submenu"
                        >
                          <li
                            onClick={actionAllDrop}
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link onClick={handleItemClick} to={'/whoweare'}>
                              Who we are?
                            </Link>
                          </li>
                          <li
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link onClick={handleItemClick} to={'/timeline'}>
                              Timeline
                            </Link>
                          </li>
                          <li
                            onClick={actionAllDrop}
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link onClick={handleItemClick} to={'/about'}>
                              Non-Aligned Movement
                            </Link>
                          </li>
                          <li
                            onClick={actionAllDrop}
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link onClick={handleItemClick} to={'secretariat'}>
                              Secretariat
                            </Link>
                          </li>
                          <li
                            onClick={actionAllDrop}
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link onClick={handleItemClick} to={'board'}>
                            NAMYO Alumni
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                    <li>
                      <Link
                        to={'/chapters'}
                        onClick={() => {
                          setOpenMenu(false)
                          scrollToTop()
                        }}
                        className={`uppercase mt-4 lg:mt-0 text-[20px] lg:text-[13px] xl:text-[16px] block lg:inline ml-0 lg:ml-[10px] xl:ml-[22px] ${color}`}
                      >
                        National Chapters
                      </Link>
                    </li>
                    <div
                      onMouseEnter={() => setIsOpen(true)}
                      onMouseLeave={() => setIsOpen(false)}
                      className={`dropdown block lg:flex xl:block uppercase mt-4 lg:mt-0 text-[20px] lg:text-[13px] xl:text-[16px] ml-0 lg:ml-[10px] xl:ml-[22px] ${color}`}
                    >
                      <span
                        className="cursor-default uppercase flex items-center"
                        to={''}
                      >
                        Opportunities <IoIosArrowDown className={`ml-[5px]`} />
                      </span>
                      {isOpen && (
                        <ul
                          className={`dropdown-content top-[25px] bg-[#2A538A]`}
                          aria-label="submenu"
                        >
                          <li
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link
                              onClick={handleItemClick}
                              to={'opportunities/job'}
                            >
                              Job/Internship
                            </Link>
                          </li>
                          <li
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link
                              onClick={handleItemClick}
                              to={'opportunities/volunteering'}
                            >
                              Volunteering
                            </Link>
                          </li>
                          <li
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link
                              onClick={handleItemClick}
                              to={'opportunities/exchangeprograms'}
                            >
                              Exchange programs
                            </Link>
                          </li>
                          {/* <li
                            className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                          >
                            <Link
                              onClick={handleItemClick}
                              to={'opportunities/scholarships'}
                            >
                              Scholarships
                            </Link>
                          </li> */}
                          {/* <li
                      className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                    >
                      <Link
                        onClick={handleItemClick}
                        to={'opportunities/internships'}
                      >
                        International events
                      </Link>
                    </li> */}
                  </ul>
                )}
                {/* </div> */}
              </div>
              <li>
                <Link
                  to={'/projects'}
                  onClick={() => setOpenMenu(false)}
                  className={`uppercase mt-4 lg:mt-0 text-[20px] lg:text-[13px] xl:text-[16px] block lg:inline ml-0 lg:ml-[10px] xl:ml-[22px] ${color}`}
                >
                  Projects and Events
                </Link>
              </li>
              <div
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                className={`dropdown block lg:flex xl:block uppercase mt-4 lg:mt-0 text-[20px] lg:text-[13px] xl:text-[16px] ml-0 lg:ml-[10px] xl:ml-[22px] ${color}`}
              >
                <span
                  className="cursor-default uppercase flex items-center"
                  onClick={actionDrop}
                >
                  Media <IoIosArrowDown className="ml-[5px]" />
                </span>
                {isOpen && (
                  <ul
                    className={`dropdown-content top-[25px] bg-[#2A538A]`}
                    aria-label="submenu"
                  >
                    <li
                      onClick={actionAllDrop}
                      className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                    >
                      <Link onClick={handleItemClick} to={'blog'}>
                        Blog
                      </Link>
                    </li>
                    <li
                      onClick={actionAllDrop}
                      className={`py-[5px] text-[14px] ${dropdownLocationColor}`}
                    >
                      <Link onClick={handleItemClick} to={'articles'}>
                        Article
                      </Link>
                    </li>
                    <li
                      onClick={actionAllDrop}
                      className={`py-[5px] text-[14px] ${dropdownLocationColor} `}
                    >
                      <Link onClick={handleItemClick} to={'/news'}>
                        News
                      </Link>
                    </li>
                    {/* <li className={`py-[5px] text-[14px] ${dropdownLocationColor}`}>
                      <Link to={''}>Projects</Link>
                    </li> */}
                        </ul>
                      )}
                      {/* </div> */}
                    </div>
                    <li>
                      <Link
                        to={'/calendar'}
                        onClick={() => setOpenMenu(false)}
                        className={`uppercase mt-4 lg:mt-0 text-[20px] lg:text-[13px] xl:text-[16px] block lg:inline ml-0 lg:ml-[10px] xl:ml-[22px] ${color}`}
                      >
                        Calendar
                      </Link>
                    </li>
                    <li
                      onClick={() => setSearchActive(true)}
                      className="ml-4 cursor-pointer flex items-center"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="block lg:hidden">
                <RegisterButton setOpenMenu={setOpenMenu} />
              </div>
            </div>
            <div className="hidden lg:block">
              <RegisterButton setOpenMenu={setOpenMenu} />
            </div>
          </>
        ) : (
          <div onClick={() => setSearchActive(false)}>
            <MdClose className="text-2xl cursor-pointer text-[#2E3652]" />
          </div>
        )}
      </nav>
    </>
  )
}

import React from 'react'

export default function Bio({bioText}) {
  return (
    <div className="px-[50px]">
      <div className='mt-[40px]'>
        <div className='border-b pb-[8px]'>
          <h1 className='text-[#202021] text-[24px] font-bold'>Bio</h1>
        </div>
        <div className='py-[30px] w-[65%] mx-auto'>
          <p className='text-[18px]'>
            {bioText}
          </p>
        </div>
      </div>
    </div>
  )
}

import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useEffect, useState } from 'react'
import { getMemberProfile } from '../../slices/auth'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

function createData(scope, role, action, user, timestamp) {
  return { scope, role, action, user, timestamp }
}

export default function RoleHistoryAnother() {
  const [rolesHistory, setRolesHistory] = useState([])
  const dispatch = useDispatch()
  const token = JSON.parse(localStorage.getItem('token'))
    const {id} = useParams()
//   const id = localStorage.getItem('id')
  useEffect(() => {
    dispatch(getMemberProfile({ id: id, token: token })).then((res) => {
      setRolesHistory(res?.payload?.member?.data?.member?.role_histories)
    })
  }, [])

  


  return (
    <TableContainer
      component={Paper}
      className="px-[52px] pt-[15px] pb-[265px]"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="text-[#202021] text-[24px] font-bold">
              Scope
            </TableCell>
            <TableCell
              align="left"
              className="text-[#202021] text-[24px] font-bold"
            >
              Role
            </TableCell>
            <TableCell
              align="left"
              className="text-[#202021] text-[24px] font-bold"
            >
              Action
            </TableCell>
            <TableCell
              align="left"
              className="text-[#202021] text-[24px] font-bold"
            >
              User
            </TableCell>
            <TableCell
              align="left"
              className="text-[#202021] text-[24px] font-bold"
            >
              Timestamp
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rolesHistory &&
            rolesHistory.map((role) => (
              <TableRow
                key={role.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className="font-bold" component="th" scope="row">
                  NAMYO AZERBAIJAN
                </TableCell>
                <TableCell align="left">{role.role_name}</TableCell>
                <TableCell align="left">
                  {role.action.charAt(0).toUpperCase() + role.action.slice(1)}{' '}
                  by
                </TableCell>
                <TableCell align="left">{role.user}</TableCell>
                <TableCell align="left">{role.date}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

import React from 'react'
import NewsHeader from './InnerHeader'
import { NavLink } from 'react-router-dom'



export default function Opportunities() {
  const links = [
    { name: 'Job', to: 'job' },
    { name: 'Internships', to: 'internships' },
    { name: 'Volunteering', to: 'volunteering' },
    { name: 'Exchange programs', to: 'exchangeprograms' },
    { name: 'Scholarships', to: 'scholarships' },
  ]

  return (
    <>
      <NewsHeader />
      <div className='py-[33px] px-[25px] md:px-[50px]' id='oppHeader'>
        <div className="flex justify-between">
        <ul className="text-[#202021] flex overflow-x-scroll md:overflow-hidden ">
          {links.map((link) => (
            <NavLink
              to={link.to}
              key={link.to}
              className={({ isActive }) =>
                isActive
                  ? 'text-[18px] md:text-[18px] mr-[15px] md:mr-[30px] pb-[5px] md:pb-[10px] border-b-2 md:border-b-4 border-[#0B2477] whitespace-nowrap'
                  : 'text-[18px] md:text-[18px] mr-[15px] md:mr-[30px] whitespace-nowrap'
              }
            >
              {link.name}
            </NavLink>
          ))}
        </ul>
        </div>
      
      </div>
    </>
  )
}

import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

const boardSecretariat = async function getBoardSecretariat(type) {
    try {
        const response = await axios.get(`${APIRoutes.boardSecretariat}?type=${type}`)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

const BoardSecretariat = {
    boardSecretariat
}
export default BoardSecretariat
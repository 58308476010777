import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CabinetLayout from "../../components/layout/public/CabinetLayout";
import MyCabinetView from "./MyCabinetView";
import { useLocation } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMemberProfile } from "../../components/slices/auth";
import { Oval } from "react-loader-spinner";

const MyCabinetLayout = () => {
  const location = useLocation();
  const { member: memberV } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const token = localStorage.getItem('token')

  const userId = localStorage.getItem('id');

  useEffect(() => {
    if(userId && token){
      dispatch(getMemberProfile({ id: userId, token: JSON.parse(token) })).then(response => {
        return response
      })
    }   
  }, [dispatch,userId,token]);
  return (
    <>
    {(!memberV && localStorage.getItem('id')) ? 
     <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
     <Oval
       height={80}
       width={80}
       color="#2A538A"
       wrapperStyle={{}}
       wrapperClass=""
       visible={true}
       ariaLabel="oval-loading"
       secondaryColor="#2A538A"
       strokeWidth={2}
       strokeWidthSecondary={2}
     />
   </div>
      :  <CabinetLayout>
      {/* <ScrollRestoration/> */}
      <Outlet />
      {location.pathname === "/myCabinet" ? <MyCabinetView /> : null}
    </CabinetLayout> }
      
    </>
  );
};

export default MyCabinetLayout;

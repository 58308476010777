import React from 'react'
import { Outlet } from 'react-router-dom'
import TimelineLayout from '../../components/layout/public/TimelineLayout';
import { useLocation } from 'react-router-dom';
import TimeLine from "./Timeline"
export default function AboutLayout() {
    const location = useLocation()
  return (
    <>
    <TimelineLayout>
        <Outlet/>
        {location.pathname === "/timeline" ?<TimeLine/> :'he' }
    </TimelineLayout>
    </>
  )
}

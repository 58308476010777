import React, { useMemo } from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './timeline.css'
import { getTimeline } from '../../components/slices/auth'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import LazyImage from '../../components/lazyLoad/LazyImage'
export default function Timeline() {
  const [timeLine, setTimeLine] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTimeline()).then((res) => {
      setTimeLine(res?.payload?.data?.timelines)
    })
  }, [dispatch])

  const sortTimeLine = useMemo(
    () => timeLine.sort((a, b) => new Date(a.date) - new Date(b.date)),
    [timeLine],
  )


  return (
    <div className="py-[8rem]">
      <VerticalTimeline>
        {sortTimeLine.map((item, index) => (
          <VerticalTimelineElement
            date={`${new Date(item.date).getFullYear()}`}
            dateClassName={`text-[44px] ${
              index % 2 === 1 ? 'pr-[100px]' : 'pl-0 xl:pl-[100px]'
            }`}
            textClassName={'text-[#202021]'}
            className="pb-[80px]"
            key={item?.id}
          >
            <div className="w-[100%]">
              <div
                className={`h-[1px] w-[45px] xl:w-[110px] bg-[gray] absolute ${
                  index % 2 === 1
                    ? 'left-[-40px] xl:left-[-35.5%]'
                    : 'left-[-40px] xl:left-[113.5%]'
                } top-[50px] xl:top-[18%] `}
              ></div>
              <LazyImage
                src={`${process.env.REACT_APP_DEV_API_URL}storage/${item?.image}`}
                width={'100%'}
                height={'260px'}
                smHeight={'160px'}
                cover={'cover'}
              />

              {/* <img
                className="w-[100%] h-[160px] md:h-[260px] object-cover"
                src={`${process.env.REACT_APP_DEV_API_URL}storage/${item?.image}`}
                alt=""
                
              /> */}
              <div
                className={`${
                  index % 2 === 1
                    ? 'left-[-120%]'
                    : 'right-0 xl:right-[-133%] top-[30%]'
                } xl:absolute top-[23%] w-[90%]`}
              >
                <p>{item.description}</p>
              </div>
            </div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  )
}

import React from 'react'
import { Outlet } from 'react-router-dom'
import LayoutNews from "../../components/layout/public/NewsLayout";
import { useLocation } from 'react-router-dom';
import NewsMain from './NewsMain';
export default function NewsLayout() {
    const location = useLocation()
  return (
    <>
    <LayoutNews>
        <Outlet/>
        {location.pathname === "/news" ?<NewsMain/> :null }
    </LayoutNews>
    </>
  )
}

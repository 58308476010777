import React,{useEffect,useState}from 'react'
import ConceptItem from '../../components/shared/concept-item/ConceptItem'
import { getSettings } from '../../components/slices/auth'
import { getTestimonials } from '../../components/slices/auth'
import { useDispatch } from 'react-redux'
const Concept = () => {
  const [item, setItem] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      setItem(res?.payload?.settings?.data?.settings)
    })
  }, [dispatch])
  return (
    <div>
     <div className='px-[56px] py-[108px] max-[912px]:px-[26px]'>
     <ConceptItem className=" pt-[102px] justify-between"/>
    </div>
    <section className='bg-[#E9EDF6] text-[#202021]'>
        <div className='px-[50px] py-[6.68rem]'>
          <div className='flex lg:justify-between max-[768px]:flex-col'>
            <div>
              <h1 className='font-bold md:text-[44px] text-[30px] md:pb-[0] pb-[20px]'>
                Our numbers
              </h1>
            </div>
            <div className='w-[25%] max-[768px]:w-[100%]'>
            <p className="text-[18px] leading-[26px]">
                    {item &&
                      item?.find((x) => x.key === 'site.our_numbers_text')
                        ?.value}
                  </p>
            </div>
          </div>
          <div className='mt-[62px] flex max-[768px]:flex-col'>
            <div className='relative w-[30%]  max-[768px]:w-full max-[768px]:ml-[0] group overflow-hidden h-[300px] pb-[30px] border-b-[1px] border-gray-500 ml-[31px]'>
              <div className='absolute transition-[0.6s] bottom-[-35%] group-hover:bottom-[0%]'>
                <div className='flex'>
                  <h1 className='font-bold text-[72px] '>
                    {item && item?.find((x) => x.key === 'site.our_numbers_1') ?.value}</h1>
                  {/* <span className='text-[49px] ml-[11px] font-bold'>+</span> */}
                </div>
                <div className=''>
                <h1 className="font-bold text-[19px]">{item &&item?.find((x) => x.key === 'site.our_numbers_1_title')?.value}
                      </h1>
                </div>
                <div className='pt-[20px] pb-[31px]  max-[768px]:pb-[116px]'>
                <p className="text-[19px] leading-[24px]">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_1_text')
                            ?.value}
                      </p>
                </div>
              </div>
            </div>
            <div className='relative w-[30%] max-[768px]:w-full  max-[768px]:ml-[0] group overflow-hidden h-[300px] pb-[30px] border-b-[1px] border-gray-500 ml-[31px]'>
              <div className='absolute transition-[0.6s] bottom-[-35%] group-hover:bottom-[0%]'>
                <div className='flex'>
                <h1 className="font-bold text-[72px] ">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_2')
                            ?.value}
                      </h1>
                </div>
                <div className=''>
                <h1 className="font-bold text-[19px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.our_numbers_2_title',
                          )?.value}
                      </h1>
                </div>
                <div className='pt-[20px] pb-[31px] max-[768px]:pb-[116px]'>
                <p className="text-[19px] leading-[24px]">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_2_text')
                            ?.value}
                      </p>
                </div>
              </div>
            </div>
            <div className='relative w-[30%] max-[768px]:w-full  max-[768px]:ml-[0] group overflow-hidden h-[300px] pb-[30px] border-b-[1px] border-gray-500 ml-[31px]'>
              <div className='absolute transition-[0.6s] bottom-[-35%] group-hover:bottom-[0%]'>
                <div className='flex'>
                <h1 className="font-bold text-[72px] ">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_3')
                            ?.value}
                      </h1>
                </div>
                <div className=''>
                <h1 className="font-bold text-[19px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.our_numbers_3_title',
                          )?.value}
                      </h1>
                </div>    
                <div className='pt-[20px] pb-[31px] max-[768px]:pb-[116px]'>
                <p className="text-[19px] leading-[24px]">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_3_text')
                            ?.value}
                      </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 </div>
  )
}

export default Concept

import { setMessages, setTest } from "./message";
import axios from "axios";
import { APIRoutes } from '../../core/api/routes'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";
const user = localStorage.getItem("user");
// const token = JSON.parse(localStorage.getItem("token"));


export const register = createAsyncThunk(
    "auth/register",
    async ({ first_name, email, nam_youth_email, password, username, password_confirmation, last_name, nationality, phone_number, gender_id, country, postal_code, city, national_chapter, date_of_birth, avatar, fb, ins, tw,linkedin }, thunkAPI) => {
        try {
            const response = await AuthService.register(first_name, email, nam_youth_email, password, username, password_confirmation, last_name, nationality, phone_number, gender_id, country, postal_code, city, national_chapter, date_of_birth, avatar, fb, ins, tw,linkedin);

            return { user: response }
        } catch (error) {
            const message = error.response.data.error;
            const messageEmail = error.response?.data?.data?.email;
            const messagePassword = error.response?.data?.data?.password;
            const messageFirstName = error.response?.data?.data?.first_name;
            const messageLastName = error.response?.data?.data?.last_name;
            const messageUsername = error.response?.data?.data?.username;
            const messageAvatar = error.response?.data?.data?.avatar;
            const messageNamYouthEmail = error.response?.data?.data?.nam_youth_email;
            const messageNationality = error.response?.data?.data?.nationality;
            const messagePhoneNumber = error.response?.data?.data?.phone_number;
            const messageGenderID = error.response?.data?.data?.gender_id;
            const messageCountry = error.response?.data?.data?.country;
            const messagePostalCode = error.response?.data?.data?.postal_code;
            const messageCity = error.response?.data?.data?.city;
            const messageDateOfBirth = error.response?.data?.data?.date_of_birth;
            const messageStatus = error.response?.status;
            const messageins = error.response?.data?.data?.ins;
            const messagefb = error.response?.data?.data?.fb;
            const messagetw = error.response?.data?.data?.tw;
            const messagelinkedin = error.response?.data?.data?.linkedin;
            const messageNationalChapter = error.response?.data?.data?.national_chapter;

            console.log(error.response)
            const messages = {
                message, messageEmail,messagelinkedin, messagePassword, messageStatus, messageAvatar,
                messageFirstName, messageLastName, messageNamYouthEmail, messageNationality, messagePhoneNumber,
                messageGenderID, messageCountry, messagePostalCode, messageCity, messageDateOfBirth, messageUsername, messageins, messagefb, messagetw, messageNationalChapter
            };
            console.log(messages)
            thunkAPI.dispatch(setMessages(messages));
            return thunkAPI.rejectWithValue();
        }
    }

)

export const login = createAsyncThunk(
    "auth/login",
    async ({ email, password }, thunkAPI) => {
        try {
            const data = await AuthService.login(email, password);
            return { user: data };
        } catch (error) {
            const messageLoginEmail = error.response?.data?.data?.email?.[0];
            const messageLoginPassword = error.response?.data?.data?.password?.[0];
            const messageLogin = error.response?.data?.error;
            const messageStatus = error.response?.status;

            const messages = { messageLogin, messageLoginEmail, messageLoginPassword, messageStatus };
            thunkAPI.dispatch(setMessages(messages));
            return thunkAPI.rejectWithValue();
        }
    }
);
export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

export const forgotPassword = createAsyncThunk(
    'user/forgotPassword',
    async (email, thunkAPI) => {
        try {
            const response = await AuthService.forgotPassword(email);
            return { user: response };
        } catch (error) {
            const messageforgotPassword = error.response?.data?.error;
            const messageEmail = error.response?.data?.data?.email?.[0];
            const messageStatus = error.response?.status;
            const r = error.response?.data;
            console.log(messageforgotPassword)
            console.log(r)
            thunkAPI.dispatch(setMessages({ messageforgotPassword, messageEmail, messageStatus }));
            return thunkAPI.rejectWithValue();
        }
    },
);

// export const getChapters = createAsyncThunk(
//     "auth/allChapters",
//     async () => {
//         const response = await AuthService.allChapters();
//         console.log(response)
//         return response
//     }
// )
// export const getChapters = createAsyncThunk(
//     "auth/allChapters",
//     async ({countryName}) => {
//         try {
//             const params = {};
//             if (countryName) {
//                 params.name = countryName;
//             }
//             const response = await AuthService.allChapters(params);
//             const res = response.data.national_chapters
//             console.log(res)
//             return res;
//         } catch (error) {
//            return error;
//         }
//     }
// );
export const getChapters = createAsyncThunk(
    "auth/allChapters",
    async ({countryName}, thunkAPI) => {
        try {
            const params = {};
            if (countryName) {
                params.name = countryName;
                const response = await axios.get(APIRoutes.nationalChapters, {
                    params,
                });
                const res = response.data.data.national_chapters
                return res;
            }
            else{
                const response = await axios.get(APIRoutes.nationalChapters);
                const res = response.data.data.national_chapters
                return res;
            }
            
        } catch (error) {
            const message = error.response?.data?.error;
            const messageStatus = error.response?.status;
            const messageFirstName1 = error.response?.data?.data?.first_name
            const messageLastName1 = error.response?.data?.data?.last_name


            const messages = { message, messageStatus, messageFirstName1, messageLastName1 };

            thunkAPI.dispatch(setMessages(messages));
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getCountries = createAsyncThunk(
    "auth/countries",
    async () => {
        const response = await AuthService.countries();
        return { countries: response }
    }
)

export const getMemberProfile = createAsyncThunk(
    "auth/memberProfile",
    async ({ id, token }) => {
        try{
            const res = await AuthService.memberProfile(id, token)
        return {member:res}
        }
        catch(error){
            console.log(error)
        }
    }
)

export const member = createAsyncThunk(
    "auth/member",
    async ({ firstNameN, lastNameN, chapterMembers, inputValue: chapterId, inputValueRole: roleId }, thunkAPI) => {
        try {
            const params = {};
            if (firstNameN) {
                params.first_name = firstNameN;
            }
            if (lastNameN) {
                params.last_name = lastNameN;
            }
            if (chapterId) {
                params.national_chapter_id = chapterId
            }
            if (roleId) {
                params.role_id = roleId
            }
            if (chapterMembers) {
                params.national_chapter_id  = chapterMembers
            }

            const response = await axios.get(APIRoutes.allMember, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
                params,
            });
            const res = response.data.data;
            return res;
        } catch (error) {
            const message = error.response?.data?.error;
            const messageStatus = error.response?.status;
            const messageFirstName1 = error.response?.data?.data?.first_name
            const messageLastName1 = error.response?.data?.data?.last_name


            const messages = { message, messageStatus, messageFirstName1, messageLastName1 };

            thunkAPI.dispatch(setMessages(messages));
            return thunkAPI.rejectWithValue();
        }
    }
);

// export const getChapterForId = createAsyncThunk(
//     "auth/chapterId",
//     async ({ id }) => {
//         const res = await AuthService.chapterForId(id)
//         return { chapId: res }
//     }
// )

export const getRoles = createAsyncThunk(
    "auth/roles",
    async () => {
        try {
            const response = await AuthService.allRoles();
            return response.data
        } catch (error) {
            console.log(error)
            return error
        }
    }
);
export const getContact = createAsyncThunk(
    "auth/contact",
    async ({ name, surname,email,message }, thunkAPI) => {
        try {
            const data = await AuthService.contact(name, surname,email,message);
            return { user: data };
        } catch (err) {
            // const messageEmail = error.response?.data?.data?.email;
            // const messageText = error.response?.data?.data?.message;
            // const messageName = error.response?.data?.data?.name;
            // const messageSurname = error.response?.data?.data?.surname;
            // const messageError = error.response?.data?.error;
            // console.log(message)
            // console.log(messageText)
            // console.log(messageName)
            // console.log(messageSurname)
            const messages = {};
            // thunkAPI.dispatch(setMessages(messages));

            if(err.response?.data) {
                const {error} = err.response.data

                messages.error = error
            }

            if(err.response?.data?.data) {
                const {email, message, name, surname} = err.response.data.data

                messages.messageEmail = email
                messages.messageText = message
                messages.messageName = name
                messages.messageSurname = surname
            }

            thunkAPI.dispatch(setMessages(messages))
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getAllNews = createAsyncThunk(
    "auth/news",
    async({year,month,day,title}) => {
        try{
            const response = await AuthService.allNews(year,month,day,title);
            return {news:response.data}
        }
        catch(error) {
            console.log(error)
        }
    }
)

export const newsById = createAsyncThunk(
    "auth/newsId",
    async({id},thunkAPI) => {
        try{
            const response = await AuthService.getNewsById(id);
            
            return {newsId:response.data}
        }
        catch(error){
            console.log(error)
        }
    }
)
export const getAbout = createAsyncThunk(
    "auth/about",
    async () => {
        const response = await AuthService.about();
        return { about: response }
    }
)
export const getTimeline = createAsyncThunk(
    "auth/timeline",
    async() => {
        const response = await AuthService.timeLine();
        return response
    }
)

export const getConcepts = createAsyncThunk(
    "auth/concepts",
    async () => {
        const response = await AuthService.concepts();
        return { concepts: response }
    }
)

export const getSettings = createAsyncThunk(
    "auth/settings",
    async () => {
        const response = await AuthService.settings();
        return { settings: response }
    }
)

export const getTestimonials = createAsyncThunk(
    "auth/testimonials",
    async () => {
        const response = await AuthService.testimonials();
        return { settings: response }
    }
)
export const getFags = createAsyncThunk(
    "auth/fags",
    async () => {
        const response = await AuthService.fags();
        return { fags: response }
    }
)

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };


const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        // [getChapterForId.fulfilled]: (state, action) => {
        //     state.isLoggedIn = true;
        //     state.user = action.payload;
        // },
        // [getChapterForId.rejected]: (state, action) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
        [getMemberProfile.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.member = action.payload;
        },
        [getMemberProfile.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [getContact.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        [getContact.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },


    },
});

const { reducer } = authSlice;
export default reducer;
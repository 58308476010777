import React from 'react'
import { RiSearchLine } from "react-icons/ri"

export default function SearchInput({placeholder,value,className,SearchClick,handleSearch}) {
    return (
        <>
            <input type="text" value={value} onChange={handleSearch} className="focus-visible:border-none text-[#202021] text-[18px] border font-light text-sm  block w-full p-2.5" placeholder={placeholder} />
            <div className='border-l border-black'>
                <button className={`px-[26px] py-[18px] ${className}`} onClick={SearchClick}>
                    <RiSearchLine className='text-[black] text-[25px] font-bold' />
                </button>
            </div>
        </>
    )
}

import React from 'react'
import NewsHeader from '../../components/layout/public/components/InnerHeader'
import MainProjectsCard from '../../components/shared/card/MainProjectsCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getProjects } from '../../components/slices/projects'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { motion } from 'framer-motion'

export default function Projects() {
  const dispatch = useDispatch()
  const projects = useSelector((state) => state.projects.projects)
  const loading = useSelector((state) => state.projects.loading)
  const [next, setNext] = useState(6)

  useEffect(() => {
    dispatch(getProjects()).then((res) => {
      return res.payload
    })
  }, [dispatch,loading])
  
  const handleMoreImage = () => {
    setNext(next + 2)
  }

const container = {
  visible:{
    transition:{
      delayChildren:0.5,
      staggerChildren:0.4
    }
  }
}



  return (
    <>
      {!loading ? (
        <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
        <Oval
          height={80}
          width={80}
          color="#2A538A"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#2A538A"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
      ) : (
        <div>
          <NewsHeader
            img={require('../../assets/images/secretariat-pic.png')}
            title={'Projects'}
            text={
              'NAM Youth Organization '
            }
          />
          <div>
            <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
             className="grid grid-cols-1 md:grid-cols-2 gap-10 py-[85px] px-[20px] md:px-[50px]">
              {projects &&
                projects
                  ?.slice(0, next)
                  ?.map((project) => (
                    <MainProjectsCard
                      image={`${process.env.REACT_APP_DEV_API_URL}storage/${project.cover}`}
                      text={project.title}
                      cardId={project.id}
                      key={project.id}
                    />
                  ))}
            </motion.div>
            {projects && projects.length > 6 && (
              <div className="flex justify-center pb-[137px]">
                <button
                  className="mt-2 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-100"
                  onClick={handleMoreImage}
                >
                  Load more
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

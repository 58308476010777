import React, { useState, useEffect } from 'react'
import {
  FaFacebookF as Facebook,
  FaInstagram as Instagram,
  FaYoutube as Youtube,
  FaLinkedinIn as Linkedin,
  FaTelegramPlane as Telegram,
} from 'react-icons/fa'
import { RiTwitterXFill as XTwitter } from "react-icons/ri";
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getSettings } from '../../../slices/auth'
import { useDispatch } from 'react-redux'
import { Link as LinkRoll } from 'react-scroll'
import { Subscribe } from '../../../services/subscriber.service'
import { MdOutlineDone } from 'react-icons/md'

const Footer = () => {
  const location = useLocation()
  const [item, setItem] = useState([])
  const [subscribeEmail, setSubscribeEmail] = useState('')
  const [emailMessage, setEmailMessage] = useState('')
  const [status, setStatus] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const url = 'https://api.namyouth.org/storage/'
  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      setItem(res?.payload?.settings?.data?.settings ?? [])
    })
  }, [dispatch])

  const facebookLink = item?.find((x) => x.key === 'site.facebook_url')?.value
  const instagramLink = item?.find((x) => x.key === 'site.instagram_url')?.value
  const youtubeLink = item?.find((x) => x.key === 'site.youtube_url')?.value
  const linkedinLink = item?.find((x) => x.key === 'site.linkedin_url')?.value
  const telegramLink = item?.find((x) => x.key === 'site.telegram_url')?.value
  const xTwitterLink = item?.find((x) => x.key === 'site.x_social_link')?.value
  
  const footerLogo = item?.find((x) => x.key === 'site.footer_logo')?.value

  const locationParse = location.pathname.split('/')
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const SendNewsLetter = () => {
    setLoading(false)
    Subscribe({ email: subscribeEmail })
      .then((res) => {
        if (res.status === 200) {
          setEmailMessage('You have joined the newsletter')
          setStatus(true)
          setLoading(true)
          setSubscribeEmail('')
        }
      })
      .catch((error) => {
        setEmailMessage(error.response.data.data.email[0])
        setStatus(false)
      })
    setSubscribeEmail('')
  }

  return (
    <>
      {location.pathname === '/login' ||
        location.pathname === '/forgotPassword' ||
        locationParse[1] === 'email' ||
        location.pathname === '/resetPassword' ? null : (
        <div className="font-america">
          <div className="bg-[#7F97B8] px-[25px] md:px-[50px] lg:max-w-[100%] md:max-w-[100%] sm:max-w-[100%]">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 py-[80px] relative">
              <div>
                <span className="font-bold text-[28px] text-[white] max-[768px]:text-[16px]">
                  Subscribe to receive the latest
                  <br />
                  updates from NAM Youth Organization
                </span>
                {/* <p className="pt-[35px] text-[18px] max-[768px]:text-[14px] max-[768px]:pb-[14px] max-[768px]:pt-[16px]">
                  {item?.find((x) => x.key === 'site.subscribe_text')?.value}
                </p> */}
              </div>
              <div className="lg:absolute right-0 top-[25%]">
                <h3 className="text-[24px] leading-7 font-bold pb-[20px] text-[white]">
                  Join Newsletter
                </h3>
                <div className="flex items-center">
                  <input
                    onChange={(e) => setSubscribeEmail(e.target.value)}
                    value={subscribeEmail}
                    className="max-[768px]:w-[74%] max-[768px]:p-[16px] placeholder:text-[white] lg:w-[433px] md:w-[80%] sm:w-[64%] p-[21px] text-[white] border-[white] border border-solid bg-transparent"
                    type="text"
                    placeholder="Enter email"
                  />
                  <button
                    onClick={SendNewsLetter}
                    disabled={loading}
                    className={`max-[768px]:w-[82px] max-[768px]:h-[58px] max-[768px]:text-[14px] bg-[white] hover:bg-[#2A538A] hover:text-[white] ease-in duration-300 w-[104px] h-[68px] text-[18px] font-bold text-[#011837] ${status ? 'cursor-not-allowed' : ''
                      }`}
                  >
                    {status ? 'SENT' : 'SEND'}
                  </button>
                  {status && (
                    <div className="pl-[10px]">
                      <MdOutlineDone className="text-[green] text-[35px] md:text-[50px]" />{' '}
                    </div>
                  )}
                </div>
                <h1 className="text-[white] mt-[5px]">{emailMessage}</h1>
              </div>
            </div>
          </div>
          <div className="bg-[#011837] text-[#ffffff]">
            <div className="px-[25px] md:px-[50px] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1  pt-[97px]">
              <div className="border-[#707070] border-b-[1px] border-solid">
                <h3 className="uppercase text-[20px] leading-6 font-bold mt-[30px] md:mt-[0px]">
                  About Organization
                </h3>

                <ul className="pt-[20px] md:pt-[40px] text-base">
                  <li className="pb-3 text-[16px]">
                    <Link to={'/chapters'}> National Chapters</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <Link to={'/whoweare'}>Concept</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <Link to={'/timeline'}>Timeline</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <Link to={'/about'}>Non-Aligned Movement</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <Link to={'/secretariat'}>Secretariat</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <Link to={'/board'}>NAMYO Alumni</Link>
                  </li>
                </ul>
              </div>
              <div className="border-[#707070] border-b-[1px] border-solid">
                <h3 className="uppercase text-[20px] leading-6 font-bold mt-[30px] md:mt-[0px]">
                  Learn More
                </h3>

                <ul className="pt-[20px] md:pt-[40px] text-base">
                  <li className="pb-3 text-[16px]">
                    <Link to={'/blog'}>Blog</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <Link to={'/news'}>News</Link>
                  </li>

                  <li className="pb-3 text-[16px]">
                    <Link to={'/projects'}>Projects</Link>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <LinkRoll
                      to="oppHeader"
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      <Link to={'/opportunities/job'} onClick={scrollToTop}>
                        Job/Internship
                      </Link>
                    </LinkRoll>
                  </li>
                  <li className="pb-3 text-[16px]">
                    <LinkRoll to="oppHeader" smooth={true}>
                      <Link
                        to={'/opportunities/exchangeprograms'}
                        onClick={scrollToTop}
                      >
                        Exchange programs
                      </Link>
                    </LinkRoll>
                  </li>
                  {/* <li className="pb-3 text-[16px]">
                    <LinkRoll to="oppHeader" smooth={true}>
                      <Link
                        to={'/opportunities/scholarships'}
                        onClick={scrollToTop}
                      >
                        Scholarships
                      </Link>
                    </LinkRoll>
                  </li> */}
                  <li className="pb-3 text-[16px]">
                    <LinkRoll to="oppHeader" smooth={true}>
                      <Link
                        to={'/opportunities/internships'}
                        onClick={scrollToTop}
                      >
                        Internship
                      </Link>
                    </LinkRoll>
                  </li>
                </ul>
              </div>
              <div className="border-[#707070] border-b-[1px] border-solid">
                <h3 className="uppercase text-[20px] leading-6 font-bold mt-[30px] md:mt-[0px]">
                  Resources
                </h3>

                <ul className="pt-[20px] md:pt-[40px] text-base">
                  <li className="pb-3">
                    <Link to={'/calendar'}>Calendar</Link>
                  </li>
                  <li className="pb-3">
                    <Link to={'/faq'}>FAQ</Link>
                  </li>
                  <li className="pb-3"><Link to={'/policies'}>Policies</Link></li>
                  <li className="pb-3">
                    <Link to={'/contact'}>Contact us</Link>
                  </li>
                </ul>
              </div>

              <div className="grid grid-flow-row auto-rows-max relative border-[#707070] border-b-[1px] border-solid">
                <img
                  className="pb-[20px] md:pb-[70px] mt-[30px] md:mt-[0px]"
                  src={url + footerLogo}
                  alt=""
                />
                <span className="pb-[18px]">
                  {' '}
                  {item?.find((x) => x.key === 'site.contact_phone')?.value}
                </span>
                <span className="pb-[18px]">
                  {' '}
                  {item?.find((x) => x.key === 'site.contact_email')?.value}
                </span>
                <span className="pb-[18px]">
                  60-62 Bulbul Avenue, Baku, Azerbaijan
                </span>
                {/* <span className="pb-[18px]">
                  Lorem ipsum dolor sit, amet consectetur
                </span> */}

                {/* <div className="mt-10">
              <select className="text-black" name="" id="">
                <option>English</option>
                <option>English</option>
              </select>
            </div> */}

                <div className="mt-8 py-[45px] md:py-[90px]">
                  <ul className="flex">
                    <li className="pr-[30px]">
                      {facebookLink && (
                        <Link to={facebookLink}>
                          <Facebook />
                        </Link>
                      )}
                    </li>
                    <li className="pr-[30px]">
                      {instagramLink && (
                        <Link to={instagramLink}>
                          <Instagram />
                        </Link>
                      )}
                    </li>
                    <li className="pr-[30px]">
                      {youtubeLink && (
                        <Link to={youtubeLink}>
                          <Youtube />
                        </Link>
                      )}
                    </li>
                    <li className="pr-[30px]">
                      {linkedinLink && (
                        <Link to={linkedinLink}>
                          <Linkedin />
                        </Link>
                      )}
                    </li>
                    <li className="pr-[30px]">
                      {xTwitterLink && (
                        <Link to={xTwitterLink}>
                          <XTwitter />
                        </Link>
                      )}
                    </li>
                    <li className="pr-[30px]">
                      {telegramLink && (
                        <Link to={telegramLink}>
                          <Telegram />
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="px-[50px] py-[30px]">
              <span>@2023 NAM Youth Organization. All rights reserved.</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer

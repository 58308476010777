import React, { useState, useEffect } from 'react'
import AboutImg from '../../../assets/images/about-1.png'
import { getAbout } from '../../slices/auth'
// import { getCountries } from "../../slices/auth";
import { useDispatch, useSelector } from 'react-redux'
import LazyImage from '../../lazyLoad/LazyImage'

const AboutItem = ({ className }) => {
  const [about, setAbout] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAbout()).then((res) => {
      setAbout(res.payload.about.data.about)
    })
  }, [dispatch])

  return (
    <div className={`flex ${className} flex-col item-nth`}>
      {about?.map((item) => (
        <div className="flex gap-x-[32px] max-[912px]:flex-col">
          <img src={`https://api.namyouth.org/storage/${item.image}`} alt="" className='object-cover lg:w-[652px] lg:h-[545px] md:w-[545px] md:h-[500px] w-[100%] h-[100%]' />
          <div>
            <h4 className="font-bold text-[42px] leading-[43px] py-[20px] md:py-[50px] max-[768px]:text-[24px]">
              {item.title}
            </h4>
            <p className="text-[16px] leading-[28px]">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AboutItem

import axios from "axios";
import { APIRoutes } from "../../core/api/routes";

export const DisLike = async(id) => {
    const response = await axios.post(`${APIRoutes.dislike}`, id, {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
    })
    return response
}
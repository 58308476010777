import axios from 'axios'

import { APIRoutes } from '../../core/api/routes'

const getArticlesSearch = async(title) => {
    try {
        if (title) {
            const response = await axios.get(`${APIRoutes.article}?title=${title}`)
            return response.data
        } else {
            const response = await axios.get(APIRoutes.article)
            return response.data
        }
    } catch (error) {
        console.log(error)
    }
}

export default getArticlesSearch
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    file: null
};

export const imageSlice = createSlice({
    name: 'imageEdit',
    initialState,
    reducers: {
        setImageEdit: (state, action) => {
            state.file = action.payload;
        }
    }
});

export const { setImageEdit } = imageSlice.actions;

export const selectImageEdit = state => state.imageEdit.file;

export default imageSlice.reducer;
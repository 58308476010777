import React from 'react'
import MyCabinetHeader from './components/MyCabinetHeader'
import { useDispatch, useSelector } from 'react-redux'
import { BsDot } from 'react-icons/bs'
import { useEffect } from 'react'
import { useState } from 'react'
import { getMemberProfile, member } from '../../slices/auth'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Oval } from 'react-loader-spinner'

export default function ChaptersLayout({ children }) {
  const { member: memberV } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(true)

  const token = JSON.parse(localStorage.getItem('token'))
  const id = localStorage.getItem('id')
  const dispatch = useDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getMemberProfile({ id: id, token: token })).then((response) => {
      return response
    })
    setLoading(false)
  }, [dispatch, id, token])
  return (
    <>
      {memberV?.member?.data?.member?.id == id ? (
        <>
          <MyCabinetHeader />
          {children}
        </>
      ) : (
        <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </>
  )
}

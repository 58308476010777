import axios from 'axios';
import { APIRoutes } from '../../core/api/routes';

const projects = async function getProjects() {
    try {
        const response = await axios.get(APIRoutes.projects)
        return response.data


    } catch (error) {
        console.log(error)
    }
}

const Projects = {
    projects
}
export default Projects
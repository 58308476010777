import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BsDot } from "react-icons/bs";
import { useEffect } from 'react';
import { useState } from 'react';
import { getMemberProfile, member } from '../../slices/auth';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function RoleHistory() {
  const { member: memberV } = useSelector((state) => state.auth)

  const token = JSON.parse(localStorage.getItem('token'));
  const id = localStorage.getItem('id');
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getMemberProfile({ id: id, token: token })).then(response => {
      return response
    })
  }, [dispatch,id,token]);
  return (
    <>
    <div className="px-[25px] lg:px-[50px] pt-[65px] pb-[265px] flex max-[768px]:flex-col">
      <div className='w-[100%] md:w-[40%]'>
        <h6 className="text-[#202021] text-[24px] font-bold border-b pb-[19px]">Personal Details</h6>
        <ul>
          <li className="flex gap-x-[104px] pb-[13px] pt-[41px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>First Name</span> <span>{memberV?.member?.data?.member?.first_name || <Skeleton />}</span></li>
          <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>Last Name</span> <span>{memberV?.member?.data?.member?.last_name|| <Skeleton />}</span></li>
          <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>Gender</span> <span>{memberV?.member?.data?.member?.gender_id === 2 ? "Female" : 1 ? "Male" : 3 ? "Other" : ""}</span></li>
          <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>Date of Birth</span> <span>{memberV?.member?.data?.member?.date_of_birth || <Skeleton />}</span></li>
          <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>Nationality</span> <span>{memberV?.member?.data?.member?.nationality|| <Skeleton />}</span></li>
          <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>Phone Number</span> <span>{memberV?.member?.data?.member?.phone_number || <Skeleton />}</span></li>
          {/* <li className="flex gap-x-[104px] pb-[13px] pt-[10px] border-b"><span className='font-bold text-[15px] text-[#1C1C1C] w-[107px]'>Address</span> <span>{email || <Skeleton />}</span></li> */}
        </ul>
      </div>
      <div className='w-[100%]'>
      <h6 className="pl-0 md:pl-[115px] lg:pl-[333px] xl:pl-[420px] text-[#202021] text-[24px] font-bold border-b pb-[19px] mt-[50px] md:mt-[0px]">NAM Involvement</h6>
        <ul className=''>
        <li  className="pl-0 md:pl-[115px] lg:pl-[333px] xl:pl-[420px] gap-x-[104px] pb-[13px] pt-[41px] border-b"><span>NAM Youth Azerbaijan</span></li>
        {memberV?.member?.data?.member?.roles.length !==0 ? 
        memberV?.member?.data?.member?.roles?.map(role => (
          <li className="px-0 md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex items-center" key={role?.id} ><BsDot /><span>{role?.name || <Skeleton />}</span></li>
        )) 
        : 
        <>
        <li className="pl-[0] md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span>{ <Skeleton />}</span></li>  
        <li className="pl-[0] md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span>{ <Skeleton />}</span></li>  
        <li className="pl-[0] md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span>{ <Skeleton />}</span></li>  
        <li className="pl-[0] md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span>{ <Skeleton />}</span></li>  
        <li className="pl-[0] md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] border-b flex" ><span>{ <Skeleton />}</span></li>  
        <li className="pl-[0] md:pl-[115px] lg:pl-[333px] xl:pl-[420px] pb-[13px] pt-[10px] flex"><span>{ <Skeleton />}</span></li>  
        </>
        }    
        </ul>
      </div>
    </div>
    </>
  );
}

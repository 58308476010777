import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setImage } from '../../../slices/addImg'
import { setImageEdit } from '../../../slices/addImgEdit'
import { ResendEmailVerify } from '../../../slices/emailVerifyResend'
import 'react-loading-skeleton/dist/skeleton.css'
import Swal from 'sweetalert2'
import '../../../../sweetalertsucces.css'
import { getMemberProfile } from '../../../slices/auth'

const MyCabinetHeader = (props) => {
  const location = useLocation()
  const locationParse = location.pathname.split('/')
  const url = 'https://api.namyouth.org/storage/'
  const [img, setImg] = useState('')
  const [imgEdit, setImgEdit] = useState('')

  const dispatch = useDispatch()
  const id = localStorage.getItem('id')
  const token = localStorage.getItem('token')
  const [loadingButton, setLoadingButton] = useState(false)

  const { member: memberV } = useSelector((state) => state.auth)

  // const userData = JSON.parse(localStorage.getItem('user'))
  const myCabinetNav = [
    { to: 'view', name: 'View' },
    { to: 'edit', name: 'Edit' },
    { to: 'roleHistory', name: 'Role History' },
    memberV?.member?.data?.member?.email_verified_at === null ||
    memberV?.member?.data?.member?.roles === null
      ? ''
      : { to: 'userSearch', name: 'User Search' },
    memberV?.member?.data?.member?.email_verified_at === null ||
    memberV?.member?.data?.member?.roles === null
      ? ''
      : { to: 'myCabinetArticles', name: 'Articles' },
    memberV?.member?.data?.member?.email_verified_at === null ||
    memberV?.member?.data?.member?.roles === null
      ? ''
      : { to: 'myCabinetBlog', name: 'Blog' },
  ]

  const inputRef = useRef(null)
  const inputRefEdit = useRef(null)

  const handleClick = () => {
    //open file input box on click of another element
    inputRef.current.click()
  }

  const handleClickEdit = () => {
    inputRefEdit.current.click()
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const fileObj = event.target.files && event.target.files[0]
    const pp = new FormData()
    pp.append('img', fileObj)

    if (!fileObj) {
      return
    }
    const objectUrl = URL.createObjectURL(fileObj)

    dispatch(setImage(fileObj))
    setImg(objectUrl)
  }

  const { messageAvatar } = useSelector((state) => state.messages)
  // console.log(currentUser)

  // in edit add image
  const handleFileChangeEdit = (event) => {
    event.preventDefault()
    const fileObjEdit = event.target.files && event.target.files[0]
    const ee = new FormData()
    ee.append('img', fileObjEdit)
    if (!fileObjEdit) {
      return
    }
    const objectUrlEdit = URL.createObjectURL(fileObjEdit)

    dispatch(setImageEdit(fileObjEdit))
    setImgEdit(objectUrlEdit)
    // console.log(fileObjEdit)
  }

  const resendEmailVerify = () => {
    setLoadingButton(true)
    dispatch(ResendEmailVerify({ token: token })).then((res) => {
      setLoadingButton(false)
      Swal.fire(
        'A confirmation link has been sent to your email. Please check it',
        '',
        'success',
      )
    })
  }
  const userId = localStorage.getItem('id')

  useEffect(() => {
    if (userId && token) {
      dispatch(getMemberProfile({ id: userId, token: JSON.parse(token) })).then(
        (response) => {
          return response
        },
      )
    }
  }, [dispatch, userId, token])

  return (
    <>
      {!memberV && localStorage.getItem('id') ? (
        <div className="h-[100vh] bg-black text-white text-[50px] text-center flex items-center justify-center">
          Loading...
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `url(${require('../../../../assets/images/my-cabinet-bg.png')})`,
            }}
            className="transition-all duration-500 h-[500px] w-[100%] bg-no-repeat bg-cover relative"
          >
            <div className="bg-[#0B2447] bg-opacity-50 h-[100%]">
              <div className="flex justify-center">
                {location.pathname === '/register' ||
                location.pathname === '/myCabinet' ||
                location.pathname === '/myCabinet/view' ||
                location.pathname === '/myCabinet/roleHistory' ||
                location.pathname === '/myCabinet/myCabinetBlog' ||
                location.pathname === '/myCabinet/myCabinetArticles' ||
                location.pathname === '/myCabinet/userSearch' ? (
                  <div className="absolute left-[50%] top-[55%] translate-x-[-50%] translate-y-[-50%] rounded-full w-[200px] h-[200px] md:w-[260px] md:h-[260px]">
                    <div className="relative">
                      <img
                        className="opacity-[50] object-cover w-[200px] h-[200px] md:w-[260px] md:h-[260px] border-[#FFFFFF] border-[8px] border-solid rounded-full"
                        src={
                          !id
                            ? img
                              ? img
                              : ''
                            : url + memberV?.member?.data?.member?.avatar
                        }
                        alt=""
                      />
                      <div className="bg-[rgba(11,36,71,.5)] absolute w-[100%] h-[100%] top-0 rounded-full"></div>
                    </div>

                    {location.pathname === '/register' ? (
                      <div className="grid absolute translate-x-[-50%] translate-y-[-50%] left-[50%] top-[50%] text-center gap-10">
                        <div></div>
                        <div className="flex justify-center">
                          <button
                            onClick={handleClick}
                            className="px-[60px] py-[60px] rounded-full flex flex-col items-center justify-center"
                          >
                            <h1 className="text-[15px] font-bold text-[white] mt-[70px]">
                              Upload Picture
                            </h1>
                            <img
                              src={require('../../../../assets/images/download.png')}
                              alt=""
                              className="w-[33px] h-[26px] mt-[22px]"
                            />
                          </button>
                          <input
                            style={{ display: 'none' }}
                            ref={inputRef}
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleFileChange}
                          />
                        </div>
                        <span className="text-[red] w-[242px]">
                          {messageAvatar}
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  // For edit
                  <div
                    className="absolute left-[50%] top-[55%] translate-x-[-50%] translate-y-[-50%] rounded-full cursor-pointer w-[200px] h-[200px] md:w-[260px] md:h-[260px] "
                    onClick={handleClickEdit}
                  >
                    <img
                      src={
                        imgEdit
                          ? imgEdit
                            ? imgEdit
                            : require('../../../../assets/images/my-cabinet-profile.png')
                          : url + memberV?.member?.data?.member?.avatar
                      }
                      alt=""
                      className="object-cover w-[200px] h-[200px] md:w-[260px] md:h-[260px] border-[#FFFFFF] border-[8px] border-solid rounded-full"
                    />
                    <div className="grid absolute translate-x-[-50%] z-10 left-[50%] top-[58%] text-center gap-10">
                      {/* <div>
             <Link className="text-[15px] text-[#FFFFFF] font-bold">
               Remove picture
             </Link>
           </div> */}
                      <div className="">
                        <button
                          onClick={handleClickEdit}
                          className="rounded-full flex flex-col items-center justify-center"
                        >
                          <h1 className="text-[12px] md:text-[15px] font-bold text-[white]">
                            Update picture
                          </h1>
                          <img
                            src={require('../../../../assets/images/download.png')}
                            alt=""
                            className="w-[26px] h-[20px] md:w-[33px] md:h-[26px] mt-[12px] md:mt-[22px]"
                          />
                        </button>
                        
                        <input
                          style={{ display: 'none' }}
                          ref={inputRefEdit}
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleFileChangeEdit}
                        />
                        {messageAvatar}
                      </div>
                    </div>
                    <div className="bg-[rgba(11,36,71,.5)] absolute w-[100%] h-[100%] top-0 rounded-full"></div>
                  </div>
                )}
              </div>

              <div className="text-center absolute bottom-[5%] left-[50%] translate-x-[-50%] text-[#FFFFFF] font-bold text-[24px] leading-7">
                <span className="text-[21px] md:text-[24px] ">
                  {token ? (
                    <span>
                      {memberV?.member?.data?.member?.first_name} {memberV?.member?.data?.member?.last_name}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="py-[33px] px-[25px] md:px-[50px] flex justify-between items-center">
            <ul className="text-[#202021] flex overflow-x-scroll md:overflow-auto">
              {locationParse[1] === 'myCabinet' ? (
                myCabinetNav.map((link) => (
                  <NavLink
                    to={link.to}
                    key={link.to}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-[14px] md:text-[18px] mr-[15px] md:mr-[30px] pb-[5px] md:pb-[10px] border-b-2 md:border-b-4 border-[#2A538A] whitespace-nowrap	'
                        : 'text-[14px] md:text-[18px] mr-[15px] md:mr-[30px] whitespace-nowrap	'
                    }
                  >
                    {link.name}
                  </NavLink>
                ))
              ) : (
                <Link to={'/register'} className=" text-[18px] mr-[30px]">
                 
                </Link>
              )}
            </ul>
            {memberV?.member?.data?.member?.email_verified_at === null ? (
              <button
                onClick={resendEmailVerify}
                className={`bg-[#2A538A] border-[#2A538A] hover:bg-[white] hover:text-[#2A538A] text-white font-bold py-2 px-4 rounded duration-300 border ${
                  loadingButton ? 'cursor-not-allowed opacity-50' : ''
                }`}
              >
                {loadingButton ? 'Loading...' : 'Resend Email'}
              </button>
            ) : null}

            {/* <hr className="mt-[10px] md:mt-[15px] mb-[70px]" /> */}
          </div>
          {locationParse[1] === 'myCabinet' ?  <hr /> :""}
        </>
      )}
    </>
  )
}

export default MyCabinetHeader

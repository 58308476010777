import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import BoardSecretariat from "../services/boardSecretariat.service";

export const getBoardSecretariat = createAsyncThunk(
    'data/boardSecretariat',
    async({ type }) => {
        const res = await BoardSecretariat.boardSecretariat(type)
        return res.data.boardSecretariats

    }
)
const initialState = {
    boardSecretariat: [],
    loading: false,
    error: null
}

const boardSecretariatSlice = createSlice({
    name: 'boardSecretariat',
    initialState,
    extraReducers: {
        [getBoardSecretariat.fulfilled]: (state, action) => {
            state.loading = false;
            state.boardSecretariat = action.payload;
        },
        [getBoardSecretariat.rejected]: (state, action) => {
            state.loading = false;
            state.boardSecretariat = action.error.message;
        },
    }
})

export default boardSecretariatSlice.reducer
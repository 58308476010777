import React from 'react'
import { useNavigate } from "react-router-dom";

export default function RegisterButton({setOpenMenu}) {

    const navigate = useNavigate();
    const handleRegisterPage = () => {
        if (localStorage.getItem("token")) {
            navigate('/myCabinet/view');
        }
        else {
            navigate('/register');
        }
        setOpenMenu(false)
    }
    return (
        <div className='ml-[0] lg:ml-[0.5rem] xl:ml-[1.5rem] font-bold'>
            <button onClick={handleRegisterPage} className='font-bold bg-[#2A538A] text-[white] hover:bg-white border border-[#2A538A] hover:text-[#2A538A] ease-linear duration-200 text-[16px] lg:text-[14px] 2xl:text-[16px] uppercase tracking-wide py-[1.125rem] px-[1.5rem] lg:py-[1rem] lg:px-[12px] xl:py-[1.125rem] xl:px-[1.5rem] ml-0 lg:ml-[1.5rem] w-[100%]'>
            {localStorage.getItem("token") ? "My Cabinet" : "Register"}
            </button>
        </div>
    )
}

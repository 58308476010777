import React, { useEffect, useMemo, useState } from 'react'
import { getSettings } from '../../components/slices/auth'
import { useDispatch } from 'react-redux';

export default function Policies() {
    const dispatch = useDispatch()
    const [item, setItem] = useState([]);
    useEffect(() => {
        dispatch(getSettings()).then((res) => {
          setItem(res?.payload?.settings?.data?.settings)
        })
      }, [dispatch])

      const findPolicy = useMemo(
        () => item.find(obj => obj.key == "site.privacy_policy")
      ,[item])


  return (
    <div className="mt-[120px] md:mt-[150px] px-[25px] md:px-[50px]">
        <div className="text-neutral-800 text-2xl font-bold mb-[40px]">Privacy and Policies</div>

      <div className="w-[100%] md:w-[85%] xl:w-[65%] mx-auto">
        <div dangerouslySetInnerHTML={{__html:findPolicy?.value}}></div>
      </div>
    </div>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./components/slices/auth"
import chapterReducer from "./components/slices/chapter"
import addImgReducer from "./components/slices/addImg"
import messageReducer from "./components/slices/message"
import imageSliceEdit from './components/slices/addImgEdit'
import verifyResendReducer from './components/slices/emailVerifyResend'
import opportunitiesReducer from './components/slices/opportunities'
import boardSecretariat from './components/slices/boardSecretariat'
import projects from './components/slices/projects'
const reducer = {
    auth: authReducer,
    image: addImgReducer,
    imageEdit: imageSliceEdit,
    messages: messageReducer,
    chapter: chapterReducer,
    resendVerify: verifyResendReducer,
    opportunities: opportunitiesReducer,
    boardSecretariat: boardSecretariat,
    projects: projects

}

export const store = configureStore({
    reducer: reducer,
    devTools: true,
});
const baseURL = process.env.REACT_APP_DEV_API_URL;

export const APIRoutes = {
    login: baseURL + "api/login",
    forgotPassword: baseURL + "api/forgot/password",
    resetPassword: baseURL + "api/password/reset",
    memberRegister: baseURL + "api/register",
    logout: baseURL + "api/logout",
    editMember: baseURL + "api/member",
    allMember: baseURL + "api/member/all",
    nationalChapters: baseURL + "api/national/chapter",
    memberProfile: baseURL + "api/member/profile/",
    chapterId: baseURL + "api/national/chapter/",
    roles: baseURL + "api/member/roles",
    countries: baseURL + "api/countries",
    allNews: baseURL + "api/news",
    newsById: baseURL + "api/news/",
    contact: baseURL + "api/send/message",
    resendEmailVerify: baseURL + "api/email/resend",
    about: baseURL + "api/about",
    concepts: baseURL + "api/concepts",
    timeline: baseURL + "api/timelines",
    settings: baseURL + "api/settings",
    testimonials: baseURL + "api/testimonials",
    fags: baseURL + "api/faqs",
    opportunitiesItems: baseURL + "api/opportunities",
    boardSecretariat: baseURL + "api/boardSecretariats/boardAll",
    projects: baseURL + "api/projects",
    project: baseURL + "api/project",
    events: baseURL + "api/events",
    newsMain: baseURL + "api/news?main=1",
    blogs: baseURL + "api/blog",
    article: baseURL + "api/article",
    like: baseURL + "api/news/like",
    dislike: baseURL + "api/news/dislike",
    subscriber: baseURL + "api/subscriber"
};
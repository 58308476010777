import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import OpportunitiesCard from '../../components/shared/card/OpportunitiesCard'
import { getOpportunities } from '../../components/slices/opportunities'
import { useDispatch } from 'react-redux'
import {motion} from "framer-motion"

export default function JobDetail() {

  const { id } = useParams()
  const [detailData, setDetailData] = useState({})
  const [cardInformation, setCardInformation] = useState([]);
  const [next, setNext] = useState(3);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DEV_API_URL}api/opportunity/${id}`)
      .then((res) => {
        setDetailData(res.data.data.opportunity)
      })
  }, [id])


  
  useEffect(() => {
    dispatch(getOpportunities({category_id:null})).then(res => {
      setCardInformation(res.payload);
    })
  }, [dispatch]);

  const specialCards = cardInformation.filter(information => information.category_id === 0)
  const doubleSpecialCards = specialCards.filter(info => info.id !== detailData.id)
  const handleMoreImage = () => {
    setNext(next + 3);
  };

  const dateString = detailData.deadline
  const date = new Date(dateString)
  const formattedDate = date
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')

  const dateStringConvert = formattedDate
  const dateParts = dateStringConvert.split('-')
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1]) - 1
  const day = parseInt(dateParts[2])

  const dateNew = new Date(year, month, day)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const formattedDateNew = dateNew.toLocaleDateString('en-US', options)

  return (
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}} className="px-[50px]">
      <div>
      <div className="border-b border-[#F0F0F0] py-[5px]">
        <h1 className="text-[#202021] text-[24px] font-bold leading-[49px]">
          {detailData.title}
        </h1>
        <span className="text-[16px]">{detailData.organization_name}</span>
      </div>
      <div className="block lg:flex justify-between items-center py-[53px]">
        <div className="w-[100%] lg:w-[50%]">
          <div
            className="mr-[32px]"
            dangerouslySetInnerHTML={{
              __html: detailData.description,
            }}
          />

          <div className="py-[26px]">
            <span>
            {detailData.address} {formattedDateNew}
            </span>
          </div>
          <div className="flex justify-center mt-[33px]">
            <button
              onClick={() => navigate(`//https://${detailData.apply_link}`)}
              className="font-bold text-[18px] px-[70px] py-[15px] bg-[#0B2477] text-white hover:bg-white hover:text-[#0B2477] border border-[#0B2477] ease-in duration-300"
            >
              Apply
            </button>
          </div>
        </div>
        <div className="w-[100%] lg:w-[50%] mt-[50px] lg:mt-[0]">
          <img
            className="w-[100%] h-[100%] object-cover"
            src={`https://api.namyouth.org/storage/${detailData.image}`}
            alt=""
          />
        </div>
      </div>
        <div>
          <div className='py-[10px] border-b border-[#F0F0F0]'>
            <h1 className='font-bold text-[24px]'>{detailData.inner_title}</h1>
          </div>
          <div
          className='py-[38px]'
            dangerouslySetInnerHTML={{
              __html: detailData.inner_description,
            }}
          />
        </div>
        <div className="py-[85px]">
          <div>
            <h1 className="font-bold text-[36px]">
              Most popular opportunities
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-[54px]">
            {doubleSpecialCards &&
              doubleSpecialCards
                ?.slice(0, next)
                ?.map((opportunity) => (
                  <OpportunitiesCard
                    func={() => window.scrollTo({ top: 0 })}
                    to={`/opportunities/job/${opportunity.id}`}
                    key={opportunity.id}
                    img={opportunity.image}
                    title={opportunity.title}
                    address={opportunity.address}
                    deadLine={opportunity.deadline}
                  />
                ))}

            {doubleSpecialCards.length === 0 && (
              <div className="text-[25px] font-bold py-[25px]">No data</div>
            )}
          </div>
          {next < doubleSpecialCards?.length &&
          doubleSpecialCards.length > 3 ? (
            <div className="flex justify-center pb-[137px]">
              <button
                className="mt-4 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-100"
                onClick={handleMoreImage}
              >
                Load more
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </motion.div>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaFacebookF } from 'react-icons/fa'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import MoreNews from '../../components/shared/moreNews/MoreNews'
import NewsSlider from './NewsSlider'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { newsById } from '../../components/slices/auth'
import { useState } from 'react'
import ContentLoader from 'react-content-loader'
import {FacebookShareButton,LinkedinShareButton, TwitterShareButton} from "react-share"

export default function NewsDetail() {
  const [newsDetail, setNewsDetail] = useState({})
  const [images, setImages] = useState([]);
  const [plainText, setPlainText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(newsById({ id: id })).then((res) => {
      setImages(JSON.parse(res?.payload?.newsId.news.images))
      setNewsDetail(res?.payload?.newsId.news)
      setIsLoading(false)
    })
  }, [dispatch,id])

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(newsDetail.text, "text/html");
    setPlainText(doc.body.textContent);
  }, [newsDetail]);

  return (
    <>
      <div className="w-[93%] mx-auto">
      {isLoading && <ContentLoader
    width={"100%"}
    height={900}
    viewBox="0 0 450 400"
    backgroundColor="gray"
    foregroundColor="#dedede"
  >
    <rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
    <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
    <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
  </ContentLoader>}
       
        
        <div className="flex">
          <div className='mt-[103px]'>
            <button
             onClick={() => navigate(-1)}
              className="flex items-center text-[#202021] text-[14px] font-bold"
            >
              {' '}
              <MdKeyboardArrowLeft className="text-[#202021] text-[18px] font-bold" />{' '}
              Back
            </button>
            <div className="mt-[74px] ">
              <div className='text-center'>
                <h1 className="text-[#202021] text-[14px] font-bold">Share</h1>
              </div>
              <div className="mt-[23px] flex flex-col">
                <FacebookShareButton url={`${process.env.REACT_APP_DEV_API_URL}news/detail/${id}`}>
                  <FaFacebookF className="text-[20px] mb-[28px]" />
                </FacebookShareButton>
                <LinkedinShareButton url={`${process.env.REACT_APP_DEV_API_URL}news/detail/${id}`}>
                  <FaLinkedinIn className="text-[20px] mb-[28px]" />
                </LinkedinShareButton>
                <TwitterShareButton url={`${process.env.REACT_APP_DEV_API_URL}news/detail/${id}`}>
                  <FaTwitter className="text-[20px] mb-[28px]" />
                </TwitterShareButton>
              </div>
            </div>
          </div>
          <div className="w-[80%] md:w-[60%] mx-[auto]">
          {newsDetail.youtube_link ? <>
          <div className='mt-[103px]'>
          <iframe width={"100%"} height={"600px"} src={`https://www.youtube.com/embed/${newsDetail.youtube_link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        </> : null}
            <div className='mt-[30px] md:mt-[100px]'>
              <h1 className="text-[#202021] text-[20px] md:text-[38px] leading-[30px] md:leading-[46px] font-bold">
                {newsDetail.title}
              </h1>
            </div>
            <div className="mt-[30px] md:mt-[100px]">
              <p className="text-[#202021] text-[18px] leading-[30px]">
                {plainText}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-[94px] w-[100%] md:w-[70%] mx-[auto]">
          <NewsSlider images={images} />
        </div>
      </div>

      <MoreNews otherId={newsDetail.id} />
    </>
  )
}

import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";
const initialState = {};
const messageSlice = createSlice({
        name: "messages",
        initialState: {
          message: "",
          messageLogin:"",
          messageEmail: "",
          messagePassword: "",
          messageLoginPassword:"",
          messageStatus: "",
          messageforgotPassword: "",
          messageAvatar: "",
          messageFirstName: "",
          messageLastName: "",
          messageFirstName1: "",
          messageLastName1: "",
          messageNamYouthEmail: "",
          messageNationality: "",
          messagePhoneNumber: "",
          messageGenderID: "",
          messageCountry: "",
          messagePostalCode: "",
          messageCity: "",
          messageUsername: "",
          messageins: "",
          messagefb: "",
          messagetw: "",
          messageNationalChapter: "",
          messageDateOfBirth:"",
          messageEmail:"",
          messageLoginEmail:"",
          messageText:"",
          messageName:"",
          messageSurname:"",
          messageError:""
      },
        reducers: {
            setMessages: (state, action) => {
                state.message = action.payload.message || "";
                state.messageLogin = action.payload.messageLogin || "";
                state.messageEmail = action.payload.messageEmail || "";
                state.messageLoginEmail = action.payload.messageLoginEmail || "";
                state.messagePassword = action.payload.messagePassword || "";
                state.messageLoginPassword = action.payload.messageLoginPassword || "";
                state.messageStatus = action.payload.messageStatus || "";
                state.messageforgotPassword = action.payload.messageforgotPassword || "";
                state.messageAvatar = action.payload.messageAvatar;
                state.messageFirstName = action.payload.messageFirstName;
                state.messageLastName = action.payload.messageLastName;
                state.messageNamYouthEmail = action.payload.messageNamYouthEmail;
                state.messageNationality = action.payload.messageNationality;
                state.messagePhoneNumber = action.payload.messagePhoneNumber;
                state.messageGenderID = action.payload.messageGenderID;
                state.messageCountry = action.payload.messageCountry;
                state.messagePostalCode = action.payload.messagePostalCode;
                state.messageCity = action.payload.messageCity;
                state.messageUsername = action.payload.messageUsername;
                state.messageins = action.payload.messageins;
                state.messagefb = action.payload.messagefb;
                state.messagetw = action.payload.messagetw;
                state.messageNationalChapter = action.payload.messageNationalChapter;
                state.messageFirstName1 = action.payload.messageFirstName1;
                state.messageLastName1 = action.payload.messageLastName1;
                state.messageDateOfBirth = action.payload.messageDateOfBirth;
                state.messagelinkedin = action.payload.messagelinkedin;
                state.messageEmail = action.payload.messageEmail;
                state.messageText = action.payload.messageText;
                state.messageName = action.payload.messageName;
                state.messageSurname = action.payload.messageSurname;
                state.messageError = action.payload.messageError;
              },
            clearMessages: () => {
                return {
                 messageStatus: "",
                 messageforgotPassword:"",
                 messageAvatar:"",
                 messageFirstName: "",
                 messageLastName: "",
                 messageFirstName1: "",
                 messageLastName1:"",
                 messageNamYouthEmail:"",
                 messageNationality:"",
                 messagePhoneNumber:"",
                 messageGenderID:"",
                 messageCountry:"",
                 messagePostalCode:"",
                 messageCity:"",
                 messageUsername:"",
                 messageins:"",
                 messagefb:"",
                 messagetw:"",
                 messageNationalChapter:"",
                 messageDateOfBirth:"",
                 messagelinkedin:"",
                 messageEmail: "",
                 messageText: "",
                 messageName:"",
                 messageSurname: "",
                 messageError:"",
            }
      }
    },
});

const { reducer, actions } = messageSlice;

export const {setMessages,clearMessages} = actions
export default reducer;
import React from 'react'
import NewsHeader from '../../components/layout/public/components/InnerHeader'
import MainProjectsCard from '../../components/shared/card/MainProjectsCard'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import { APIRoutes } from '../../core/api/routes'
import { useDispatch, useSelector } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import { useMemo } from 'react'
import { getProjects } from '../../components/slices/projects'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

export default function Projects() {
  const { id } = useParams()
  const projects = useSelector((state) => state.projects.projects)
  const [projectDetail, setProjectDetail] = useState({})
  const [imagesSlider, setImagesSlider] = useState([]);
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    axios.get(`${APIRoutes.project}/${id}`).then((res) => {
      setProjectDetail(res.data.data.project)
    })
    setLoading(false)
  }, [id])
  useEffect(() => {
    dispatch(getProjects()).then((res) => {
      return res.payload
    })
  }, [dispatch])
  const otherCards = useMemo(
    () => projects.filter((items) => items.id !== projectDetail.id),
    [projectDetail, projects],
  )

  return (
    <div>
      {!projectDetail.cover ? (
        <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
        <Oval
          height={80}
          width={80}
          color="#2A538A"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#2A538A"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
      ) : (
        <>
          <NewsHeader
            img={`${process.env.REACT_APP_DEV_API_URL}/storage/${projectDetail.cover}`}
            title={'Projects'}
            text={
              'incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis '
            }
          />

          <div className="w-[85%] md:w-[65%] mx-auto py-[124px]">
            <div className="pb-[24px]">
              <p className="text-[38px] leading-[46px] ">
                {projectDetail.title}
              </p>
            </div>
            <div>
              <div className='py-[35px]'>
                {projectDetail.status === 1 ? <h1 className='text-neutral-800 text-[18px] font-normal underline'>Ongoing</h1> : <h1 className='text-neutral-800 text-[18px] font-normal underline'>Completed</h1>}
              </div>
              <div
                className="text-[18px] leading-[22px] text-[#202021]"
                dangerouslySetInnerHTML={{
                  __html: projectDetail.description,
                }}
              ></div>
            </div>
          </div>
          <div className="px-[50px]">
            <div>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}

                navigation={true}
                modules={[Navigation]}

                breakpoints={{
                  
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  
                }}
                className="mySwiper"
              >
                {projectDetail?.images && JSON.parse(projectDetail?.images)?.map((image,index) => (
                  <SwiperSlide key={index}>
                    <img  className="object-cover w-[100%] h-[300px] md:h-[600px]" src={`${process.env.REACT_APP_DEV_API_URL}storage/${image}`} alt="" />
                    </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="py-[43px] border-t border-[#F0F0F0]">
              <h1 className="text-[38px] text-[#202021] leading-[46px] ">
                More Projects
              </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pb-[238px]">
              {projects.length > 0 &&
                otherCards.map((card) => (
                  <>
                    <MainProjectsCard
                      cardId={card.id}
                      image={`${process.env.REACT_APP_DEV_API_URL}storage/${card.cover}`}
                      text={card.title}
                    />
                  </>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

import React, { useState, useRef, useEffect } from 'react';
import { getFags } from '../../components/slices/auth';
import { useDispatch } from 'react-redux';
import './Fag.css';

const AccordionItem = ({ handleToggle, active, fagItem }) => {
  const contentEl = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  
  const onTitleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {fagItem?.map((item) => {
        return (
          <div className={`rc-accordion-card ${
            active === item.id  ? 'bg' : ''
          }`} key={item.id}>
            <div className="rc-accordion-header">
              <div
                className={`rc-accordion-toggle p-3 ${
                  active === item.id ? 'active' : ''
                }`}
                onClick={() => handleToggle(item.id)}
              >
                <i className={`fas ${
                  active === item.id ? 'fa-minus' : 'fa-plus'
                } rc-accordion-icon`}></i>
                <h5 className="rc-accordion-title text-[24px] leading-[28px] font-bold">{item.title}</h5>
              </div>
            </div>
            <div
              ref={contentEl}
              className={`rc-collapse ${
                active === item.id ? 'show' : ''
              }`}
              style={
                active === item.id
                  ? { height: contentEl.current.scrollHeight }
                  : { height: '0px' }
              }
            >
              <div className="rc-accordion-body">
                <p className="mb-0">{item.text}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const Fag = () => {
  const [active, setActive] = useState(null);
  const [fagItem, setFagItem] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFags()).then((res) => {
      setFagItem(res.payload.fags.data.faqs);
    });
  }, [dispatch]);

  const handleToggle = (index) => {
    setActive(active === index ? null : index);
  };

  return (
    <div className="pt-[100px] md:pt-[174px] px-[54px]">
      <div>
        {/* <span className="text-[14px] text-[#202021]">FAG</span> */}
        <h2 className="text-[60px] md:text-[69px] leading-[83px] text-[#202021]">
          Do you have any <br/>questions?
        </h2>
      </div>
      <div className="container-fluid mt-5 mb-5 pt-[50px] md:pt-[110px] pb-[50px] md:pb-[286px]">
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="card">
              <div className="card-body">
                <AccordionItem
                  active={active}
                  handleToggle={handleToggle}
                  fagItem={fagItem}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fag;

import React, { useState } from 'react'
import ShowMoreText from 'react-show-more-text'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAllNews } from '../../slices/auth'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
export default function MoreNews({otherId}) {
    const [moreNews, setMoreNews] = useState([]);
    const navigate = useNavigate()
    const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllNews({id})).then((res) => {
        setMoreNews(res?.payload?.news?.data?.news)
    })
  }, [dispatch,id])
  const pass = (id) => {
    navigate(`/news/detail/${id}`)
  }

  const newses = moreNews.filter(ss => ss.id !== otherId)
const lastElement = (newses[newses.length-1])
const lastSecondElement = (newses[newses.length-2])
const lastThreedElement = (newses[newses.length-3])


  return (
    <div className="bg-[#E9EDF6] py-[100px] px-[20px] md:px-[52px] mt-[254px]">
      <div>
        <div>
          <h1 className="text-[44px] leading-[53px] font-bold">More News</h1>
        </div>
        <div className="block lg:flex mt-[49px]">
          <div onClick={() => pass(lastElement?.id)} className="cursor-pointer w-[100%] lg:w-[100%] mr-[37px] border-b-2 md:border-b-0 md:border-r-2 mb-[2rem] md:mb-0 pb-[1rem] md:pb-[1rem]">
            <div>
              <span className="text-[14px] leading-[17px] text-[#202021]">
                {lastElement?.create_at}
              </span>
            </div>
            <div className="mt-[18px]">
              <p className="text-[#000000] text-[21px] leading-[34px] font-bold">
                <ShowMoreText
                  /* Default options */
                  lines={4}
                  more={false}
                less={false}
                  expanded={false}
                  truncatedEndingComponent={'... '}
                >
                  {lastElement?.desc}
                </ShowMoreText>
              </p>
            </div>
          </div>
          <div onClick={() => pass(lastSecondElement.id)} className="cursor-pointer w-[100%] lg:w-[100%] mr-[37px] border-b-2 md:border-b-0 md:border-r-2 mb-[2rem] md:mb-0 pb-[1rem]">
            <div>
              <span className="text-[14px] leading-[17px] text-[#202021]">
              {lastSecondElement?.create_at}
              </span>
            </div>
            <div className="mt-[18px]">
              <p className="text-[#000000] text-[21px] leading-[34px] font-bold">
                <ShowMoreText
                  /* Default options */
                  lines={4}
                more={false}
                less={false}
              
                expanded={false}
               
                truncatedEndingComponent={"... "}
                >
                  {lastSecondElement?.desc}
                </ShowMoreText>
              </p>
            </div>
          </div>

          {lastThreedElement 
          ? <div onClick={() => pass(lastThreedElement?.id)} className="cursor-pointer w-[100%] lg:w-[100%] mr-[37px] border-b-2 md:border-b-0 md:border-r-2 mb-[2rem] md:mb-0 pb-[1rem]">
            <div>
              <span className="text-[14px] leading-[17px] text-[#202021]">
                {lastThreedElement?.create_at}
              </span>
            </div>
            <div className="mt-[18px]">
              <p className="text-[#000000] text-[21px] leading-[34px] font-bold">
                <ShowMoreText
                  /* Default options */
                  lines={4}
                  more=""
                  less=""
                  expanded={false}
                  truncatedEndingComponent={'... '}
                >
                  {lastThreedElement?.desc}
                </ShowMoreText>
              </p>
            </div>
          </div> : ""}
          
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import InnerHeader from './components/InnerHeader'

const AboutLayout = ({children}) => {
    return (
        <div className='font-america'>
        <InnerHeader/>
            {children}
        </div>
      )
}

export default AboutLayout




import React from 'react'
import UserHeader from "../public/components/UserHeader"

export default function UserLayout({children}) {
  return (
    <div className='font-america'>
        <UserHeader/>
        {children}
    </div>
  )
}

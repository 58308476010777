import React from 'react'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'

import LoginLeftSide from './LoginLeftSide'
import { clearMessages } from '../../slices/message'
import { login } from '../../slices/auth'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [emailValid, setEmailValid] = useState('')
  const [passwordValid, setPasswordValid] = useState('')

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const { messageLogin, messageLoginEmail, messageLoginPassword } = useSelector(
    (state) => state.messages,
  )
  const dispatch = useDispatch()

  let navigate = useNavigate()

  function toggleShowPassword() {
    setShowPassword(!showPassword)
  }
  useEffect(() => {
    dispatch(clearMessages())
  }, [dispatch])

  const handleSubmitEnter = async (e) => {
    if (e.key === 'Enter') {
      setLoadingSubmit(true)

      e.preventDefault()
      const data = {
        email: email,
        password: password,
      }
      dispatch(login(data))
        .unwrap()
        .then((response) => {

          navigate('/myCabinet/view')
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoadingSubmit(false)
        })
    }
  }
  const handleSubmit = async (e) => {
    setLoadingSubmit(true)

    e.preventDefault()
    const data = {
      email: email,
      password: password,
    }
    dispatch(login(data))
      .unwrap()
      .then((response) => {

        navigate('/myCabinet/view')
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  function handleEmailChange(e) {
    setEmail(e.target.value)
    setEmailValid(e.target.value)
    if (validateEmail(e.target.value)) {
      setEmailValid('')
    } else {
      setEmailValid('Please enter the correct email')
    }
  }

  function validatePassword(password) {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
    return passwordRegex.test(password)
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value)
    setPasswordValid(e.target.value)
    if (validatePassword(e.target.value)) {
      setPasswordValid('')
    } else {
      setPasswordValid('Please enter the correct password')
    }
  }
  return (
    <div className="flex h-[100vh]">
      <LoginLeftSide />
      <div className="w-[100%] sm:w-[52%] bg-[#F0F0F0]">
        <div className="w-[80%] md:w-[80%] lg:w-[60%] xl:w-[50%] mx-auto flex flex-col h-[100%] justify-center">
          <div className="flex items-center">
            <Link
              to={'/'}
              className="flex items-center text-[14px]  text-[#2699FB]"
            >
              <MdOutlineKeyboardArrowLeft className="text-[16px]" />
              Back
            </Link>
          </div>
          <div>
            <div className="text-center mt-[18px]">
              <h1 className="text-[24px] font-bold text-[#1C1C1C] ">Log In</h1>
            </div>
            <div className="mt-[33px] mb-[14px]">
              <input
                type="email"
                className={`w-full py-[17.5px] px-[19px] bg-white text-[#A0A0A0] text-[14px] ${
                  emailValid === ''
                    ? ''
                    : 'border-2 border-rose-600  focus:outline-none'
                }`}
                placeholder="Email"
                onChange={handleEmailChange}
                onKeyDown={handleSubmitEnter}
              />
              <span className="text-[red]">
                {messageLoginEmail}
                <span className="block">{emailValid}</span>
              </span>
              <br />
              <div className="relative">
                <input
                  onKeyDown={handleSubmitEnter}
                  type={showPassword ? 'text' : 'password'}
                  className={`w-full py-[17.5px] px-[19px] bg-white text-[#A0A0A0] text-[14px] mt-[10px] ${
                    passwordValid === ''
                      ? ''
                      : 'border-2 border-rose-600 focus:outline-none'
                  }`}
                  placeholder="Password"
                  onChange={handlePasswordChange}
                />
                {showPassword ? (
                  <BsFillEyeFill
                    className="absolute top-[50%] right-[5%] text-[18px] cursor-pointer"
                    onClick={toggleShowPassword}
                  />
                ) : (
                  <BsFillEyeSlashFill
                    className="absolute top-[50%] right-[5%] text-[18px] cursor-pointer"
                    onClick={toggleShowPassword}
                  />
                )}
              </div>
              <span className="text-[red]">
                {messageLoginPassword}
                <span className="block">{passwordValid}</span>
              </span>
            </div>
            <span className="text-[red] mt-[30px]">{messageLogin}</span>
            <div className="flex justify-between mt-[27.5px]">
              <div>
                <Link
                  to={'/forgotPassword'}
                  className="text-[#2699FB] text-[14px] underline"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>
            <div className="mt-[26px]">
              <button
                className={`uppercase transition-all text-center border border-[#2A538A] hover:text-[#2A538A] w-[100%] py-[18px] text-[white] bg-[#2A538A] hover:bg-transparent font-bold text-[14px] ${
                  loadingSubmit ? 'cursor-not-allowed' : ''
                }`}
                onClick={handleSubmit}
              >
                {loadingSubmit ? 'Loading...' : 'Log in'}
              </button>
            </div>
            <div className="mt-[27px]">
              <div className="text-center">
                <p className="text-[12px] text-[#282D39]">
                  Haven't registered yet?
                </p>
              </div>
            </div>
            <div className="mt-[29.5px]">
              <button
                className="w-[100%] text-[#2A538A] border border-[#2A538A] py-[18px] hover:bg-[#2A538A] hover:text-[white] transition-all"
                onClick={() => navigate('/register')}
              >
                CREATE PERSONAL ACCOUNT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import RoleHistory from '../../components/shared/role-history/RoleHistory'

const MyCabinetRoleHistory = () => {
  return (
    <div>
      <RoleHistory/>
    </div>
  )
}

export default MyCabinetRoleHistory

import Layout from "./components/layout/public/Layout";
import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
ReactGA.initialize("G-4XDDH5BR2N");
ReactGA.send({ hitType: "pageview", page: window.location.pathname });


function App() {
  const location = useLocation()
  const locationParse = location.pathname.split("/")
  return (
    <div className="App">
      <Layout>
        {locationParse[1]==="chapters" || locationParse[1] === "opportunities" ? "" :<ScrollRestoration/>}
        {/* <ScrollRestoration/> */}
        <Outlet />
      </Layout>
    </div>
  );
}

export default App;

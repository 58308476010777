import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OpportunitiesCard from '../../components/shared/card/OpportunitiesCard'
import { useDispatch, useSelector } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import { getOpportunities } from '../../components/slices/opportunities'
import { getCountries } from '../../components/slices/auth'
import {motion} from "framer-motion"
export default function Internship() {

  const [opportunitiesInternship, setOpportunitiesInternship] = useState([])
  const [loading, setLoading] = useState(true)
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState('');
  const [title, setTitle] = useState("");

  const dispatch = useDispatch()

  const { opportunities } = useSelector((state) => state.opportunities)
  useEffect(() => {
    setOpportunitiesInternship(
      opportunities.filter((opportunity) => opportunity.category_id === 1),
    )
    setLoading(false)
  }, [opportunities])


  const onChangeCountry = (e) => {
    setRegion(e.target.value)
  }
    useEffect(() => {
      dispatch(getOpportunities({title:title,category_id:1,country_id:region})).then(res => {
        return res
      })
    }, [dispatch,title,region]);


    useEffect(() => {
      dispatch(getCountries()).then(res => {
        setCountry(res?.payload?.countries?.data?.countries)
      })
    }, [dispatch]);

    const container = {
      visible:{
        transition:{
          delayChildren:0.5,
          staggerChildren:1
        }
      }
    }
    const items = {
      hidden:{
        opacity:0,
        translateY:60
      },
      visible:{
        opacity:1,
        translateY:0
      }
    }
   
  
  return (
    <div className="px-[25px] md:px-[50px]">
      <div className="block mt-[30px] md:flex items-center" id="topDates">
        <div className="relative w-[100%] xl:w-[30%]">
          <input
          onChange={(e) => setTitle(e.target.value)}
            type="text"
            className="block w-full px-3 py-4 bg-[#F0F0F0] border border-[#F0F0F0] text-sm shadow-sm placeholder-[black] focus:outline-none focus:border-[#F0F0F0] focus:ring-1 focus:ring-[#F0F0F0]"
            placeholder="Search by keyword"
          />
          <button
            type="submit"
            className="absolute top-0 right-0 px-2.5 py-4 text-sm font-medium text-black "
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
        <div className="flex border w-[100%]">
        <Box sx={{ minWidth: 120}} className="w-[100%] md:w-[120px]">
            <FormControl variant="filled" sx={{ m: 1, minWidth: 130,margin:0 }}>
              <InputLabel
                id="demo-simple-select-filled-label"
                style={{ color: 'black' }}
              >
                Region
              </InputLabel>
              <Select
                disableUnderline
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={onChangeCountry}
                value={region}
              >
                <MenuItem>
                  <em>None</em>
                </MenuItem>
                {country && country.map(c => (
                  <MenuItem key={c.name} value={c.id}>{c.name}</MenuItem>
                ))}

                {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
          
        </div>
      </div>

      <motion.div
      initial="hidden"
      animate="visible"
      variants={container} 
      className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-[54px]">
      {loading ? (
           <Oval
             height={80}
             width={80}
             color="#2A538A"
             wrapperStyle={{}}
             wrapperClass=""
             visible={true}
             ariaLabel="oval-loading"
             secondaryColor="#2A538A"
             strokeWidth={2}
             strokeWidthSecondary={2}
           />
        ) : (
          <>
            {opportunities &&  opportunitiesInternship.map((opportunity) => (
              <motion.div
              variants={items}
              key={`${opportunity.id}`}

              >
                <OpportunitiesCard
                to={`${opportunity.id}`}
                unique={`${opportunity.id}`}
                img={opportunity.image}
                title={opportunity.title}
                address={opportunity.address}
                deadLine={opportunity.deadline}
                loading={loading}
              />
              </motion.div>
            )) }

            {opportunitiesInternship.length === 0 && <div className='text-[25px] font-bold py-[25px]'>No data</div>}
          </>
        )}



        
      </motion.div>
    </div>
  )
}

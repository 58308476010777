import React from 'react'

export default function presidentCard({img,name,about}) {
  return (
    <div className="flex justify-between">
    <div className="w-[50%]">
      <img
        src={img}
        alt=""
        className="object-cover w-[100%]"
      />
    </div>
    <div className="w-[50%] ml-[30px]">
      <div>
        <h1 className="text-[#202021] text-[36px] font-bold">
          {name}
        </h1>
      </div>
      <div className="mt-[40px]">
        <p className="text-[#202021] leading-[25px] text-[16px]">
          {about}
        </p>
      </div>
    </div>
  </div>
  )
}

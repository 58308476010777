import React, { useMemo, useState } from 'react'
import { AiFillDislike, AiFillLike } from 'react-icons/ai'
import { BsEyeFill } from 'react-icons/bs'
import LazyImage from '../../lazyLoad/LazyImage'
import Skeleton from 'react-loading-skeleton'
import ShowMoreText from 'react-show-more-text'
import { useNavigate } from 'react-router-dom'
import { Like } from '../../services/likes.service'
import { DisLike } from '../../services/dislikes.service'
export default function NewsCard({ item }) {
  const [likesCount, setLikesCount] = useState(item.likes_count)
  const [disLikesCount, setDisLikesCount] = useState(item.dislikes_count)

  const [colorLike, setColorLike] = useState('')
  const [colorDisLike, setColorDisLike] = useState('')

  const [loading, setLoading] = useState(false);


  const navigate = useNavigate()
  const doLike = async (id) => {
    const storedId = localStorage.getItem('id');
  
    if (storedId) {
      try {
        setLoading(true);
        const res = await Like({ news_id: id });
  
        if (res.status === 201) {
          if (id === item.id) {
            setLikesCount(likesCount - 1);
            setColorLike('black');
          }
        } else if (res.status === 200) {
          if (id === item.id) {
            setLikesCount(likesCount + 1);
            if (disLikesCount !== 0) {
              setDisLikesCount(disLikesCount - 1);
            }
            setColorLike('red');
            setColorDisLike('black');
          }
        }
      } catch (error) {
        // Handle error here if needed
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/login');
    }
  };

  const doDislike = async (id) => {
    const storedId = localStorage.getItem('id');
  
    if (storedId) {
      try {
        setLoading(true);
        const res = await DisLike({ news_id: id });
  
        if (res.status === 201) {
          setDisLikesCount(disLikesCount - 1);
          setColorDisLike('black');
        } else if (res.status === 200) {
          setDisLikesCount(disLikesCount + 1);
          if (likesCount !== 0) {
            setLikesCount(likesCount - 1);
            setColorLike('black');
          }
          setColorDisLike('red');
        }
      } catch (error) {
        // Handle error here if needed
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/login');
    }
  };

  const filterLikes = useMemo(
    () =>
      item.likes.filter((data) => data.member_id == localStorage.getItem('id')),
    [item.likes],
  )
  const filterDisLikes = useMemo(
    () =>
      item.dislikes.filter((data) => data.member_id == localStorage.getItem('id')),
    [item.dislikes],
  )

  

  return (
    <div className='hover:shadow-2xl ease-in duration-300'>
      <div
        onClick={() => navigate(`detail/${item?.id}`)}
        className="w-[100%] h-[230px] bg-[gray]"
      >
        {(
          <LazyImage
            src={`${process.env.REACT_APP_DEV_API_URL}storage/${item?.cover_image}`}
            width={'100%'}
            height={'100%'}
            smHeight={'100%'}
            cover={'cover'}
          />
        ) || <Skeleton />}
      </div>
      <div onClick={() => navigate(`detail/${item?.id}`)} className="mt-[24px] mx-[10px]">
        <div className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          <ShowMoreText
            /* Default options */
            lines={2}
            more=""
            less=""
            className="content-css"
            anchorClass="show-more-less-clickable"
            expanded={false}
            truncatedEndingComponent={'... '}
          >
            {item?.title}
          </ShowMoreText>
        </div>
        <div className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          <ShowMoreText
            /* Default options */
            lines={2}
            more=""
            less=""
            className="content-css"
            anchorClass="show-more-less-clickable"
            expanded={false}
          >
            {item?.desc}
          </ShowMoreText>
        </div>
      </div>
      <div className="flex items-center justify-between border mt-[43px] pr-[16px] py-[8px]">
        <div className="flex">
          <button
          disabled={loading}
            onClick={() => {
              doLike(item.id, item.likes_count)
            }}
            className={`flex items-center mr-[32px] lg:mr-[10px] 2xl:mr-[32px] pl-[16px]`}
          >
            {/* <AiFillLike className={`text-[18px]`} /> */}
            {filterLikes.length> 0 ? filterLikes.map((like) => (
                like.member_id == localStorage.getItem('id') &&
                <AiFillLike
                key={like.id}
                className={`text-[18px] ${
                  like.active === 1 && colorLike == ''
                    ? 'text-[#2A538A]'
                    : colorLike == 'black'
                    ? 'text-[black]'
                    : colorLike == 'red' && 'text-[#2A538A]'
                }`}
              />
            ))  :
            
            <AiFillLike
            className={`text-[18px] ${
                  colorLike == ''
                  ? 'text-[black]'
                  : colorLike == 'black'
                  ? 'text-[black]'
                  : colorLike == 'red' && 'text-[#2A538A]'
              }`}
              />

            }

            <span className="ml-[4px]">{likesCount}</span>
          </button>
          <button
                    disabled={loading}

          onClick={() => doDislike(item.id)}
           className="flex items-center mr-[32px] lg:mr-[10px] 2xl:mr-[32px]">
            {/* <AiFillDislike className="text-[18px]" /> */}
            {filterDisLikes.length> 0 ? filterDisLikes.map((dislike) => (
                dislike.member_id == localStorage.getItem('id') &&
                <AiFillDislike
                key={dislike.id}
                className={`text-[18px] ${
                    dislike.active === 1 && colorDisLike == ''
                    ? 'text-[#2A538A]'
                    : colorDisLike == 'black'
                    ? 'text-[black]'
                    : colorDisLike == 'red' && 'text-[#2A538A]'
                }`}
              />
            ))  :
            
            <AiFillDislike
            className={`text-[18px] ${
                colorDisLike == ''
                  ? 'text-[black]'
                  : colorDisLike == 'black'
                  ? 'text-[black]'
                  : colorDisLike == 'red' && 'text-[#2A538A]'
              }`}
              />

            }
            <span className="ml-[4px]">{disLikesCount}</span>
          </button>
          <div className="flex items-center mr-[32px] lg:mr-[10px] 2xl:mr-[32px]">
            <BsEyeFill className="text-[18px]" />
            <span className="ml-[4px]">{item.read_count}</span>
          </div>
        </div>
        <div className="flex flex-wrap">
          <h1 className="text-[14px] lg:[10px] 2xl:[14px]">
            {item?.created_at}
          </h1>
        </div>
      </div>
    </div>
  )
}

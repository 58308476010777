import axios from "axios";
import { APIRoutes } from '../../core/api/routes'
import { useEffect } from "react";



const register = (first_name, email, nam_youth_email, password, username, password_confirmation, last_name, nationality, phone_number, gender_id, country, postal_code, city, national_chapter, date_of_birth, avatar, fb, tw, ins, linkedin) => {

    return axios
        .post(APIRoutes.memberRegister, {
            first_name,
            email,
            nam_youth_email,
            password,
            username,
            password_confirmation,
            last_name,
            nationality,
            phone_number,
            gender_id,
            country,
            postal_code,
            city,
            national_chapter,
            date_of_birth,
            avatar,
            fb,
            tw,
            ins,
            linkedin

        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response) {
                // localStorage.setItem("user", JSON.stringify(response.data.data.user));
                // localStorage.setItem("token", JSON.stringify(response.data.data.access_token));

                return response.data.data.user
            }
        })
}

const login = (email, password) => {
    return axios
        .post(APIRoutes.login, {
            email,
            password,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            // console.log(response.data.data.user.email_verified_at)

            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            localStorage.setItem("token", JSON.stringify(response.data.data.access_token));
            localStorage.setItem("id", JSON.parse(response.data.data.user.id))
            return response.data.data.user;
        });
};
const forgotPassword = (email) => {
    return axios
        .post(APIRoutes.forgotPassword, {
            email,
        })
        .then((response) => {
            return response;
        });
};
const resetPassword = ({ newPassword, email, token }) => {
    return axios
        .post(APIRoutes.resetPassword, {
            newPassword,
            email,
            token
        })
        .then((response) => {
            return response;
        });
};

const allMembers = async function getAllMembers(firstName) {
    try {
        const response = await axios.get(APIRoutes.allMember, {
            params: {
                firstName,
            },
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


const allChapters = async function getAllChapters() {
    try {
        const response = await axios.get(APIRoutes.nationalChapters)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

const timeLine = async function getTimeLine() {
    try {
        const response = await axios.get(APIRoutes.timeline)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

const allRoles = async function getAllRoles() {
    try {
        const response = await axios.get(APIRoutes.roles, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            },
        })
        return response
    } catch (error) {
        console.log(error.response)
    }
}
const allNews = async function getAllNews(year, month, day, title) {
    try {
        const params = {};
        if (year) {
            params.year = year;
        }
        if (month) {
            params.month = month;
        }
        if (day) {
            params.day = day;
        }
        if (title) {
            params.title = title
        }
        const response = await axios.get(APIRoutes.allNews, { params });
        return response
    } catch (error) {
        console.log(error)
    }
}

const getNewsById = async function getNewsById(id) {
    return axios.get(APIRoutes.newsById + id).then(response => {
        return response.data
    })
}

const memberProfile = async function getMembersProfile(id, token) {
    try {
        const response = await axios.get(APIRoutes.memberProfile + id, { headers: { "Authorization": `Bearer ${token}` } })
        return response.data
    } catch (error) {
        console.log(error)
    }
    // axios.get(APIRoutes.memberProfile + id, { headers: { "Authorization": `Bearer ${token}` } }).then(response => {
    //     console.log(response.data.data)
    //     return response.data.data

    // })
}

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("id")
};



const countries = async function getCountries() {
    try {
        const response = await axios.get(APIRoutes.countries)
        return response.data

    } catch (error) {
        console.log(error)
    }
}


const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const contact = (name, surname, email, message) => {
    return axios
        .post(APIRoutes.contact, {
            name,
            surname,
            email,
            message,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return response.data.data.user;
        });
};
const about = async function getAbout() {
    try {
        const response = await axios.get(APIRoutes.about)
        return response.data

    } catch (error) {
        console.log(error)
    }
}
const concepts = async function getConcepts() {
    try {
        const response = await axios.get(APIRoutes.concepts)
        return response.data

    } catch (error) {
        console.log(error)
    }
}
const settings = async function getSettings() {
    try {
        const response = await axios.get(APIRoutes.settings)
        return response.data

    } catch (error) {
        console.log(error)
    }
}
const testimonials = async function getTestimonials() {
    try {
        const response = await axios.get(APIRoutes.testimonials)
        return response.data

    } catch (error) {
        console.log(error)
    }
}
const fags = async function getFags() {
    try {
        const response = await axios.get(APIRoutes.fags)
        return response.data

    } catch (error) {
        console.log(error)
    }
}
const AuthService = {
    login,
    logout,
    register,
    getCurrentUser,
    forgotPassword,
    allMembers,
    allChapters,
    memberProfile,
    // chapterForId,
    allRoles,
    countries,
    allNews,
    getNewsById,
    contact,
    about,
    concepts,
    timeLine,
    settings,
    testimonials,
    fags
}

export default AuthService;
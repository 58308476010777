import React, { useEffect, useState } from 'react'
import InnerHeader from '../../components/layout/public/components/InnerHeader'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import blogsGet from '../../components/services/blogs.service'
import { useParams } from 'react-router-dom'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import getArticles from '../../components/services/article.service'
export default function  MyArticle() {
  const { id } = useParams()
  const [detailInformation, setDetailInformation] = useState({})
//   const [moreBlogs, setMoreBlogs] = useState([])
  const location = useLocation()
  const locationParse = location.pathname.split('/')
  const navigate = useNavigate()
 
  useEffect(() => {
    getArticles(id).then((res) => {
      setDetailInformation(res.data.article)
    })
  }, [id])

//   useEffect(() => {
//     blogsGet().then((res) => {
//       const data = res.data.blogs.filter(
//         (blog) => blog.id !== detailInformation.id,
//       )
//       setMoreBlogs(data)
//     })
//   }, [detailInformation])

  return (
    <>
      <InnerHeader
        img={`${process.env.REACT_APP_DEV_API_URL}storage/${detailInformation?.cover_image}`}
        title={'Article'}
        text={
          'incididunt ut ero ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis '
        }
      />
      <div className="px-[50px]">
        <div className="flex">
          <div className="mt-[103px]">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center text-[#202021] text-[14px] font-bold"
            >
              {' '}
              <MdKeyboardArrowLeft className="text-[#202021] text-[18px] font-bold" />{' '}
              Back
            </button>
            <div className="mt-[74px] ">
              <div className="text-center">
                <h1 className="text-[#202021] text-[14px] font-bold">Share</h1>
              </div>
              <div className="mt-[23px] flex flex-col">
                <FacebookShareButton
                  url={`${process.env.REACT_APP_DEV_API_URL}${locationParse[1]}/${id}`}
                >
                  <FaFacebookF className="text-[20px] mb-[28px]" />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_DEV_API_URL}${locationParse[1]}/${id}`}
                >
                  <FaLinkedinIn className="text-[20px] mb-[28px]" />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_DEV_API_URL}${locationParse[1]}/${id}`}
                >
                  <FaTwitter className="text-[20px] mb-[28px]" />
                </TwitterShareButton>
              </div>
            </div>
          </div>

          <div className="w-[75%] mx-auto">
            <div className="py-[5px]">
              <h1 className="text-[#202021] text-[38px] font-normal">
                {detailInformation?.title}
              </h1>
              <span className="text-[16px]"></span>
            </div>
            <div className="block lg:flex justify-between items-center py-[20px]">
              <div className="w-[100%]">
                <div className="flex items-center">
                  <div className="h-[32px] w-[32px] border-full rounded-full bg-[#202021] mr-[15px]">
                    <img
                      className="rounded-full"
                      src={`${process.env.REACT_APP_DEV_API_URL}storage/${detailInformation?.member?.avatar}`}
                      alt=""
                    />
                  </div>
                  <div>
                    <h1 className="text-neutral-800 text-[12px] font-normal mr-[15px]">
                      {detailInformation?.member?.first_name}{' '}
                      {detailInformation?.member?.last_name}
                    </h1>
                  </div>
                </div>
                <div className="mr-[32px] mt-[20px]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detailInformation?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

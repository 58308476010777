import React from "react";

const TextInput = ({ value, handle, iplaceholder, ivalue, ititle,bgClass, iname,type,className }) => {
  return (
    <div className={`pb-[4px] ${className}`}>
      <h4 className="mb-[15px] font-bold text-[15px] mt-[17px]">{ititle}</h4>
      <input onChange={(e) => handle(e.target.value)} className={`w-[100%] h-[50px] pl-[20px] ${bgClass} bg-[#F3F4F6] placeholder-[#202021]`} type={type} placeholder={iplaceholder} name={iname} value={value} />
    </div>
  );
};

export default TextInput;

import React from 'react'
import { useRef } from 'react'
import { useEffect,useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


export default function LazyImage({src,height,width,cover,smHeight,smWidth,animation,round}) {
    const [inView, setInView] = useState(false);
  const ref = useRef()


  let callback= (entries,observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            setInView(true)
        }
    });
  }
  useEffect(() => {
    let observer = new IntersectionObserver(callback)

    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])
  return inView ?(
    <img src={src} style={{objectFit:cover}} alt="" className={`h-[${smHeight}] w-[${smWidth}] md:h-[${height}] md:w-[${width}] object-cover ${round ? 'rounded-full' : ''} ${animation ? 'hover:scale-125 transform transition object-top' : ''}`} />
  ) :
  <img ref={ref} className={`h-[${smHeight}] w-[${smWidth}] md:h-[${height}] md:w-[${width}]`} style={{objectFit:cover,background:"gray"}}  src={require('../../assets/images/bgLoad.png')} alt="" />
{/* <Skeleton ref={ref}/> */}
}

import React from 'react'
import InnerHeader from './components/InnerHeader'

export default function NewsLayout({children}) {
  return (
    <div className='font-america'>
    <InnerHeader/>
        {children}
    </div>
  )
}

import React, { useState } from "react";
import { Link } from "react-router-dom";

import LoginLeftSide from "../login/LoginLeftSide";
import { forgotPassword } from "../../slices/auth";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineCheck } from "react-icons/ai";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [text, setText] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  
  const dispatch = useDispatch();

  const { messageforgotPassword,messageEmail } = useSelector((state) => state.messages);
  const handleSubmit = () => {
    setLoadingSubmit(true)
    
    dispatch(forgotPassword(email))
      .unwrap()
      .then((response) => {
        if (response) {
          setText(true)
          setEmail("")
          // Swal.fire({
          //   title: 'Please check your email',
          //   confirmButtonText: 'OK',
          //   confirmButtonColor: '#000',
          // }).then(() => {
          //      navigate('/')
          // })
        }
      })
      .catch(() => {
        console.log(dispatch(forgotPassword(email)));
      })
      .finally(() => {

        setLoadingSubmit(false)
      })
  };
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function handleEmailChange(e) {
    setEmail(e.target.value);
    setEmailValid(e.target.value);
    if (validateEmail(e.target.value)) {
      setEmailValid("");
    } else {
      setEmailValid("Please enter the correct email");
    }
  }
  return (
    <div className="flex h-[100vh]">
      <LoginLeftSide />
      <div className="w-[100%] sm:w-[52%] bg-[#F0F0F0]">
        <div className="w-[80%] md:w-[80%] lg:w-[60%] xl:w-[50%] h-[100vh] mx-auto bg-[#F0F0F0]">
          <div className="mx-auto flex flex-col h-[100%] justify-center">
            <div className="text-center mt-[18px]">
              <h1 className="text-[24px] font-bold text-[#1C1C1C] ">
                Forget Password?
              </h1>
            </div>
            <div className={text ? 'block' :"hidden"}>
              <h6 className="text-[14px] leading-[17px] text-[#282D39] text-center py-[16px]">Enter your email address</h6>
               <AiOutlineCheck className="text-[#2699FB]"/><p className="inline text-[16px] leading-[17px] text-[#282D39]">We've sent an email for resetting your password to your email address. Please check your email for next steps.</p>
            </div>
            <div className="mt-[33px]">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className={`w-full py-[17.5px] px-[19px] bg-white text-[#A0A0A0] text-[14px] ${
                  emailValid === "" 
                    ? ""
                    : "border-2 border-rose-600  focus:outline-none"
                } `}
                placeholder="Email or Username"
              />
              <span className="text-[red]">{messageEmail}<span className="block">{emailValid}</span></span>
              <span className="text-[red]">{messageforgotPassword}</span>
            </div>
            <div className="mt-[26px]">
              <button
                onClick={handleSubmit}
                className="text-center w-[100%] py-[18px] text-[white] bg-[#2A538A] font-bold text-[14px]"
              >
                {loadingSubmit ? "Loading..." : "Reset Password"}
              </button>
            </div>
            <div className="mt-[25px] text-center ">
              <Link
                to={"/login"}
                className="text-[#2699FB] text-[14px] underline"
              >
                Return to Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

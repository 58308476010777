import axios from 'axios'

import { APIRoutes } from '../../core/api/routes'

const getArticles = async(id) => {
    try {
        if (id) {
            const response = await axios.get(`${APIRoutes.article}/${id}`)
            return response.data
        } else {
            const response = await axios.get(APIRoutes.article)
            return response.data
        }
    } catch (error) {
        console.log(error)
    }
}

export default getArticles
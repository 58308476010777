import React, { useState, useEffect } from 'react'
import TextInput from '../../components/shared/input/TextInput'
import { TextareaAutosize } from '@mui/material'
import BecomeButton from '../../components/shared/button/BecomeButton'
import { Link } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai"
import { AiFillYoutube } from "react-icons/ai"
import { FaLinkedinIn } from "react-icons/fa"
import { FaTelegramPlane } from "react-icons/fa"
import { RiTwitterXFill } from "react-icons/ri";
import {
  FaFacebookF as Facebook,
  FaInstagram as Instagram,
  FaYoutube as Youtube,
  FaLinkedinIn as Linkedin,
  FaTelegramPlane as Telegram,
} from 'react-icons/fa'
import { RiTwitterXFill as XTwitter } from "react-icons/ri";
import { getContact } from "../../components/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearMessages } from "../../components/slices/message"
import Swal from 'sweetalert2'
import { getSettings } from '../../components/slices/auth'


const Contact = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const [contact, setContact] = useState("")

  const navigate = useNavigate();
  const { messageEmail, messageText, messageName, messageSurname, messageError } = useSelector(
    (state) => state.messages
  );
  useEffect(() => {
    if (name && surname && email && message) {
      dispatch(clearMessages());
    }
  }, [dispatch, name, surname, email, message]);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      surname: surname,
      email: email,
      message: message,
    };
    dispatch(getContact(data))
      .unwrap()
      .then((response) => {
        Swal.fire(
          'Message sent!',
          'success'
        ).then(() => {
          setName("")
          setSurname("")
          setEmail("")
          setMessage("")
          messageEmail("")
        })
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const [item, setItem] = useState([])
  const url = 'https://api.namyouth.org/storage/'
  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      setItem(res?.payload?.settings?.data?.settings ?? [])
    })
  }, [dispatch])
  const facebookLink = item?.find((x) => x.key === 'site.facebook_url')?.value
  const instagramLink = item?.find((x) => x.key === 'site.instagram_url')?.value
  const youtubeLink = item?.find((x) => x.key === 'site.youtube_url')?.value
  const linkedinLink = item?.find((x) => x.key === 'site.linkedin_url')?.value
  const telegramLink = item?.find((x) => x.key === 'site.telegram_url')?.value
  const xTwitterLink = item?.find((x) => x.key === 'site.x_social_link')?.value
  return (
    <div className='pt-[174px] px-[54px] max-[912px]:px-[26px]'>
      <div>
        <h1 className='text-[#202021] leading-[83px] text-[69px] max-[768px]:leading-[42px] max-[768px]:text-[23px]'>Get in touch with us</h1>
      </div>
      <div className='flex justify-between pt-[118px] pb-[70px] max-[768px]:flex-col '>
        <div>
          <TextInput
            iplaceholder="Name"
            value={name}
            bgClass={'bg-[#FFFFFF] border'}
            className="w-[652px] max-[768px]:w-[100%]"
            handle={setName}
          />
          <span className="text-[red] mt-[30px]">{messageName}</span>
          <TextInput
            iplaceholder="Surname"
            value={surname}
            bgClass={'bg-[#FFFFFF] border'}
            handle={setSurname}
          />
          <span className="text-[red] mt-[30px]">{messageSurname}</span>
          <TextInput
            iplaceholder="Email"
            value={email}
            bgClass={'bg-[#FFFFFF] border'}
            handle={setEmail}
          />
          <span className="text-[red] mt-[30px]">{messageEmail}</span>
        </div>
        <div className='flex flex-col'>
          <textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} className='w-[652px] h-[209px] border-[#E9E9E9] placeholder-[#202021] border p-[16px] max-[768px]:w-[100%] max-[768px]:mt-[15px] ' />
          <span className="text-[red] mt-[10px]">{messageText}</span>
        </div>
      </div>
      <div className='text-center'>
        <BecomeButton
          func={handleSubmit1}
          bgcolor="#202021"
          txtsize="13px"
          textColor="white"
          text="send"
          bgColor="#202021"
          paddingX="71px"
          paddingY="22px" />
      </div>
      <div className='flex justify-between pb-[194px] pt-[98px] max-[768px]:flex-col max-[768px]:gap-y-[32px]'>
        <div className='flex flex-col'>
          <span className='text-[#202021] leading-[12px] text-[10px] pb-[9px]'>Mobile</span>
          <span className='text-[#202021] leading-[17px] text-[14px]'> {item?.find((x) => x.key === 'site.contact_phone')?.value}</span>
        </div>
        <div className='flex flex-col'>
          <span className='text-[#202021] leading-[12px] text-[10px] pb-[9px]'>Email</span>
          <span className='text-[#202021] leading-[17px] text-[14px]'>{item?.find((x) => x.key === 'site.contact_email')?.value}</span>
        </div>
        <div className='flex flex-col'>
          <span className='text-[#202021] leading-[12px] text-[10px] pb-[9px]'>Address</span>
          <span className='text-[#202021] leading-[17px] text-[14px]'>60-62 Bulbul Avenue, Baku, Azerbaijan</span>
        </div>
        <div className='flex flex-col'>
          <span className='text-[#202021] leading-[12px] text-[10px] pb-[9px]'>Socials</span>
          <div className='flex text-[20px]'>
            {facebookLink && (
              <Link to={facebookLink} target="_blank" className='mr-[17px]'>
                <Facebook />
              </Link>
            )}
            {instagramLink && (
              <Link to={instagramLink} target="_blank" className='mr-[17px]'>
                <Instagram />
              </Link>
            )}
            {youtubeLink && (
              <Link to={youtubeLink} target="_blank" className='mr-[17px]'>
                <Youtube />
              </Link>
            )}
            {linkedinLink && (
              <Link to={linkedinLink} target="_blank" className='mr-[17px]'>
                <Linkedin />
              </Link>
            )}
            {xTwitterLink && (
              <Link to={xTwitterLink} target="_blank" className='mr-[17px]'>
                <XTwitter />
              </Link>
            )}
            {telegramLink && (
              <Link to={telegramLink} target="_blank" className='mr-[17px]'>
                <Telegram />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className='pb-[196px]'>
        {/* <iframe  width="100%" height="447" frameBorder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=1340&amp;height=447&amp;hl=en&amp;q=%20Baku+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href='https://maps-generator.com/'></a> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12157.124135128699!2d49.8419909!3d40.3804636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d7c8a9b71af%3A0x46da99a7df3040d4!2sNon-Aligned%20Movement%20Youth%20Organization%20(NAMYO)%20Headquarters!5e0!3m2!1sen!2saz!4v1695122961058!5m2!1sen!2saz" width="100%" height="447" frameborder="0" scrolling="no" marginheight="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Contact

import React, { useEffect, useMemo, useState } from 'react'
import Fullcalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import InnerHeader from '../../components/layout/public/components/InnerHeader'
import { Dialog, DialogContent } from '@mui/material'

import { getEvents } from '../../components/services/events.service'
import './calendar.css'
function Calendar() {
  const [open, setOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState('')
  const [event, setEvent] = useState([])
  // const fullScreen = useMediaQuery(theme.breakpoints('md'));
  const [datePosition, setDatePosition] = useState({ left: 0, top: 0 })
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleClick = (clickInfo) => {
    setDialogContent(clickInfo.event.id)
    const eventPosition = clickInfo.el.getBoundingClientRect()
    setDatePosition({ left: eventPosition.left, top: eventPosition.top })
    console.log('Event position:', eventPosition)
    setDialogContent(clickInfo.event.id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getEvents().then((res) => {
      setEvent(res.data.Events)
    })
  }, [])
  const filterEvent = useMemo(
    () => event.find((item) => item.id == dialogContent),
    [dialogContent,event],
  )

  const events = event.map((item) => ({
    id: item.id,
    title: item.title,
    start: item.start_date,
    allDay: true,
    border:'3px',
    borderColor:`transparent transparent transparent 
    ${(item.category_id === 2 && '#00CD77') ||
     (item.category_id === 3 && '#E7CA00') ||
     (item.category_id === 4 && '#F16500') ||
     (item.category_id === 1 && '#0087D2')}`,
   
    backgroundColor:
      (item.category_id === 2 && '#F7EFB3') ||
      (item.category_id === 3 && '#CCF4E3') ||
      (item.category_id === 4 && '#F8D8C0') ||
      (item.category_id === 1 && '#CDE8F7'),
    textColor: '#707070',
    fontWeight: 'bold',
  }))

  const filterEventsUpComing = useMemo(
    () => event.filter((item) => new Date(item?.start_date) - new Date() > 0),
    [event],
  )

  const sortData = useMemo(
    () =>
      filterEventsUpComing.sort(
        (a, b) => (b?.start_date) - (a?.start_date),
      ),
    [filterEventsUpComing],
  )

  const sortDataFromLast = useMemo(
    () =>
      filterEventsUpComing.sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date),
      ),
    [filterEventsUpComing],
  )

  const selectedDate = new Date(sortData[0]?.start_date)
  const month = selectedDate.toLocaleDateString('default', { month: 'long' })
  const year = selectedDate.getFullYear()
  const date = selectedDate.getDate()

  const sortedDataEvents = [
    sortDataFromLast[0],
    sortDataFromLast[1],
    sortDataFromLast[2],
    sortDataFromLast[3],
  ]

  return (
    <div>
      <InnerHeader
        img={require('../../assets/images/secretariat-pic.png')}
        title={'Calendar'}
        text={
          'Stay tuned for the upcoming initiatives of the NAM Youth Organization through our calendar!'
        }
      />
      <div className="flex justify-center py-[50px]">
        {sortData[0]?.title && <div>
          <div className="flex justify-center mb-[20px]">
            <h1 className="text-neutral-800 text-[32px] font-bold">Upcoming</h1>
          </div>
          <div className="w-[310px] h-[258px] bg-white border border-stone-300">
            <div
              className={`w-[310px] h-[68px] text-center ${
                (sortData[0]?.category_id === 1 && 'bg-[#0087D2]') ||
                (sortData[0]?.category_id === 2 && 'bg-[#00CD77]') ||
                (sortData[0]?.category_id === 3 && 'bg-[#E7CA00]') ||
                (sortData[0]?.category_id === 4 && 'bg-[#F16500]')
              }`}
            >
              <h1 className='text-white text-[20px] font-bold'>{sortData[0]?.title}</h1>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-neutral-800 text-[64px] font-bold">{date}</h1>
              <span className="text-neutral-500 text-[32px] font-normal">
                {month}, {year}
              </span>
            </div>
          </div>
        </div>}
        
      </div>
      <div className='px-[20px] md:px-[50px]'>

        <Fullcalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={'dayGridMonth'}
          headerToolbar={{
            start: 'today',
            center: 'title',
            end: 'prev,next',
          }}
          height={'90vh'}
          events={events}
          eventClick={handleClick}
        />

        <Dialog
          style={{
            display:screenWidth > 1024 ? 'block' : 'flex',
            justifyContent:'center',
            alignItems: 'center',

            left:
            screenWidth > 1024 ? 
              datePosition.left > 900
                ? datePosition.left - 260
                : datePosition.left + 160 : '' ,
                
                // 150 > datePosition.left > 80
                // ? datePosition.left-200 : datePosition.left-30,


            top:
            screenWidth > 1024 ? 
            datePosition.top > 486 ? datePosition.top-250 :  datePosition.top-40 : 
            '',
            height: 'auto !important',
            width: "257px !important",
            backgroundColor:screenWidth > 1024 ? '' : 'rgba(10,10,10,0.4)'

          }}
          open={open}
          onClose={handleClose}
          className="calendar__dialog">

          <DialogContent
            style={{
              position: 'relative',
              width: '257px',
              // height: '304px',
              paddingLeft: '0px',
              paddingRight: '0px',
              paddingBottom: '1rem',
              paddingTop: 0,
              backgroundColor: `${
                (filterEvent?.category?.id === 2 && '#F7EFB3') ||
                (filterEvent?.category?.id === 3 && '#CCF4E3') ||
                (filterEvent?.category?.id === 4 && '#F8D8C0') ||
                (filterEvent?.category?.id === 1 && '#CDE8F7')
              }`,
            }}
          >
            <div
              style={{
                height: '11px',
                background: `${
                  (filterEvent?.category?.id === 2 && '#00CD77') ||
                  (filterEvent?.category?.id === 3 && '#E7CA00') ||
                  (filterEvent?.category?.id === 4 && '#F16500') ||
                  (filterEvent?.category?.id === 1 && '#0087D2')
                }`,
                width: '100%',
              }}
            ></div>
            <div className="px-[20px] pt-2">
              <h1 className="text-neutral-500 text-[12px] font-bold">
                {filterEvent?.title}
              </h1>
            </div>
            <div className="px-[10px] mt-[15px]">
              <div className="flex border-t-[1px] pt-[8px] border-zinc-400 mb-2">
                <img
                  src={require('../../../src/assets/images/Square Clock.png')}
                  alt=""
                />
                <span className="text-neutral-500 text-[10px] font-normal ml-[0.2rem]">
                  {filterEvent?.start_date} {filterEvent?.start_hour}
                </span>
              </div>

              {filterEvent?.location && (
                <div className="flex border-t-[1px] pt-[8px] border-zinc-400 mb-2">
                  <img
                    src={require('../../../src/assets/images/Location.png')}
                    alt=""
                  />
                  <span className="text-neutral-500 text-[10px] font-normal ml-[0.2rem]">
                    {filterEvent?.location}
                  </span>
                </div>
              )}

              {filterEvent?.provider_name && (
                <div className="flex border-t-[1px] pt-[8px] border-zinc-400 mb-2">
                  <img
                    src={require('../../../src/assets/images/Customer.png')}
                    alt=""
                  />
                  <span className="text-neutral-500 text-[10px] font-normal ml-[0.2rem]">
                    {filterEvent?.provider_name}
                  </span>
                </div>
              )}

              {filterEvent?.image && (
                <div>
                  <img
                    className="w-[205px] h-[61px] ml-5 object-cover"
                    src={`${process.env.REACT_APP_DEV_API_URL}storage/${filterEvent?.image}`}
                    alt=""
                  />
                </div>
              )}

              {filterEvent?.description && (
                <div className="flex pt-[8px]">
                  <img
                    className="h-[100%]"
                    src={require('../../../src/assets/images/Playlist.png')}
                    alt=""
                  />
                  <span className="text-neutral-500 text-[10px] font-normal ml-[0.2rem]">
                    {filterEvent?.description}
                  </span>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>

      <div className="px-[20px] md:px-[50px] pt-[150px]">
        <div>
          <h1 className="text-neutral-800 text-[44px] font-bold">
            More Events
          </h1>
        </div>
        <div className="mt-[50px] py-[0] md:py-[50px] block md:flex flex-wrap justify-center">
          {sortedDataEvents &&
            sortedDataEvents.map((moreEvent) => (
              moreEvent && <div key={moreEvent?.id} className="w-[100%] md:w-[310px] mt-[50px] md:mt-0 mr-[40px] h-[380px] md:h-[336px] bg-white border border-stone-300 flex flex-col justify-between">
              <div className="flex">
                <div className={`w-3 h-[90px] ${
                (moreEvent?.category_id === 1 && 'bg-[#0087D2]') ||
                (moreEvent?.category_id === 2 && 'bg-[#00CD77]') ||
                (moreEvent?.category_id === 3 && 'bg-[#E7CA00]') ||
                (moreEvent?.category_id === 4 && 'bg-[#F16500]')
              }`}></div>
                <div className="flex ml-[25px] items-center">
                  <h1 className="text-neutral-800 text-[64px] font-bold">
                    {new Date(moreEvent?.start_date).getDate()}
                  </h1>
                  <div className="ml-[10px]">
                    <h1 className="text-neutral-800 text-[24px] font-normal">
                    {new Date(moreEvent?.start_date).toLocaleDateString('default', { weekday: 'long' })}
                    </h1>
                    <span className="text-neutral-500 text-[16px] font-normal">
                    {new Date(moreEvent?.start_date).toLocaleDateString('default', { month: 'long' })},{new Date(moreEvent?.start_date).getFullYear()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-[50px] px-[20px]">
                <h1 className="text-neutral-800 text-[23px] font-bold">
                  {moreEvent?.title}
                </h1>
                <span className="text-neutral-500 text-[16px] font-normal">
                  {moreEvent?.start_hour &&
                    `Starts at ${moreEvent?.start_hour}`}
                </span>
              </div>
              <div className='w-[100%] h-[180px] md:h-[100px]'>
                <img className='h-[100%] w-[100%] object-cover' src={`${process.env.REACT_APP_DEV_API_URL}storage/${moreEvent.image}`} alt="" />
              </div>
            </div>
            ))}
        </div>
        <div></div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default Calendar

import React,{useEffect, useState,useLocation} from 'react'
import { Link } from 'react-router-dom'
import LoginLeftSide from '../login/LoginLeftSide'
import { useNavigate} from 'react-router-dom'
import {APIRoutes} from "../../../core/api/routes"
import axios from 'axios';


export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [newPasswordNoConfirm, setNewPasswordNoConfirm] = useState('');

    const [errorPassword, setErrorPassword] = useState('');
    const navigate = useNavigate()
   

    const handleSubmit = async (e) => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
        const email = searchParams.get("email");
        if (newPassword !== newPasswordConfirm) {
            setNewPasswordNoConfirm('Passwords do not match');
            return;
          }
        e.preventDefault();
        const data = {
            token:token,
            email:email,
            password:newPassword,
            password_confirmation:newPasswordConfirm
        }
        axios.post(APIRoutes.resetPassword,data).then((response) => {
            if(response){
                navigate("/login")
             }
        }).catch((err) => {
            console.log(err.response.data.error);
            setErrorPassword(err.response?.data?.data?.password?.[0]);
          });
      };
   
    return (
        <div className='flex h-[100vh]'>
            <LoginLeftSide />

            <div className='w-[100%] sm:w-[52%] bg-[#F0F0F0]'>
                <div className='w-[80%] md:w-[80%] lg:w-[70%] xl:w-[50%] mx-auto flex flex-col h-[100%] justify-center'>
                    <div className='text-center mt-[18px]'>
                        <h1 className='text-[24px] font-bold text-[#1C1C1C] '>
                            Reset Password
                        </h1>
                    </div>
                    <div className='mt-[33px]'>
                        <input type="password" className='w-full py-[17.5px] px-[19px] bg-white text-[#A0A0A0] text-[14px]' placeholder='Enter password'
                          value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                        <input type="password" className='w-full py-[17.5px] px-[19px] bg-white text-[#A0A0A0] text-[14px] mt-[10px]' placeholder='Repeat Password'  
                        value={newPasswordConfirm} onChange={(e) => setNewPasswordConfirm(e.target.value)} />
                    </div>
                    <span className='text-[red]'>{newPasswordNoConfirm}</span>
                    <span className='text-[red]'>{errorPassword}</span>
                    <div className='mt-[26px]'>
                        <button onClick={handleSubmit} className='uppercase text-center w-[100%] py-[18px] text-[white] bg-black font-bold text-[14px]'>Reset Password</button>
                    </div>
                    <div className='mt-[25px] text-center '>
                        <Link to={"/login"} className='text-[#2699FB] text-[14px] underline'>Return to Log In</Link>
                    </div>
                </div>


            </div>



        </div>
    )
}

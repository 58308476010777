import React, { useEffect, useMemo } from 'react'
import InnerHeader from '../../components/layout/public/components/InnerHeader'
import SecretariatBoardCard from '../../components/shared/card/SecretariatBoardCard'
import CardPresident from '../../components/shared/card/CardPresident'
import { useDispatch, useSelector } from 'react-redux'
import { getBoardSecretariat } from '../../components/slices/boardSecretariat'
import { motion } from 'framer-motion'

export default function Secretariat() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBoardSecretariat({ type: 0 })).then((res) => {
      return res.payload
    })
  }, [dispatch])
  const secretariat = useSelector(
    (state) => state.boardSecretariat.boardSecretariat,
  )
  
  const onePer = useMemo(
    () => secretariat.find((onePerson) => +onePerson.main === 1),
    [secretariat],
  )
  const otherPer = useMemo(
    () => secretariat.filter((otherPerson) => +otherPerson.main === 0),
    [secretariat],
  )

  const container = {
    visible:{
      transition:{
        delayChildren:0.5,
        staggerChildren:0.4
      }
    }
  }

  return (
    <div>
      <InnerHeader
        title={'Secretariat'}
        text={
          'Meet our staff members who coordinate the projects and activities of NAM Youth Organization all around the globe.'
        }
        img={require('../../assets/images/secretariat-pic.png')}
      />
       <motion.div
        initial="hidden"
        animate="visible"
       variants={container}
        className="px-[20px] md:px-[50px] py-[50px]">
      {onePer && <CardPresident img={`${process.env.REACT_APP_DEV_API_URL}storage/${onePer?.image}`} name={onePer?.title} about={onePer?.description}/>}
      <div className='mt-[100px] grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
        {otherPer && otherPer.map(otherPersons => (
          <SecretariatBoardCard 
          image={`${process.env.REACT_APP_DEV_API_URL}storage/${otherPersons?.image}`}
          fullName={otherPersons?.title}
          duty={otherPersons?.duty}
          unique={otherPersons?.id}
          key={otherPersons?.id}
          facebook={otherPersons?.facebook}
          instagram={otherPersons?.instagram}
          />
        ))}
      </div>
    </motion.div>
    </div>
  )
}

import React from "react";
import ChaptersHeader from "./components/ChaptersHeader";
// import Map from "../../shared/map/WorldMap";

export default function ChaptersLayout({ children }) {
  return (
    <>
      <div className="bg-[#F0F0F0]">
        {/* <Map /> */}
      </div>
      <ChaptersHeader />
      {children}
    </>
  );
}

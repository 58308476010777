import React from 'react'
import PostItem from '../../components/shared/post-item/Post-Item'

const MyCabinetArticles = () => {
  return (
    <div>
      <PostItem/>
    </div>
  )
}

export default MyCabinetArticles

import axios from "axios"
import { APIRoutes } from "../../core/api/routes"

export const getEvents = async() => {
    try {
        const response = await axios.get(`${APIRoutes.events}`)
        return response.data
    } catch (error) {
        console.log(error)
    }
}
import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

const blogsGet = async(id) => {
    try {
        if (id) {
            const response = await axios.get(`${APIRoutes.blogs}/${id}`)
            return response.data
        } else {
            const response = await axios.get(`${APIRoutes.blogs}`)
            return response.data
        }

    } catch (error) {
        console.log(error);
    }
}

export default blogsGet
import React from 'react'

import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

export default function MainProjectsCard({text,image,cardId}) {
  
  const navigate= useNavigate()
  const item = {
    hidden:{
      opacity:0,
      translateY:20
    },
    visible:{
      opacity:1,
      translateY:0
    }
  }
  return (
    <>
      <motion.div
      variants={item}
      
      key={cardId}
        onClick={() => navigate(`/projects/${cardId}`)}
        className="relative h-[40vh] hover:-translate-y-6 ease-in duration-300"
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition:'center',
          backgroundSize:'cover',
          cursor:'pointer'
        }}
      >
        <div className='absolute bottom-0 py-[18px] md:py-[24px] px-[16px] md:px-[20px] w-[100%]' style={{background: "rgba(11, 36, 71, 0.5)"}}>
          <h1 className='text-[#F0F0F0] text-[18px] md:text-[24px] font-bold leading-[29px] '>{text}</h1>
        </div>
      </motion.div>
    </>
  )
}

import React from 'react';
import { Outlet,Navigate } from 'react-router'
import Login from './components/layout/login/Login';

const LoginRouteCheck = () => {
   let auth = {'token':localStorage.getItem('token')}

    return (
        !auth.token ? <Login/> : <Navigate to ="/myCabinet/view"/>
    );
}

export default LoginRouteCheck;

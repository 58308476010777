import React, { useEffect } from 'react'
import OpportunitiesLayout from '../../components/layout/public/OpportunitiesLayout'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Job from './Job'
import { getOpportunities } from '../../components/slices/opportunities'
export default function Opportunities() {
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(getOpportunities({category_id:null})).then(res => {
        return res
      })
    }, [dispatch]);
  return (
    <div>
        <OpportunitiesLayout>
            <Outlet/>
            {location.pathname === "/opportunities" ? <Job/> : null}
        </OpportunitiesLayout>

    </div>
  )
}

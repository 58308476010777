import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import UserLayoutAnother from '../../components/layout/public/UserLayout'
import { useLocation } from 'react-router-dom'
import ViewAnother from '../../components/shared/view/ViewAnother'

export default function UserLayout() {
    const location = useLocation();
    const {id} =useParams()

  return (
    <div>
        <UserLayoutAnother>
            <Outlet/>
            {location.pathname=== `/user/${id}`? <ViewAnother/> : null}
        </UserLayoutAnother>
    </div>
  )
}

import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getChapterForId } from '../../../slices/chapter'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import MapChart from '../../../../MapChart'
import { getMemberProfile } from '../../../slices/auth'

export default function ChaptersHeader() {
  const location = useLocation()
  const chapterForId = useSelector((state) => state.chapter.chapter)
  const { id } = useParams()
  const userData = JSON.parse(localStorage.getItem('user'))
  const dispatch = useDispatch()
  const { member: memberV } = useSelector((state) => state.auth)
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('id');

  useEffect(() => {
    if(id){
      dispatch(getChapterForId({ id: id })).then(res => {
        return res
      })
    }
   
  }, [id,dispatch])

  

  useEffect(() => {
    if(userId && token){
      dispatch(getMemberProfile({ id: userId, token: JSON.parse(token) })).then(response => {
        return response
      })
    }
    
    
  }, [dispatch,userId,token]);


  const flag = chapterForId?.flag
  return (
    <>
      <MapChart />
      <div
      id='overview'
        className={`${
          location.pathname === '/chapters' ? 'hidden' : 'block'
        } px-[50px] py-[69px]`}
      >
        <div className="flex items-center">
          <div className="w-[72px] h-[52px]">
            <img
              src={`https://api.namyouth.org/storage/${flag}`}
              alt=""
              className="w-[100%] h-[100%] object-cover"
            />
          </div>
          <div className="ml-[25px]">
            <h1 className="text-[44px] text-[#202021]">{chapterForId?.name}</h1>
          </div>
        </div>
        <div className="py-[24px] border-t border-b border-black mt-[30px]">
          <NavLink
            style={({ isActive }) => ({
              fontWeight: isActive ? '700' : '400',
            })}
            className="pr-[24px] py-[14px]"
            to={`overview/${chapterForId?.id}`}
          >
            Overview
          </NavLink>
          {userData?.roles == '' ? (
            ''
          ) : (
            memberV?.member?.data?.member?.email_verified_at ? 
            <NavLink
              style={({ isActive }) => ({
                fontWeight: isActive ? '700' : '400',
              })}
              className="pl-[24px] py-[14px]"
              to={`${memberV?.member?.data?.member?.email_verified_at && `members/${chapterForId?.id}`}`}
            >
              Members
            </NavLink> : ""
          )}
        </div>
      </div>
    </>
  )
}

import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { useLockBodyScroll } from '@uidotdev/usehooks';

const Search = ({ setSearchActive }) => {
  useLockBodyScroll();

  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
  ];




  return (
    <div className="fixed bg-[#2E3652] overscroll-none h-screen w-full top-0 left-0 z-50">
      <div className="w-3/5 mx-auto">
        <div className="flex justify-end">
          <div
            className="cursor-pointer"
            onClick={() => setSearchActive(false)}
          >
            x
          </div>
        </div>
        <div className="flex flex-col justify-center h-screen -translate-y-10">
          <Stack spacing={1} sx={{ width: "100%",backgroundColor:"red" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{
                backgroundColor:"red"
              }}
              renderInput={(params) => (
                <TextField
                    sx={{
                        backgroundColor:"red"
                    }}
                  {...params}
                  id="standard-basic"
                  label="Standard"
                  variant="standard"
                  type='search'
                />
              )}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Search;

import React,{useState,useEffect} from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { NavLink, useParams } from 'react-router-dom'
import { getChapters } from "../../../../slices/auth";
import { useDispatch } from "react-redux";
import { getChapterForId } from '../../../../slices/chapter';


export default function BasicTabs({homeFeedins,homeFeedYt}) {
  const [value, setValue] = React.useState(0);
  const [feedInsta, setFeedInsta] = useState("");
  const [feedYoutube, setFeedYoutube] = useState("");
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();

  useEffect(() => {
   if(id){
    dispatch(getChapterForId({ id: id })).then(res => {
      setFeedInsta(res?.payload?.instagram_feed);
      setFeedYoutube(res?.payload?.twitter_feed);
    })
   }
  }, [dispatch,id]);


  function TabPanel(props) {
  const { children, value, index, ...other } = props;
  (function () {
    var i, e, d = document, s = "script"; i = d.createElement("script"); i.async = 1; i.charset = "UTF-8";
    i.src = !feedInsta ? homeFeedins : feedInsta;
    e = d.getElementsByTagName(s)[0]; e.parentNode.insertBefore(i, e);
  })();
  (function () {
    var i, e, d = document, s = "script"; i = d.createElement("script"); i.async = 1; i.charset = "UTF-8";
    i.src =!feedYoutube ? homeFeedYt :feedYoutube;
    e = d.getElementsByTagName(s)[0]; e.parentNode.insertBefore(i, e);
  })();
  return (
    <div hidden={value !== index}>
      {value === index && (
        <div id={`curator-feed-${index === 0 ? 'default' : 'new'}-feed-layout`}>
          {children}
        </div>
      )}
    </div>
  );
}

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
     {(id && (feedInsta || feedYoutube) ?  <>
      <div className='flex items-center justify-between pb-[48px]'><h4 className='font-bold text-[44px] text-[#202021] max-[768px]:text-[18px]'>Social Media Feed</h4> <NavLink className="border-b-[1px] pb-[6px] text-[16px] max-[768px]:text-[13px]">See more</NavLink></div>
      <Box sx={{ width: '100%' }} className="tab-panel">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab id='tab1' label="Instagram" className="px-[17px] py-[22px] max-[768px]:py-[9px] max-[768px]:text-[12px] border-solid border-[#202020]" {...a11yProps(0)} />
            <Tab id='tab2' label="YouTube" className="px-[17px] py-[22px] border-solid border-[#202020]" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className="pt-[38px]">
          <div id="curator-feed-default-feed-layout"></div>
      </TabPanel>
        <TabPanel value={value} index={1} className="pt-[38px]">
          <div id="curator-feed-new-feed-layout"></div>
        </TabPanel>
      </Box>
     </> : (homeFeedYt || homeFeedins) ?  <>
      <div className='flex items-center justify-between pb-[48px]'><h4 className='font-bold text-[44px] text-[#202021] max-[768px]:text-[18px]'>Social Media Feed</h4> <NavLink className="border-b-[1px] pb-[6px] text-[16px] max-[768px]:text-[13px]">See more</NavLink></div>
      <Box sx={{ width: '100%' }} className="tab-panel">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab id='tab1' label="Instagram" className="px-[17px] py-[22px] max-[768px]:py-[9px] max-[768px]:text-[12px] border-solid border-[#202020]" {...a11yProps(0)} />
            <Tab id='tab2' label="YouTube" className="px-[17px] py-[22px] border-solid border-[#202020]" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className="pt-[38px]">
          <div id="curator-feed-default-feed-layout"></div>
      </TabPanel>
        <TabPanel value={value} index={1} className="pt-[38px]">
          <div id="curator-feed-new-feed-layout"></div>
        </TabPanel>
      </Box>
     </>  : '')
     }
    </>
  );
}

import React from "react";
import { RiFacebookFill, RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";

export default function MiniHeader({
  bgImage,
  name,
  personImg,
  duty,
  country,
  facebook,
  instagram,
}) {
  console.log(null)
  return (
    <div
      className="bg-no-repeat bg-center bg-cover"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div
        className="h-[100%] pt-[120px] pb-[20px]"
        style={{
          backgroundColor: "rgba(11,36,71,.5)",
        }}
      >
        <div className="flex flex-wrap md:justify-between justify-center items-center h-[100%] px-[50px] mt-[30px]">
          <div className="text-center md:text-start">
            <h1 className="text-[40px] font-bold text-white text-center md:text-start">
              {name}
            </h1>
            <span className="text-[18px] text-white font-bold mt-[17px]">
              {duty}
            </span>
          </div>
          <div className="h-[200px] w-[200px] md:h-[260px] md:w-[260px] mt-[50px] md:mt-0 rounded-full border-8 border-[white]">
            <img
              src={personImg}
              alt=""
              className="w-[100%] h-[100%] object-cover object-top rounded-full"
            />
          </div>
        </div>
        <div className="flex justify-between px-[50px] mt-[67px]">
          <div>
            <h1 className="text-white text-[18px] ">{country}</h1>
          </div>
          <div>
            {facebook ? (
              <Link to={facebook} target='_blank'>
                <RiFacebookFill className="text-[18px] text-[white]" />
              </Link>
            ) : (
              ""
            )}
            {instagram ? (
              <Link to={instagram} target='_blank'>
                <RiInstagramFill className="text-[18px] ml-[15px] text-[white]" />
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

const opportunitiesItems = async function getOpportunitiesItems(category_id, country_id, title) {
    try {
        const params = {};
        if (category_id) {
            params.category_id = category_id;
        }
        if (country_id) {
            params.country_id = country_id;
        }
        if (title) {
            params.title = title;
        }
        const response = await axios.get(APIRoutes.opportunitiesItems, { params })
        return response.data
    } catch (err) {
        console.log("error getting opportunities")
    }
}

const OpportunitiesService = {
    opportunitiesItems
}
export default OpportunitiesService
import React, { useEffect, useState } from 'react'
import NewsHeader from '../../components/layout/public/components/InnerHeader'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import BlogArticlesCard from '../../components/shared/card/BlogArticlesCard'
import getArticles from '../../components/services/article.service'
import getArticlesSearch from '../../components/services/articleSearch.service'
export default function Articles() {
  const [title, setTitle] = useState('')
  const [articles, setArticles] = useState([]);


  useEffect(() => {
    getArticles(title).then(res => {
      setArticles(res.data.articles)
    })
  }, []);

  useEffect(() => {
    getArticlesSearch(title).then(res => {
      setArticles(res.data.articles)
    })
  }, [title]);

  return (
    <>
      <NewsHeader
        img={require('../../assets/images/opp-bg-img.png')}
        title={'Article'}
        text={
          'The publications submitted by our members and National Chapters can be accessed through this section. '
        }
      />

      <div className="px-[20px] md:px-[50px]">
        <div className="block border mt-[30px] md:flex items-center" id="topDates">
          <div className="relative w-[100%] xl:w-[30%]">
            <input
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              className="block w-full px-3 py-4 bg-[#F0F0F0] border border-[#F0F0F0] text-sm shadow-sm placeholder-[black] focus:outline-none focus:border-[#F0F0F0] focus:ring-1 focus:ring-[#F0F0F0]"
              placeholder="Search by keyword"
            />
            <button
              type="submit"
              className="absolute top-0 right-0 px-2.5 py-4 text-sm font-medium text-black "
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
         
        </div>
        
        {articles.length ===0 && <h1 className='font-bold text-[20px]'>No data</h1>}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-[54px]">
          {articles && articles.map(article => (
            <BlogArticlesCard
            id={article?.id}
            key={article?.id}
            coverImage={article?.cover_image}
            description={article?.description}
            title={article?.title}
            createdTime={article?.created_at}
            memberName={article?.member?.first_name}
            memberLastName={article?.member?.last_name}
            memberImage={article?.member?.avatar}
            pdf={article?.pdf_file}
            />
          ))}
        </div>
      </div>
    </>
  )
}

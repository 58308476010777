import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Resend from './Resend'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Oval } from 'react-loader-spinner'

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(false);
  const location = useLocation()
  const navigate=  useNavigate();
  const query = new URLSearchParams(location.search)
  const { id } = useParams()
  const expires = query.get('expires')
  const hash = query.get('hash')
  const signature = query.get('signature')
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    const verifyEmailUrl = async () => {
      setLoading(true)

      try {
        const url = `https://namyouth.org/api/email/verify/${id}?expires=${expires}&hash=${hash}&signature=${signature}`
        const data = await axios.get(url)
        console.log(data)
        setValidUrl(true)
      }
      catch (error) {
        setLoading(true)
        setValidUrl(false)

      }
      finally{
        setLoading(false)

      }
    }
    verifyEmailUrl()
  }, [id, expires, hash, signature])
  console.log('expires', expires, 'hash', hash)
  return (
    <>

      {loading ? <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
       : !validUrl? 
       <Resend/>
        :<div className='mx-auto w-[50%] h-[100vh] flex flex-col justify-center'>
        <div className='text-center'>
          <div className='flex justify-center'>
            <img src={require('../../../assets/images/Group2265.png')} alt="" />
          </div>
          <div>
            <div>
              <h1 className='text-[#2A538A] text-[48px] font-bold'>Verified</h1>
            </div>
            <div className='mt-[14px]'>
              <p className='text-[#A0A0A0] text-[14px]'>You have successfully verified the account. </p>
            </div>
          </div>
          <div className='mt-[104px]'>
            <button className=' bg-[#2A538A] px-[125px] py-[18px] text-white border border-[#2A538A] hover:bg-white hover:text-[#2A538A] transition duration-150 ease-in-out' onClick={() => navigate("/login")}>Go to Login</button>
          </div>
        </div>
      </div>
       }
       
    </>
  )
}

export default EmailVerify

import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HiOutlineUpload } from 'react-icons/hi'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import TextInput from '../input/TextInput'
import createBlog from '../../services/blogPost.service'
import postArticle from '../../services/postArticle.service'
import { Editor } from '@tinymce/tinymce-react'
import Swal from 'sweetalert2'
import paginate from '../../shared/pagination/PaginateArticleBlog'
import { FaFilePdf, FaDownload } from 'react-icons/fa'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { getMemberProfile } from '../../slices/auth'
import File from "../../../assets/doc/Templateforarticle.docx";


const PostItem = () => {
  const [title, setTitle] = useState('')
  const [previewImage, setPreviewImage] = useState(null)
  const [previewImageFile, setPreviewImageFile] = useState(null)

  const [pdfName, setPdfName] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  const [text, setText] = useState('')

  const [myArticles, setMyArticles] = useState([])
  const [myBlogs, setMyBlogs] = useState([])

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [paginationData, setPaginationData] = useState([])
  const [paginationArticleData, setPaginationArticleData] = useState([])

  //Error messages
  const [titleError, setTitleError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [coverImageError, setCoverImageError] = useState('')
  const [pdfError, setPdfError] = useState('')

  // const[blogTitle,setBlogTitle] = useState()

  const location = useLocation()
  const locationParse = location.pathname.split('/')

  const articles = location.pathname === '/myCabinet/myCabinetArticles'
  const blog = location.pathname === '/myCabinet/myCabinetBlog'
  const memberV = useSelector((state) => state.auth.member)
  const memberProfil = memberV?.member.data.member
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('id')
  const dispatch = useDispatch()

  const handlePage = (index) => {
    setPage(index)
  }

  const nextPage = () => {
    if (
      articles
        ? page === paginationArticleData?.length - 1
        : page === paginationData?.length - 1
    ) {
      setPage(0)
    } else {
      setPage(page + 1)
    }
  }
  const prevPage = () => {
    if (page === 0) {
      setPage(
        articles
          ? paginationArticleData?.length - 1
          : paginationData?.length - 1,
      )
    } else {
      setPage(page - 1)
    }
  }

  const sortedData = useMemo(() => {
    if (memberProfil?.blogs) {
      return [...memberProfil.blogs].sort((a, b) => b?.id - a?.id)
    }
    return []
  }, [memberProfil?.blogs])

 
  const sortedDataArticle = useMemo(() => {
    if (memberProfil?.articles) {
      return [...memberProfil?.articles].sort((a, b) => b?.id - a?.id)
    }
    return []
  }, [memberProfil?.articles])



  useEffect(() => {
    setPaginationData(paginate(sortedData))
    setPaginationArticleData(paginate(sortedDataArticle))
    setLoading(false)
    setPage(0)
  }, [sortedDataArticle, sortedData])

  useEffect(() => {
    if (loading) return
    setMyBlogs(paginationData[page])
  }, [loading, page, paginationData])

  useEffect(() => {
    if (loading) return
    setMyArticles(paginationArticleData[page])
  }, [loading, page, paginationArticleData])

  const handleSelectImage = (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () => {
      setPreviewImage(fileReader.result)
    })
    fileReader.readAsDataURL(file)
    setPreviewImageFile(file)
  }

  const handleSelectPdf = (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }
    setPdfFile(file)
    setPdfName(file.name.slice(0, 16))
  }

  const handleSendBlog = () => {
    createBlog({
      title: title,
      cover_image: previewImageFile,
      description: text,
    })
      .then((res) => {
        if (res.status === 201) {
          setMyBlogs([res.data.data.blog, ...sortedData])
          setLoading(false)
          setPage(0)
          dispatch(
            getMemberProfile({ id: userId, token: JSON.parse(token) }),
          ).then((response) => {
            return response
          })
          Swal.fire('Good job!', 'You clicked the button!', 'success')
        }
        setTitle('')
        setPreviewImage(null)
        setText('')
        setTitleError('')
        setDescriptionError('')
        setCoverImageError('')
      })
      .catch((error) => {
        setTitleError(error.response.data.data.title)
        setDescriptionError(error.response.data.data.description)
        setCoverImageError(error.response.data.data.cover_image)

        Swal.fire({
          icon: 'error',
          text: error.response.data.error,
        })
      })
  }

  const handleSendArticle = () => {
    postArticle({
      title: title,
      cover_image: previewImageFile,
      description: text,
      pdf_file: pdfFile,
    })
      .then((res) => {
        if (res.status === 201) {
          setMyArticles([res.data.data.article, ...sortedDataArticle])
          setLoading(false)
          setPage(0)
          dispatch(
            getMemberProfile({ id: userId, token: JSON.parse(token) }),
          ).then((response) => {
            return response
          })
          Swal.fire('Good job!', 'You clicked the button!', 'success')
        }
        setTitle('')
        setPreviewImage(null)
        setText('')
        setPdfFile('')
        setPdfName('')
        setTitleError('')
        setDescriptionError('')
        setCoverImageError('')
        setPdfError('')
      })
      .catch((error) => {
        setTitleError(error.response.data.data.title)
        setDescriptionError(error.response.data.data.description)
        setCoverImageError(error.response.data.data.cover_image)
        setPdfError(error.response.data.data.pdf_file)
        Swal.fire({
          icon: 'error',
          text: error.response.data.error,
        })
      })
  }

  function formatDate(date) {
    const dateObj = new Date(date)
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const day = dateObj.getDate().toString().padStart(2, '0')
    const year = dateObj.getFullYear().toString()
    return `${month}.${day}.${year}`
  }
  const handleEditorChange = (text) => {
    setText(text)
  }
  const toolbar =
    'undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image'

  const contentStyle = `
    img.float-right {
      max-width: auto;
      height: auto;
      float: auto;
      margin: 0 0 10px 10px;
    }
  `
  // const downloadFile = () => {
  //   const googleDocsUrl = "https://docs.google.com/document/d/13kM2L06KdtGjtsYR-3ygdzcZNqgVAdF4/export?format=docx"; // Replace with your Google Docs URL

  //   // Fetch the content of the Google Docs link
  //   fetch(googleDocsUrl)
  //     .then(response => response.text())
  //     .then(fileContent => {
  //       // Create a Blob with the file content
  //       console.log(fileContent)
  //       const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

  //       // Create a temporary anchor element
  //       const anchor = document.createElement('a');
  //       anchor.href = URL.createObjectURL(blob);

  //       // Set the filename for the download
  //       anchor.download = 'document.docx';

  //       // Programmatically click the anchor to start the download
  //       anchor.click();

  //       // Clean up the URL object after the download
  //       URL.revokeObjectURL(anchor.href);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching file content:', error);
  //     });
  // };
   
  return (
    <div className="mt-[20px] md:mt-[72px] px-[25px] md:px-[54px]">
      <div>
        <h1 className="text-[#202021] text-[30px] md:text-[44px] font-bold pb-[30px] md:pb-[56px]">
          {articles ? 'Articles' : blog ? 'Blog' : ''}
        </h1>
        {articles ? (
          <div className="block lg:flex gap-x-[146px] pb-[25px] md:pb-[50px]">
            <div className="mb-[30px] xl:mb-[0]">
              <h4 className="text-[#202021] text-[24px] font-bold pb-[21px]">
                PDF
              </h4>
              <div className="file-input-container w-[100%] md:w-[100%] lg:w-[auto] px-[110px] md:px-[169px] bg-[#E9E9E9]">
                <label
                  htmlFor="fileInput"
                  className="file-input-label flex-col bg-[#E9E9E9] py-[35px] items-center gap-y-[14px] flex gap-x-[61px] text-[#707070] text-[15px] font-bold"
                >
                  <FaFilePdf className="text-[40px] text-[#999999]" />
                  {pdfName ? <p>{pdfName}</p> : 'Upload PDF'}
                </label>
                <input
                  type="file"
                  id="fileInput"
                  className="file-input"
                  onChange={handleSelectPdf}
                />
              </div>
              <br />

              <span className="text-[red] block mt-[10px]">{pdfError}</span>
            </div>
            <div className="mb-[30px] xl:mb-[0]">
              <h4 className="text-[#202021] text-[24px] font-bold pb-[21px]">
                Template
              </h4>
              {/* <div className="">
            </div> */}
              <div className="file-input-container w-[100%] md:w-[100%] lg:w-[auto] bg-[#E9E9E9] ">
                <a
                href={require("../../../assets/doc/Templateforarticle.docx")}
                download="template-for-article"
                target="_blank"
                rel="noreferrer"
                  className="file-input-label flex-col px-[100px] py-[35px] md:px-[169px] bg-[#E9E9E9] items-center gap-y-[14px] flex gap-x-[61px] text-[#707070] text-[15px] font-bold"
                >
                  <FaDownload className="text-[40px] text-[#999999]" />
                  Download template
                </a>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="pb-[25px] md:pb-[50px]">
          <h4 className="text-[#202021] text-[24px] font-bold pb-[17px]">
            Title
          </h4>
          <TextInput
            handle={setTitle}
            value={title}
            iplaceholder="250 characters max"
            className="w-[100%] xl:w-[424px] h-[50px]"
          />
          <span className="text-[red] mt-[10px] block">{titleError}</span>
        </div>
        {/* Description */}
        <div className="pb-[80px]">
          <h4 className="text-[#202021] text-[24px] font-bold pb-[17px]">
            Description
          </h4>
          <div className="w-[100%] xl:w-[879px] h-[324px]">
            <Editor
              apiKey="x0erwvj5y7znub867ugv0kn91eiqoupsn4pshhuc1e6zjpvo"
              onEditorChange={handleEditorChange}
              value={text}
              init={{
                content_style: contentStyle,
                toolbar: toolbar,
                image_advtab: true,
                automatic_uploads: false,
                selector: 'textarea#local-upload',
                plugins: 'image code',
                menubar: true,
                toolbar:
                  'undo redo | formatselect |  ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help | image code',
                images_upload_url: 'postAcceptor.php',

                /* we override default upload handler to simulate successful upload*/
                images_upload_handler: function (blobInfo, success, failure) {
                  setTimeout(function () {
                    /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                    success(
                      'http://moxiecode.cachefly.net/tinymce/v9/images/logo.png',
                    )
                  }, 2000)
                },
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
        </div>
        <span className="text-[red]"> {descriptionError}</span>

        {/* Picture */}
        <div className="mt-[30px]">
          <h4 className="text-[#202021] text-[24px] font-bold pb-[17px]">
            Picture
          </h4>
          <div className="file-input-container mb-[10px]">
            <label
              htmlFor="fileInput"
              className="file-input-label items-center flex gap-x-[61px] px-[15px] py-[10px] bg-[#F3F4F6] text-[#707070] text-[15px] font-bold"
            >
              Upload Picture
              <HiOutlineUpload />
            </label>
            <input
              type="file"
              id="fileInput"
              className="file-input"
              onChange={handleSelectImage}
            />
          </div>
          {previewImage ? (
            <img
              src={previewImage}
              className="w-[424px] h-[263px] border mt-[28px] object-cover"
              alt=""
            />
          ) : null}
          <br />
          <span className="text-[red]">{coverImageError}</span>
        </div>
        <div className="flex gap-x-[35px] pt-[62px] pb-[85px] justify-center">
          <div>
            <button
              className={`uppercase w-[100%] sm:w-auto bg-[#2A538A] border border-[#2A538A] ease-in duration-300 hover:bg-[white] hover:text-[#2A538A] text-[#fff] px-[40px] md:px-[63px] py-[18px] md:py-[23px]`}
            >
              CANCEL
            </button>
          </div>
          <div>
            <button
              onClick={
                locationParse[2] === 'myCabinetBlog'
                  ? handleSendBlog
                  : handleSendArticle
              }
              className={`uppercase w-[100%] sm:w-auto bg-[#2A538A] border border-[#2A538A] ease-in duration-300 hover:bg-[white] hover:text-[#2A538A] text-[#fff] px-[40px] md:px-[63px] py-[18px] md:py-[23px]`}
            >
              SEND
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <h1 className="text-[#202021] text-[24px] font-bold pb-[37px] pt-[108px]">
          {articles ? 'Sent Article posts' : 'Sent blog posts'}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-[30px]">
          {locationParse[2] === 'myCabinetBlog'
            ? myBlogs &&
              myBlogs?.map((blog) => (
                <div key={blog?.id} className="mr-[0] md:mr-[30px] mb-[30px]">
                  <div className="bg-[#2A538A] pl-[33px] pt-[50px] pb-[18px]">
                    <h4 className="text-[white] text-[24px] font-bold">
                      {blog?.title}
                    </h4>
                  </div>
                  <div className="bg-[#F0F0F0] pl-[33px] pt-[27px] pb-[37px]">
                    <ul>
                      <li className="pb-[19px]">
                        Sent: <span>{formatDate(blog.created_at)}</span>
                      </li>
                      <li className="pb-[22px]">
                        Status:{' '}
                        <span>
                          {blog?.status_id === 1
                            ? 'Pending'
                            : blog?.status_id === 2
                            ? 'Published'
                            : 'Rejected'}
                        </span>
                      </li>
                      {blog?.status_id === 2 ? (
                        <li>
                          <Link
                            to={`/myBlog/${blog.id}`}
                            className="underline decoration-1"
                          >
                            View
                          </Link>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              ))
            : myArticles?.map((article) => (
                <div key={article?.id} className="mr-[30px] mb-[30px]">
                  <div className="bg-[#2A538A] pl-[33px] pt-[50px] pb-[18px]">
                    <h4 className="text-[white] text-[24px] font-bold">
                      {article?.title}
                    </h4>
                  </div>
                  <div className="bg-[#F0F0F0] pl-[33px] pt-[27px] pb-[37px]">
                    <ul>
                      <li className="pb-[19px]">
                        Sent: <span>{formatDate(article.created_at)}</span>
                      </li>
                      <li className="pb-[22px]">
                        Status:{' '}
                        <span>
                          {article?.status_id === 1
                            ? 'Pending'
                            : article?.status_id === 2
                            ? 'Published'
                            : 'Rejected'}
                        </span>
                      </li>
                      {article?.status_id === 2 ? (
                        <li>
                          <Link
                            to={`/myArticle/${article.id}`}
                            className="underline decoration-1"
                          >
                            View
                          </Link>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              ))}
        </div>
        <div className="btn-container flex justify-center pt-[15px] pb-[150px]">
          {loading ? null : (
            <button
              className="btn next-btn bg-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]"
              onClick={() => prevPage()}
            >
              {!loading ? <MdKeyboardArrowLeft /> : null}
            </button>
          )}

          {articles
            ? loading
              ? null
              : paginationArticleData?.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`page-btn border-[1px] border-[#E9E9E9] py-[4px] px-[12px] ${
                        index === page
                          ? 'active-btn bg-[#2A538A] text-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]'
                          : null
                      }`}
                      onClick={() => {
                        handlePage(index)
                      }}
                    >
                      {index + 1}
                    </button>
                  )
                })
            : loading
            ? null
            : paginationData?.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={`page-btn border-[1px] border-[#E9E9E9] py-[4px] px-[12px] ${
                      index === page
                        ? 'active-btn bg-[#2A538A] text-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]'
                        : null
                    }`}
                    onClick={() => {
                      handlePage(index)
                    }}
                  >
                    {index + 1}
                  </button>
                )
              })}
          {loading ? null : (
            <button
              className="btn next-btn bg-[#FFFFFF] border-[1px] border-[#E9E9E9] py-[4px] px-[12px]"
              onClick={() => nextPage()}
            >
              {!loading ? <MdKeyboardArrowRight /> : null}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default PostItem

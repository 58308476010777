import React from 'react'
import { Outlet } from 'react-router-dom'
import LayoutAbout from "../../components/layout/public/AboutLayout";
import { useLocation } from 'react-router-dom';
import About from './About';
export default function AboutLayout() {
    const location = useLocation()
  return (
    <>
    <LayoutAbout>
        <Outlet/>
        {location.pathname === "/about" ?<About/> :'he' }
    </LayoutAbout>
    </>
  )
}

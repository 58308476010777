import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ImQuotesRight } from 'react-icons/im'
import { FaAngleRight } from 'react-icons/fa'
import { Pagination } from 'swiper'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import './Home.css'
import MapChart from '../../MapChart'
import { getSettings } from '../../components/slices/auth'
import { getTestimonials } from '../../components/slices/auth'
import SocialMedia from '../../components/layout/public/components/socialMedia/SocialMedia'
import { useDispatch } from 'react-redux'
import ShortNews from '../../components/shared/newsShort/ShortNews'
import { Oval } from 'react-loader-spinner'
import { getChapters } from "../../components/slices/auth";

const Home = () => {
  const [item, setItem] = useState([])
  const [testimonials, setTestimonials] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      setItem(res?.payload?.settings?.data?.settings)
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(getTestimonials()).then((res) => {
      setTestimonials(res?.payload?.settings?.data?.testimonials)
    })
  }, [dispatch])

  const ourMissionImage = item?.find((x) => x.key === 'site.our_mission_image')
    ?.value

  const becomePartImage = item?.find((x) => x.key === 'site.become_part_image')
    ?.value
  const findOpportunitiesMainImage = item?.find((x) => x.key === 'site.find_opportunities_main_image')
    ?.value
  const mainPageBanner = item?.find((x) => x.key === 'site.main_page_banner')?.value

  console.log(mainPageBanner)
  const handleClickMember = () => {
    if (localStorage.getItem('token')) {
      navigate('/myCabinet/view')
    } else {
      navigate('/register')
    }
  }

  useEffect(() => {
    dispatch(getChapters("")).then(res => {
      setFeed(res.payload)
    });
  }, [])

  const feedSocial = feed?.find((x) => x.id === 6)

  return (
    <>
      {item?.length === 0 ? (
        <div className="h-[100vh] bg-[#E9EDF6] flex justify-center items-center text-white text-[44px]">
          <Oval
            height={80}
            width={80}
            color="#2A538A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#2A538A"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div>
          <main>
            <div
              style={
                mainPageBanner &&
                {
                  backgroundImage: `url(${process.env.REACT_APP_DEV_API_URL}storage/${mainPageBanner})`,
                }
              }
              className="transition-all duration-500 h-[100vh] w-[100%] bg-no-repeat bg-center bg-cover relative"
            >
              <div className='bg-sky-950 bg-opacity-50 h-[100%]'>
                <div className="absolute top-[58%] translate-y-[-50%] left-[1.25rem] sm:left-[3.125rem] w-[auto] sm:w-[55%]">
                  <h1 className="text-[30px] leading-[40px] sm:text-[64px] text-white sm:leading-[86px] ">
                    {item?.length > 0 &&
                      item?.find((x) => x.key === 'site.main_page_title')?.value}
                  </h1>
                </div>
              </div>
            </div>

          </main>
          <section data-aos="fade-right" className="bg-[#E9EDF6] pt-[6.625rem] px-[50px] max-[768px]:px-[18px]">
            <div className="relative bg-[#E9EDF6]">
              <div className="flex md:justify-between max-[768px]:flex-col">
                <div className="md:w-[50%] w-full">
                  <h1 className="font-bold md:text-[44px] text-[22px] text-[#202021] max-[768px]:pb-[15px]">
                    National Chapters
                  </h1>
                </div>
                <div className="md:w-[50%] w-full">
                  <p className="text-[18px] leading-[26px]">
                    {item?.length > 0 &&
                      item?.find((x) => x.key === 'site.main_page_chapter_text')
                        ?.value}
                  </p>
                </div>
              </div>
              <MapChart />
            </div>
          </section>
          <section data-aos="fade-right" className="h-[100vh] bg-[#2A538A] relative flex flex-col justify-center max-[768px]:h-[100%]">
            <div className=" flex justify-between items-center px-[50px] max-[968px]:flex-col max-[768px]:px-[25px] max-[768px]:py-[16px]">
              <div className="w-[30%] max-[968px]:w-[100%]">
                <div>
                  <h5 className="font-bold text-[44px] leading-[65px] text-[#FFFFFF]">
                    {item &&
                      item?.find((x) => x.key === 'site.who_we_are_title')
                        ?.value}
                  </h5>
                </div>
                <div className="pb-[22px] pt-[37px]">
                  <p className="text-[white] text-[18px] leading-[30px] font-normal max-[768px]:text-[14px] max-[768px]:leading-[24px]">
                    {item &&
                      item?.find((x) => x.key === 'site.who_we_are_text')
                        ?.value}
                  </p>
                </div>
                <div className="mt-[22px] mb-[32px] md:mb-0">
                  <Link
                    to={'/whoweare'}
                    className="text-[white]  border-b-2 border-white py-[10px]"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="flex max-[768px]:flex-col">
                <div>
                  <img
                    className="h-[100%] max-[768px]:w-[100%] object-cover "
                    src={require('../../assets/images/Konferencija_Pokreta_nesvrstanih_1961._godine.png')}
                    alt=""
                  />
                </div>
                <div className="ml-[54px] max-[768px]:ml-[0] max-[768px]:mt-[10px]">
                  <div className="bg-[#FBFCFE] w-[310px] max-[768px]:w-[100%] max-[768px]:h-auto">
                    <div className="px-[20px] py-[23px]">
                      <h1 className="text-[18px] text-[#202021] font-bold">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.short_history_1_title',
                          )?.value}
                      </h1>
                      <p className="text-[14px] mt-[20px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.short_history_1_text',
                          )?.value}
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#FBFCFE] w-[310px] max-[768px]:w-[100%] max-[768px]:h-[100%] mt-[30px] max-[768px]:mt-[8px]">
                    <div className="px-[20px] py-[23px]">
                      <h1 className="text-[18px] text-[#202021] font-bold	">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.short_history_2_title',
                          )?.value}
                      </h1>
                      <p className="text-[14px] mt-[20px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.short_history_2_text',
                          )?.value}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-right" className="lg:flex md:px-[26px] justify-between items-center bg-[#E9EDF6] py-[221px] max-[1024px]:px-[22px]">
            <h4 className=" font-bold text-[44px] leading-[65px] text-[#202021] pl-[52px] max-[1024px]:pl-[0] max-[768px]:text-[34px]">
              Our Mission
            </h4>
            <p className="text-[24px] leading-[36px] text-[#202021] max-[1024px]:w-[100%] w-[538px]  max-[1024px]:text-[17px] max-[1024px]:leading-[26px] max-[1024px]:pb-[14px]">
              {item &&
                item?.find((x) => x.key === 'site.our_mission_text')?.value}
            </p>
            {ourMissionImage && (
              <img
                src={`${process.env.REACT_APP_DEV_API_URL}storage/${ourMissionImage}`}
                alt=""
                className="w-[30%] pr-[52px] object-cover max-[1024px]:pr-[0] max-[1024px]:w-[100%]"
              />
            )}
          </section>
          <section data-aos="fade-right" className="px-[50px] py-[170px]  max-[768px]:px-[26px] bg-[#2A538A]">
            <div className="sm:flex justify-between items-center pb-[70px] max-[768px]:pb-[30px]">
              <h4 className="text-[44px] font-bold max-[768px]:text-[20px] max-[768px]:pb-[10px] text-[white]">
                Find Opportunities
              </h4>
              <p className=" text-[18px] max-[768px]:text-[13px] text-[white]">
                You can find interesting job opportunities from NAM Youth Organization.
              </p>
            </div>
            <div className="flex max-[992px]:flex-col gap-6 items-center justify-between">

              {findOpportunitiesMainImage && (
                <div className='w-[768px] h-[422px] max-[992px]:w-[100%] max-[992px]:h-[100%]'>
                  <img
                    src={`${process.env.REACT_APP_DEV_API_URL}storage/${findOpportunitiesMainImage}`}
                    alt=""
                    className="w-full h-full object-cover"
                  /></div>
              )}
              <ul className="w-[430px] max-[992px]:w-[100%] max-[992px]:ml-[0]">
                <li className=" group/item group/edit text-[18px] text-[#202021] border-[white] border-b-[1px] border-solid py-[15px]">
                  <Link to={"/opportunities/job"} className="flex justify-between items-center group-hover/edit:cursor-pointer text-[white]">
                    Job/Internships
                    <FaAngleRight className="invisible group-hover/item:visible" />
                  </Link>
                </li>
                <li className=" group/item group/edit text-[18px] text-[#202021] border-[white] border-b-[1px] border-solid py-[15px]">
                  <Link to={"/opportunities/internships"} className="flex justify-between items-center group-hover/edit:cursor-pointer text-[white]">
                    Internships
                    <FaAngleRight className="invisible group-hover/item:visible" />
                  </Link>
                </li>
                <li className=" group/item group/edit text-[18px] text-[#202021] border-[white] border-b-[1px] border-solid py-[15px]">
                  <Link to={"/opportunities/volunteering"} className="flex justify-between items-center group-hover/edit:cursor-pointer text-[white]">
                    Volunteering
                    <FaAngleRight className="invisible group-hover/item:visible" />
                  </Link>
                </li>
                <li className=" group/item group/edit text-[18px] text-[#202021] border-[white] border-b-[1px] border-solid py-[15px]">
                  <Link to={"/opportunities/exchangeprograms"} className="flex justify-between items-center group-hover/edit:cursor-pointer text-[white]">
                    Exchange programs
                    <FaAngleRight className="invisible group-hover/item:visible" />
                  </Link>
                </li>
                {/* <li className=" group/item group/edit text-[18px] text-[#202021] border-[white] border-b-[1px] border-solid py-[15px]">
                  <Link to={"/opportunities/scholarships"} className="flex justify-between items-center group-hover/edit:cursor-pointer text-[white]">
                    Scholarships
                    <FaAngleRight className="invisible group-hover/item:visible" />
                  </Link>
                </li> */}

              </ul>
            </div>
          </section>
          <section data-aos="fade-right" className="md:flex md:flex-wrap md:gap-[33px] md:px-[52px] justify-between bg-[#E9EDF6] py-[122px] max-[768px]:px-[30px] max-[768px]:flex-col max-[768px]:items-center ">
            <div className="max-[768px]:pl-[0]">
              <h4 className="text-[44px] leading-[65px] text-[#202021] pb-[51px] max-[768px]:text-[32px] max-[768px]:leading-[48px]">
                Become part of{' '}
                <span className=" text-[#202021] font-bold block">
                  NAM Youth
                </span>
              </h4>
              <button
                onClick={handleClickMember}
                className="bg-[#2A538A] ease-in duration-300 hover:bg-[white] text-[#FFFFFF] hover:text-[#2A538A] border border-[#2A538A] text-[18px] uppercase tracking-wide py-[23px] px-[28px]"
              >
                Register
              </button>
            </div>
            <p className="font-bold text-[18px] leading-[26px] text-[#202021] w-[308px] max-[768px]:w-[100%] max-[768px]:py-[20px]">
              {item &&
                item?.find((x) => x.key === 'site.become_part_text')?.value}
            </p>
            {becomePartImage && (
              <img
                src={`${process.env.REACT_APP_DEV_API_URL}storage/${becomePartImage}`}
                alt=""
                className="pr-[52px] w-[100%] md:w-[30%] object-cover max-[768px]:pr-[0]"
              />
            )}
          </section>

          {/* News */}
          <div data-aos="fade-right">
            <ShortNews />
          </div>
          <div className="px-[50px] max-[768px]:px-[26px]" data-aos="fade-right">
            <SocialMedia homeFeedins={feedSocial?.instagram_feed}
              homeFeedYt={feedSocial?.twitter_feed} />
          </div>

          <section className="bg-[#E9EDF6] text-[#202021]" data-aos="fade-right">
            <div className="px-[50px] py-[6.68rem]">
              <div className="flex justify-between items-center max-[768px]:flex-col">
                <div>
                  <h1 className="font-bold md:text-[44px] text-[30px] md:pb-[0] pb-[20px]">
                    Our numbers
                  </h1>
                </div>
                <div className="md:w-[35%] w-full ">
                  <p className="text-[18px] leading-[26px]">
                    {item &&
                      item?.find((x) => x.key === 'site.our_numbers_text')
                        ?.value}
                  </p>
                </div>
              </div>
              <div className="mt-[62px] flex max-[768px]:flex-col">
                <div className="relative w-[30%]  max-[768px]:w-full max-[768px]:ml-[0] group overflow-hidden h-[300px] pb-[30px] border-b-[1px] border-gray-500 ml-[31px]">
                  <div className="absolute transition-[0.6s] bottom-[-35%] group-hover:bottom-[0%] max-[768px]:static">
                    <div className="flex">
                      <h1 className="font-bold text-[72px] ">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_1')
                            ?.value}
                      </h1>
                      {/* <span className="text-[49px] ml-[11px] font-bold">+</span> test */}
                    </div>
                    <div className="">
                      <h1 className="font-bold text-[19px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.our_numbers_1_title',
                          )?.value}
                      </h1>
                    </div>
                    <div className="pt-[20px] pb-[31px] max-[768px]:pb-[116px]">
                      <p className="text-[19px] h-[80px]">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_1_text')
                            ?.value}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative w-[30%] max-[768px]:w-full  max-[768px]:ml-[0] group overflow-hidden h-[300px] pb-[30px] border-b-[1px] border-gray-500 ml-[31px]">
                  <div className="absolute transition-[0.6s] bottom-[-35%] group-hover:bottom-[0%] max-[768px]:static">
                    <div className="flex">
                      <h1 className="font-bold text-[72px] ">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_2')
                            ?.value}
                      </h1>
                      {/* <span className="text-[49px] ml-[11px] font-bold">+</span> */}
                    </div>
                    <div className="">
                      <h1 className="font-bold text-[19px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.our_numbers_2_title',
                          )?.value}
                      </h1>
                    </div>
                    <div className="pt-[20px] pb-[31px] max-[768px]:pb-[116px]">
                      <p className="text-[19px] h-[80px]">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_2_text')
                            ?.value}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative w-[30%] max-[768px]:w-full  max-[768px]:ml-[0] group overflow-hidden h-[300px] pb-[30px] border-b-[1px] border-gray-500 ml-[31px]">
                  <div className="absolute transition-[0.6s] bottom-[-35%] group-hover:bottom-[0%] max-[768px]:static">
                    <div className="flex">
                      <h1 className="font-bold text-[72px] ">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_3')
                            ?.value}
                      </h1>
                      {/* <span className="text-[49px] ml-[11px] font-bold">+</span> */}
                    </div>
                    <div className="">
                      <h1 className="font-bold text-[19px]">
                        {item &&
                          item?.find(
                            (x) => x.key === 'site.our_numbers_3_title',
                          )?.value}
                      </h1>
                    </div>
                    <div className="pt-[20px] pb-[31px] max-[768px]:pb-[116px]">
                      <p className="text-[19px] h-[80px]">
                        {item &&
                          item?.find((x) => x.key === 'site.our_numbers_3_text')
                            ?.value}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>



          {/*Closed yet testimonials  */}
          {/* <section className="pl-[50px] pb-[250px] testimonials-swiper" data-aos="fade-right">
            <div className="md:flex justify-between items-center pt-[170px] pb-[54px] pr-[50px]">
              <h3 className="text-[44px] leading-[65px] text-[#202021] pr-[91px] font-bold max-[768px]:text-[34px]">
                Testimonials
              </h3>
              <p className="font-normal text-[18px]  max-[768px]:pr-[0] max-[768px]:pt-[20px] max-[768px]:pb-[20px] leading-[26px] text-[#202021] md:pl-[91px] pr-[52px]">
                {item?.find((x) => x.key === 'site.testimonial_text')?.value}
              </p>
              <button
                onClick={handleClickMember}
                className="bg-[#F0E661] font-bold leading-[23px] text-[#202021] text-[18px] uppercase tracking-wide py-[23px] px-[28px] md:w-2/6 max-[768px]:text-[15px]"
              >
                Become a member
              </button>
            </div>
            <Swiper
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Pagination]}
      >

              {testimonials.length > 0
                ? testimonials?.map((item) => (
                    <SwiperSlide
                      className="text-center bg-[#fff] text-[18px]  max-[768px]:flex-col"
                      key={item.id}
                    >
                      <div className="photo">
                        <img
                          src={`${process.env.REACT_APP_DEV_API_URL}storage/${item?.image}`}
                          alt=""
                          className="w-full h-full block object-cover "
                        />
                      </div>
                      <div className="info flex flex-col items-baseline bg-[#F0F0F0] pt-[62px] pr-[42px] pl-[55px] w-[610px] max-[768px]:w-[100%] max-[768px]:pr-[0] max-[768px]:pl-[0]">
                        <ImQuotesRight className="w-[70px] h-[60px]" />
                        <p className="max-[768px]:text-[18px] max-[768px]:leading-[22px]  pt-[32px] pb-[31px] text-[28px] leading-[42px] text-[#202021] font-normal text-left">
                          {item && item.description}
                        </p>
                        <div className="text-left">
                          <h6 className="text-[24px] leading-[29px] text-[#202021] font-bold">
                            {item && item.name_surname}
                          </h6>
                          <span className="font-normal text-[14px] leading-[17px] text-[#202021] uppercase">
                            {item && item.country.name}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                : []}
            </Swiper>
          </section> */}
        </div>
      )}
    </>
  )
}

export default Home

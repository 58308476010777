import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

const postArticle = (payload) => {
    return axios.post(APIRoutes.article, payload, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        }
    })
}

export default postArticle
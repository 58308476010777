import React, { useMemo } from 'react'
import BecomeButton from '../../shared/button/BecomeButton'
import TextInput from '../../shared/input/TextInput'

import Swal from 'sweetalert2'

// AutoComplete
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useDispatch, useSelector } from 'react-redux'

import { getSettings, register } from '../../slices/auth'
import { selectImage } from '../../slices/addImg'
import { useState, useEffect } from 'react'
import { getChapters } from '../../slices/auth'
import { getCountries } from '../../slices/auth'
import { useNavigate } from 'react-router-dom'
// Date picker
import dayjs from 'dayjs'

import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Slide from '@mui/material/Slide'

import { clearMessages } from '../../slices/message'
import './datePicker.css'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { Button } from 'bootstrap'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const Register = () => {
  const image = useSelector(selectImage)

  //validation
  const [avatarValid, setAvatarvalid] = useState('')
  const [emailValid, setEmailValid] = useState('')
  const [userNameValid, setUserNameValid] = useState('')
  const [firstNameValid, setFirstNameValid] = useState('')
  const [lastNameValid, setLastNameValid] = useState('')
  const [namYouthEmailValid, setNamYouthEmailValid] = useState('')
  const [cityValid, setCityValid] = useState('')
  const [countryValid, setCountryValid] = useState('')
  const [genderIdValid, setGenderIdValid] = useState('')
  const [nationalityValid, setNationalityValid] = useState('')
  const [passwordValid, setPasswordValid] = useState('')
  const [phoneNumberValid, setPhoneNumberValid] = useState('')
  const [postalCodeValid, setPostalCodeValid] = useState('')
  const [item, setItem] = useState([])

  // Edit  profile
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [namYouthEmail, setNamYouthEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [nationality, setNationality] = useState('')
  const [phone, setPhone] = useState('')
  const [gender, setGender] = useState('')

  const [img, setImg] = useState('')

  // Address
  const [country, setCountry] = useState([])
  const [inputCountry, setInputCountry] = useState('')

  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')

  // National Chapter
  const [countryName, setCountryName] = useState('')

  // Set Password
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // Social Media Information
  const [facebookLink, setFacebookLink] = useState('')
  const [twitterLink, setTwitterLink] = useState('')
  const [linkedinLink, setLinkedinLink] = useState('')
  const [instagramLink, setInstagramLink] = useState('')

  // Privacy data
  const [numChecked, setNumChecked] = useState(false)


  // Chapters data
  const [chaptersMembers, setChaptersMembers] = useState([])
  const [inputValue, setInputValue] = useState('')

  // Date picker
  const [value, setValue] = useState('')
  // const startOfQ12022 = dayjs('2022-01-01T00:00:00.000');
  const endOfQ12022 = dayjs('2010-03-31T23:59:59.999')
  // const { message } = useSelector((state) => state.message);

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [validFiles, setValidFiles] = useState(true)
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = React.useState('paper')
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const {
    message,
    messageEmail,
    messagePassword,
    messageStatus,
    messageAvatar,
    messageUsername,
    messageFirstName,
    messageLastName,
    messageNamYouthEmail,
    messageNationality,
    messagePhoneNumber,
    messageGenderID,
    messageCountry,
    messagePostalCode,
    messageCity,
    messageins,
    messagefb,
    messagetw,
    messageDateOfBirth,
    messageNationalChapter,
    messagelinkedin,
  } = useSelector((state) => state.messages)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const navigate = useNavigate();
  useEffect(() => {
    dispatch(getChapters(countryName)).then((res) => {
      setChaptersMembers(res?.payload)
    })
  }, [dispatch])
  //validation messages

  useEffect(() => {
    dispatch(clearMessages())
  }, [dispatch])

  function handleCheck(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]')
    const allChecked = [...checkboxes].every((checkbox) => checkbox.checked)
    setNumChecked(allChecked)
  }

  // useEffect(() => {
  //   if (messageCity) {
  //     dispatch(clearMessagesOnCityChange());
  //   }
  // }, [messageCity]);

  // const [successful, setSuccessful] = useState(false);

  // Chapter find
  const filtered = chaptersMembers?.find((obj) => {
    return obj.name === inputValue
  })
  const chapterId = filtered ? filtered.id : null

  // Countries find for id

  const filteredCountry = country.find((obj) => {
    return obj?.name === inputCountry
  })
  const IdCountry = filteredCountry ? filteredCountry?.id : null

  const click = (e) => {
    setLoadingSubmit(true)
    if (!numChecked) {
      Swal.fire('Please fill in all fields', '', 'info')
      setValidFiles(false)
      setLoadingSubmit(false)
    } else {
      setValidFiles(true)
      e.preventDefault()
      const ss = new FormData()
      ss.append('img', img)
      const data = {
        first_name: firstName,
        email: email,
        nam_youth_email: namYouthEmail,
        password: password,
        username: userName,
        password_confirmation: confirmPassword,
        last_name: lastName,
        nationality: nationality,
        phone_number: phone,
        gender_id: gender,
        country: IdCountry,
        postal_code: postalCode,
        city: city,
        national_chapter: chapterId,
        date_of_birth: `${value.$y}-${value.$M + 1}-${value.$D}`,
        avatar: image,
        fb: facebookLink,
        ins: instagramLink,
        tw: twitterLink,
        linkedin: linkedinLink,
      }
      dispatch(register(data))
        .then((res) => {
          // console.log(res.payload.response.status);
          if (res.payload.user) {
            Swal.fire(
              'A message has been sent to your email',
              'Check your email',
              'success',
            ).then(() => {
              navigate('/')
            })
          }
        })
        .catch((err) => {
          setAvatarvalid(messageAvatar)

          Swal.fire({
            icon: 'error',
            // title: 'Oops...',
            text: 'Please check the inputs again',
            // footer: '<a href="">Why do I have this issue?</a>'
          })
        })
        .finally(() => {
          setLoadingSubmit(false)
        })
    }
  }

  useEffect(() => {
    dispatch(getCountries()).then((res) => {
      setCountry(res?.payload?.countries?.data?.countries)
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(getSettings()).then((res) => {
      setItem(res?.payload?.settings?.data?.settings)
    })
  }, [dispatch])

  const findPolicy = useMemo(
    () => item?.find((obj) => obj?.key == 'site.term_condition'),
    [item],
  )

  return (
    <div className="px-[20px] lg:px-[50px] md:px-[30px] sm:px-[20px]">
      <div className="block md:flex">
        <div className="w-[100%] md:w-[30%]">
          {/* <input type="file" name="image" onChange={handleFileCheck} /><br/> */}
          {/* <span className="text-[red]">{avatarValid}</span> */}
          <h1 className="font-bold text-[24px] pb-[20px]">Profile</h1>
          <TextInput
            iplaceholder="Enter your username"
            ititle="Username"
            iname="username"
            value={userName}
            handle={setUserName}
          />
          <span className="text-[red]">{messageUsername}</span>
          <TextInput
            iplaceholder="Enter your email"
            ititle="Email Address"
            iname="email"
            value={email}
            handle={setEmail}
          />
          <span className="text-[red]">{messageEmail}</span>
          <TextInput
            iplaceholder="Enter your email"
            ititle="NAM Youth Email Address"
            iname="namemail"
            handle={setNamYouthEmail}
          />
          <span className="text-[red]">{messageNamYouthEmail}</span>
          <TextInput
            iplaceholder="Enter your name"
            ititle="First Name"
            iname="firstname"
            handle={setFirstName}
          />
          <span className="text-[red]">{messageFirstName}</span>
          <TextInput
            iplaceholder="Enter your surname"
            ititle="Last Name"
            iname="lastname"
            handle={setLastName}
          />
          <span className="text-[red]">{messageLastName}</span>
          <div className="w-[100%]">
            <h4 className="mb-[15px] font-bold text-[15px] mt-[17px]">
              Date of Birth
            </h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                  // minDate={startOfQ12022}
                  maxDate={endOfQ12022}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <span className="text-[red]">{messageDateOfBirth}</span>
          <TextInput
            iplaceholder="Enter your nationality"
            ititle="Nationality"
            iname="nationality"
            handle={setNationality}
          />
          <span className="text-[red]">{messageNationality}</span>
          <TextInput
            iplaceholder="Enter your number"
            ititle="Phone Number"
            iname="pnumber"
            handle={setPhone}
          />
          <span className="text-[red]">{messagePhoneNumber}</span>
          <div>
            <h4 className="mb-[15px] font-bold text-[15px]">Gender</h4>
            <div className="flex gap-5">
              <input
                type="radio"
                value="1"
                name="gender"
                onChange={(e) => setGender(e.target.value)}
                className="cursor-pointer"
                checked={gender === '1'}
              />{' '}
              Male
              <input
                type="radio"
                value="2"
                name="gender"
                onChange={(e) => setGender(e.target.value)}
                className="cursor-pointer"
                checked={gender === '2'}
              />{' '}
              Female
              <input
                type="radio"
                value="3"
                name="gender"
                onChange={(e) => setGender(e.target.value)}
                className="cursor-pointer"
                checked={gender === '3'}
              />{' '}
              Other
            </div>
            <span className="text-[red]">{messageGenderID}</span>
          </div>
        </div>
        <div className="ml-0 w-[100%] md:ml-[147px] md:w-[30%]">
          <div>
            <h1 className="font-bold text-[24px] pb-[20px]">Country</h1>
            <Autocomplete
              id="controllable-states-demo"
              options={country?.map((country) => country?.name)}
              inputValue={inputCountry}
              onInputChange={(event, newInputValue) => {
                setInputCountry(newInputValue)
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
            />
            <span className="text-[red]">{messageCountry}</span>
            <TextInput
              iplaceholder="Enter your postal code"
              ititle="Postal code"
              iname="postalcode"
              handle={setPostalCode}
            />
            <span className="text-[red]">{messagePostalCode}</span>
            <TextInput
              iplaceholder="Enter your city"
              ititle="City"
              iname="city"
              handle={setCity}
            />
            <span className="text-[red]">{messageCity}</span>
          </div>
          <div className="pt-[30px]">
            <h1 className="font-bold text-[24px] pb-[20px]">
              National Chapter
            </h1>
            <div className="w-[100%]">
              <Autocomplete
                id="controllable-states-demo1"
                options={chaptersMembers?.map((chap) => chap?.name)}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
              />
              <span className="text-[red]">{messageNationalChapter}</span>
            </div>
            <span className="text-[red]">{countryValid}</span>
          </div>
          <div className="pt-[30px]">
            <h1 className="font-bold text-[24px] pb-[20px]">Set Password</h1>
            <TextInput
              iplaceholder="Enter New Password"
              ititle="Password"
              iname="newpsw"
              handle={setPassword}
              type="password"
            />
            <span className="text-[red]">{messagePassword}</span>
            <TextInput
              iplaceholder="Enter Confirm Password"
              ititle="Confirm Password"
              iname="repsw"
              handle={setConfirmPassword}
              type="password"
            />
            <span className="text-[red]">{messagePassword}</span>
          </div>
        </div>
      </div>
      <hr className="my-[150px]" />
      <div>
        <h1 className="font-bold text-[24px] pb-[30px]">
          Social Media Information
        </h1>
        <div className="block md:flex">
          <div className="w-[100%] md:w-[30%]">
            <TextInput
              iplaceholder="Enter Facebook Link"
              ititle="Facebook Profile"
              iname="fblink"
              handle={setFacebookLink}
            />
            <span className="text-[red]">{messagefb}</span>
            <TextInput
              iplaceholder="Enter Twitter Link"
              ititle="Twitter Profile"
              iname="twitlink"
              handle={setTwitterLink}
            />
            <span className="text-[red]">{messageins}</span>
          </div>
          <div className="w-[100%] md:w-[30%] ml-0 md:ml-[147px]">
            <TextInput
              iplaceholder="Enter LinkedIn Link"
              ititle="Linkedin Profile"
              iname="lelink"
              handle={setLinkedinLink}
            />
            <span className="text-[red]">{messagelinkedin}</span>
            <TextInput
              iplaceholder="Enter Instagram Link"
              ititle="Instagram Profile"
              iname="instalink"
              handle={setInstagramLink}
            />
            <span className="text-[red]">{messagetw}</span>
          </div>
        </div>
      </div>
      <hr className="my-[150px]" />
      <div className="pb-[170px]">
        <h1 className="font-bold text-[24px] pb-[30px]">Your privacy data</h1>
        <div className="lg:flex md:flex ">
          <ul>
            <li className="pb-[20px]">
              <input
                id="checkbox1"
                type="checkbox"
                className={`${validFiles ? '' : 'border-[red]'} mr-2`}
                onClick={handleCheck}
              />
              <label
                className="pl-[13px] text-[16px] font-bold cursor-pointer hover:text-[#4e4a35]"
                onClick={handleClickOpen}
              >
                I accept the NAM Youth Accounts Terms and Conditions
              </label>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className="policy__dialog"
              >
                <DialogTitle id="scroll-dialog-title">
                  Terms and Conditions
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: findPolicy?.value }}
                    ></div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button
                    className="bg-[#2A538A] hover:bg-[white] text-white hover:text-[#2A538A] border border-[#2A538A] font-bold py-2 px-4 rounded"
                    onClick={handleClose}
                  >
                    Ok
                  </button>
                </DialogActions>
              </Dialog>
            </li>
          </ul>
        </div>
        <span className={`${validFiles ? 'hidden' : 'block'} text-[red]`}>
          Please fill in all fields
        </span>
      </div>
      <div className="flex justify-center pb-[220px]">
        <div>
          <button
            disabled={loadingSubmit}
            onClick={click}
            className={`uppercase w-[100%] sm:w-auto bg-[#2A538A] border ease-in duration-300 border-[#2A538A] hover:bg-[white] hover:text-[#2A538A] px-[72px] py-[22px] text-[18px] text-[white]`}
          >
            {loadingSubmit ? 'Loading...' : 'Register'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Register

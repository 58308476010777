import React from "react";
import image from "../../assets/images/hr.png";
import SearchInput from "../../components/shared/search/SearchInput"

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'

import { getChapters } from "../../components/slices/auth";
export default function NationalChapters() {
  const [allChapters, setAllChapters] = useState([]);
  const location = useLocation();
  
  const dispatch = useDispatch();
  var homePage = location.pathname === "/"

  useEffect(() => {
    dispatch(getChapters("")).then(res => {
      setAllChapters(res.payload)
    });
  }, [])
  const searchDynamic = async (e) => {
    e.preventDefault()
    if(e.target.value.trim()==""){
      dispatch(getChapters({countryName:e.target.value})).then(res => {
        setAllChapters(res.payload)
      });
    }
    else{
      const filter = allChapters.filter(chapter => chapter.name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()));
    setAllChapters(filter)
    }
  };
  return (
    <div id='down'>
      <div className="px-[20px] md:px-[50px] pt-[90px]  ">
        <h1 className="text-[44px] font-bold leading-[53px] pb-[35px]">
          National Chapters
        </h1>
        <div className="flex border border-black mb-[70px]">
          <SearchInput placeholder='Search by country name' handleSearch={(e) => searchDynamic(e)}  />
        </div>
      </div>
      <div className="w-[100%] grid grid-cols-1 md:grid-cols-4 px-[20px] md:px-[50px] gap-10 pb-[270px]">
        {allChapters.map((chapters) => (
          
          <div className="flex items-center" key={chapters?.id}>
            <img
              className=" object-cover w-[100px] h-[52px]"
              src={`${process.env.REACT_APP_DEV_API_URL}storage/${chapters?.flag}`}
              alt=""

            />
            
            <Link  to={homePage ? `chapters/overview/${chapters?.id}` : `overview/${chapters?.id}`} className="pl-[20px] hover:underline">{chapters?.name}</Link>
          </div>
        ))}
      
      </div>
    </div>
  )
};

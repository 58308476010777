import React from 'react'

const BecomeButton = ({ func, bgColor, textColor, text, paddingX, paddingY, textSize,marginB }) => {

  return (
    <div>
      <button onClick={func} className={`uppercase w-[100%] sm:w-auto hover:bg-${bgColor} mb-[${marginB}] hover:text-${bgColor}`} style={{ backgroundColor: bgColor, color: textColor, paddingLeft: paddingX, paddingRight: paddingX, paddingTop: paddingY, paddingBottom: paddingY, fontSize: textSize }}>{text}</button>
    </div>

  )
}

export default BecomeButton;
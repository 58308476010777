import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

export const Like = async(id) => {
    try {
        const response = axios.post(`${APIRoutes.like}`, id, {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}
import React, { useEffect, useState } from 'react'
import InnerHeader from '../../components/layout/public/components/InnerHeader'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import BlogArticlesCard from '../../components/shared/card/BlogArticlesCard'
import blogsGet from '../../components/services/blogs.service'
import blogsSearch from '../../components/services/blogSearch.service'

export default function Blog() {
  const [title, setTitle] = useState('')
  const [blogs, setBlogs] = useState([]);
  const [next, setNext] = useState(3)
  const handleMoreItem = () => {
    setNext(next + 3)
  }
  useEffect(() => {
      blogsGet().then(res => {
        setBlogs(res.data.blogs)
      })
  }, []);

  useEffect(() => {
    blogsSearch(title).then(res =>{
      setBlogs(res.data.blogs)
    })
  }, [title]);

  return (
    <>
      <InnerHeader
        img={require('../../assets/images/opp-bg-img.png')}
        title={'Blog'}
        text={
          'Welcome to the online journal of the members of NAM Youth Organization worldwide where their activities, experience, and achievements are showcased!'
        }
      />
      <div className="px-[20px] md:px-[50px]">
      <div className="block border mt-[30px] md:flex items-center" id="topDates">
          <div className="relative w-[100%] xl:w-[30%]">
            <input
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              className="block w-full px-3 py-4 bg-[#F0F0F0] border border-[#F0F0F0] text-sm shadow-sm placeholder-[black] focus:outline-none focus:border-[#F0F0F0] focus:ring-1 focus:ring-[#F0F0F0]"
              placeholder="Search by keyword"
            />
            <button
              type="submit"
              className="absolute top-0 right-0 px-2.5 py-4 text-sm font-medium text-black "
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
         
        </div>
        {
          blogs.length === 0 && <h1 className='font-bold text-[20px] mt-[30px]'>No data</h1>
        }

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-[54px]">
          {
            blogs && blogs.slice(0,9).map(blog => (
              <BlogArticlesCard 
              id={blog?.id}
              key={blog?.id}
              coverImage={blog?.cover_image}
              description={blog?.description}
              title={blog?.title}
              createdTime={blog?.created_at}
              memberName={blog?.member?.first_name}
              memberLastName={blog?.member?.last_name}
              memberImage={blog?.member?.avatar}

              />

            ))
          }
          {next < blogs?.length && blogs.length > 9 ? (
              <div className="flex justify-center pb-[137px]">
                <button
                  className="mt-4 bg-[#2A538A] text-[white] py-[25px] px-[52px] font-bold hover:bg-[white] hover:text-[#2A538A] border border-[#2A538A] ease-in duration-100"
                  onClick={handleMoreItem}
                >
                  Load more
                </button>
              </div>
            ) : (
              ''
            )}
          {/* <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/>
          <BlogArticlesCard/> */}

        </div>
      </div>
    </>
  )
}

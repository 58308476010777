import React from 'react'
import LoginLeftSide from '../login/LoginLeftSide';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Resend() {
    const navigate = useNavigate()
    
  return (
    <div className='h-[100vh] flex flex-row'>
        <LoginLeftSide/>
        <div className='flex flex-col justify-center items-center w-[100%] md:w-[50%]'>
            <div>
                <div className='text-center'>
                    <div>
                        <h1 className='text-[24px] font-bold text-[#1C1C1C]'>Please verify your email</h1>
                    </div>
                    
                    <div className='my-[17px]'>
                        <p>
                        Your email has expired. Please go to your office and perform the verification process
                         </p>
                    </div>
                    <div className='my-[16px]'>
                        <button className='py-[18px] bg-black text-white uppercase px-[137px] hover:text-black hover:bg-white duration-800 font-america border border-black' onClick={() => navigate("/login")}>Login</button>
                    </div>
                    <div>
                        <p>Need to help? <Link to={"/contact"} className='text-[#2699FB]'>Contact Us</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

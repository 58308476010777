import axios from "axios";
import { APIRoutes } from '../../core/api/routes'


const chapterForId = async function getChaptersForId(id) {
    try {
        const response = await axios.get(APIRoutes.chapterId + id)
        return response.data.data.national_chapter
    } catch {}
}

const ChapterService = {
    chapterForId
}
export default ChapterService
import React from 'react'
import { Outlet } from 'react-router-dom'
import LayoutAbout from "../../components/layout/public/AboutLayout";
import { useLocation } from 'react-router-dom';
import Concept from './Concept';
export default function ConceptLayout() {
    const location = useLocation()
  return (
    <>
    <LayoutAbout>
        <Outlet/>
        {location.pathname === "/concept" ?<Concept/> :'' }
    </LayoutAbout>
    </>
  )
}

import axios from "axios";

import { APIRoutes } from "../../core/api/routes";

const resendEmail = async function sendEmailVerify(token) {
    return axios
        .get(APIRoutes.resendEmailVerify, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${JSON.parse(token)}`
            }
        })
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(error)
        })

}
const resendEmailVerify = {
    resendEmail
}
export default resendEmailVerify;
import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai"
import { AiFillYoutube } from "react-icons/ai"
import { FaLinkedinIn } from "react-icons/fa"
import { FaTelegramPlane } from "react-icons/fa"
import { useDispatch} from 'react-redux'
import SocialMedia from '../../components/layout/public/components/socialMedia/SocialMedia'
import { useEffect, useState } from 'react'
import { getChapterForId } from '../../components/slices/chapter'
import { useParams } from 'react-router-dom'


const CountryNameOverview = () => {
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [instagram, setInstagram] = useState("");
  const [telegram, setTelegram] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [youtube, setYoutube] = useState("");


  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChapterForId({ id: id })).then(res => {
      setAddress(res?.payload?.address);
      setEmail(res?.payload?.address);
      setInstagram(res?.payload?.ins);
      setPhone(res?.payload?.phone);
      setWebsite(res?.payload?.website)
      setTelegram(res?.payload?.tel)
      setYoutube(res?.payload?.yt)
    })
  }, [dispatch,id]);
  return (
    <div className='px-[50px]' >
      <div className='flex justify-between mt-[23px]'>
        <div>
          <div>
            <span className='text-[14px]'>{phone}</span>
          </div>
          <div className='mt-[20px]'>
            <span className='text-[14px]'>{email}</span>
          </div>
          <div className='mt-[20px]'>
            <span className='text-[14px]'>{address}</span>
          </div>
          <div className='mt-[20px]'>
            <Link className='underline decoration-1' to={`${website}`}>Website</Link>
          </div>
        </div>
        <div>
          <div>
            <span className='font-light text-[14px]'>Social media</span>
          </div>
          <div className='flex text-[20px] justify-between mt-[29px]'>
            <Link to={"facebook"} className='mr-[20px]'><FaFacebookF /></Link>
            <Link to={`${instagram}`} className='mr-[20px]'><AiFillInstagram /></Link>
            <Link to={`${youtube}`} className='mr-[20px]'><AiFillYoutube /></Link>
            <Link to={"linkedin"} className='mr-[20px]'><FaLinkedinIn /></Link>
            <Link to={`${telegram}`} className='mr-[20px]'><FaTelegramPlane /></Link>
          </div>
        </div>
      </div>
      <div className='pt-[5.5rem]'>
        <SocialMedia />
      </div>
    </div>
  )
}

export default CountryNameOverview
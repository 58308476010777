import axios from "axios";
import { APIRoutes } from "../../core/api/routes";

const MainNews = async() => {
    try {
        const response = await axios.get(APIRoutes.newsMain)
        return response.data.data.news
    } catch (error) {
        return error
    }
}

export default MainNews
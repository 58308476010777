import React from 'react'
import { Link } from 'react-router-dom'

export default function OpportunitiesCard({
  img,
  title,
  address,
  deadLine,
  unique,
  to,
  func,
}) {
  const dateString = deadLine
  const date = new Date(dateString)
  const formattedDate = date
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')

  const dateStringConvert = formattedDate
  const dateParts = dateStringConvert.split('-')
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1]) - 1
  const day = parseInt(dateParts[2])

  const dateNew = new Date(year, month, day)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const formattedDateNew = dateNew.toLocaleDateString('en-US', options)
  return (
    <div className='hover:-translate-y-[10px] ease-in duration-300 mb-[10px]'>
      <Link
        onClick={func}
        to={to}
        className="mr-0 md:mr-[20px] bg-white mb-[78px]"
        key={unique}
      >
        <div className="h-[263px] bg-gray-400">
         {img &&  <img
            className="h-[100%] w-[100%] object-cover"
            src={`${process.env.REACT_APP_DEV_API_URL}storage/${img}`}
            alt=""
          />}
        </div>
        <div className="">
          <h5 className="mt-2 text-2xl font-bold text-gray-900">{title}</h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {address}   <span className='ml-2'>{formattedDateNew}</span>
          </p>
        </div>
      </Link>
    </div>
  )
}

import ChapterService from "../services/chaptersForId.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getChapterForId = createAsyncThunk(
    "auth/chapterId",
    async({ id }) => {
        const res = await ChapterService.chapterForId(id)
        return res
    }
)

const initialState = {
    chapter: null,
    loading: false,
    error: null,
};

const chapterSlice = createSlice({
    name: "chapter",
    initialState,
    extraReducers: {
        [getChapterForId.fulfilled]: (state, action) => {
            state.loading = false;
            state.chapter = action.payload;
        },
        [getChapterForId.rejected]: (state, action) => {
            state.loading = false;
            state.chapter = action.error.message;
        },
    }
})

export default chapterSlice.reducer;
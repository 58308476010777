import React from 'react'
import OpportunitiesHeader from './components/OpportunitesHeader'


export default function OpportunitiesLayout({children}) {
  return (
    <div>
        <OpportunitiesHeader/>
        {children}

    </div>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAllNews } from '../../slices/auth'
import { useNavigate } from 'react-router-dom'

export default function ShortNews() {
    const dispatch = useDispatch();
    const [sortNews, setSortNews] = useState([]);
    const navigate=useNavigate()

    useEffect(() => {
        dispatch(getAllNews({year:undefined})).then((res) => {
            setSortNews(res?.payload?.news?.data?.news)
        })
      }, [])

      sortNews?.sort((x,y) => (
        x.id-y.id
      ));
      const firstNews = sortNews[sortNews.length -1]
      const secondNews = sortNews[sortNews.length -2]
      const thirdNews = sortNews[sortNews.length -3]
      const fourthNews = sortNews[sortNews.length -4]

  return (
    <section className="text-[#202021] bg-[#FBFCFE]">
        <div className="px-[50px] py-[164px] max-[768px]:px-[26px]">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-[44px] max-[768px]:text-[32px]">
              News
            </h1>
            <Link
              to={"/news"}
              className="text-[16px] py-[10px] border-b-[1px] border-[#202021]"
            >
              See more
            </Link>
          </div>
          <div className="mt-[3rem] flex max-[768px]:flex-col ">

            
            <div className="w-[50%] cursor-pointer max-[768px]:w-[100%] max-[768px]:pb-[24px]" onClick={() => navigate(`news/detail/${firstNews.id}`)}>
              <div className='h-[250px] md:h-[450px] '>
                <img
                className='w-[100%] h-[100%] object-cover'
                  src={`${process.env.REACT_APP_DEV_API_URL}storage/${firstNews?.cover_image}`}
                  alt=""
                />
              </div>
              <div>
                <div className="mt-[18px] mb-[10px]">
                  <span className="text-[14px] uppercase">
                    {firstNews?.created_at}
                  </span>
                </div>
                <div>
                  <h1 className="font-bold text-[36px] leading-[45px] max-[768px]:leading-[32px] max-[768px]:text-[24px]">
                    {firstNews?.title}
                  </h1>
                </div>
                <div className="mt-[14px]">
                  <p className="leading-[32px] text-[18px]">
                    {firstNews?.desc}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-[50%] ml-[34px] flex flex-col justify-between max-[768px]:w-[100%] max-[768px]:ml-[0]">
              <div className="border-b-[1px] border-[gray] pb-[33.5px] cursor-pointer" onClick={() => navigate(`news/detail/${secondNews?.id}`)}>
                <div>
                  <span className="text-[14px]">{secondNews?.created_at}</span>
                </div>
                <p className="font-bold text-[24px] max-[768px]:text-[16px]">
                  {secondNews?.desc}
                </p>
              </div>
              <div className="border-b-[1px] border-[gray] pb-[33.5px] cursor-pointer" onClick={() => navigate(`news/detail/${thirdNews?.id}`)}>
                <div>
                  <span className="text-[14px]">{thirdNews?.created_at}</span>
                </div>
                <p className="font-bold text-[24px] max-[768px]:text-[16px]">
                  {thirdNews?.desc}
                </p>
              </div>
              <div className="border-b-[1px] border-[gray] pb-[33.5px] cursor-pointer" onClick={() => navigate(`news/detail/${fourthNews?.id}`)}>
                <div>
                  <span className="text-[14px]">{fourthNews?.created_at}</span>
                </div>
                <p className="font-bold text-[24px] max-[768px]:text-[16px]">
                  {fourthNews?.desc}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

import EmailVerifyService from "../services/emailVerifyResend.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const ResendEmailVerify = createAsyncThunk(
    "email/resendVerify",
    async({ token }) => {
        const res = await EmailVerifyService.resendEmail(token)
        return res
    }


)

const initialState = {
    resendVerify: null,
    loading: false,
    error: null,
}

const resendVerifySlice = createSlice({
    name: "resendVerify",
    initialState,
    extraReducers: {
        [ResendEmailVerify.fulfilled]: (state, action) => {
            state.loading = false;
            state.resendVerify = action.payload;
        },
        [ResendEmailVerify.rejected]: (state, action) => {
            state.loading = false;
            state.resendVerify = action.error.message;
        },
    }
})

export default resendVerifySlice.reducer